import { React, useState } from "react";
import {TextField } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { red, green } from '@mui/material/colors';
import * as Global from '../Global';
import { toast } from 'react-toastify';

const styles = {
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

const TableListItem = (props) => {
  const [table, setTable] = useState(props.table);
  const [edit, setEdit] = useState(table.name === "" ? true : false);
  
  if(table !== props.table && !edit){
    setTable(props.table);
  }

  const Save = () => {
    if(!edit){
      setEdit(true);
      return
    }
    else{
      props.ws.send("UPDATETABLE|"+Global.USER+'|'+table.id+'|'+table.name+'|'+table.status);
      setEdit(false);
    }
  }

  const Edit = (e) => {
    if(e.target.value.length <= 20)
      setTable({...table,name:e.target.value})         
    else
      toast.error("A megnevezés nem lehet hosszabb, mint 20 karakter")
  }

  const PreDelete = () => {
    props.ws.send("PREDELETETABLE|"+Global.USER+'|'+table.id);
  }

  return(
    <li>
      <ul style={styles.ul}>
      <li style={{...styles.li,width:'70%',minWidth:180}}>
          {
            edit ? 
            <TextField maxLength={20} size="small" onChange={Edit} value={table.name}/>
            : table.name
          }
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          {table.status?
            <CheckCircleIcon 
              onClick={() => {
                if(edit){
                  toast.error("Szerkesztés alatt nem lehet státuszt változtatni");
                  return
                }
                props.ws.send('UPDATETABLESTATUS|'+Global.USER+"|"+table.id+"|false");
              }} 
              sx={{
                color:green[500],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
            :
            <CancelIcon 
              onClick={() => {
                if(edit){
                  toast.error("Szerkesztés alatt nem lehet státuszt változtatni");
                  return
                }
                props.ws.send('UPDATETABLESTATUS|'+Global.USER+"|"+table.id+"|true");
              }} 
              sx={{
                color:red[400],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
          }
          
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={styles.editDiv} onClick={Save}>
            {edit?
              <SaveIcon style={{color:'#7186fa'}}/>
              :
              <EditIcon  style={{color:'#7186fa'}}/>
            }
          </div>
        </li>   
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={{...styles.editDiv,borderColor:'red'}} onClick={PreDelete}>
            <DeleteForeverIcon  style={{color:'red'}}/>
          </div>
        </li>     
      </ul>
    </li>    
  )
}

export default TableListItem

import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Typography, Grid, Button, TextField, InputAdornment, FormControlLabel, Switch } from '@mui/material';

import * as Global from '../Global';
import SimpleSelect from './SimpleSelect';

const Settings = forwardRef((props, ref) => {
  const [cSellings,]                            = useState(sellings);
  const [sSelling, setSelling]                  = useState(props.sellings ?? '');
  const [cDenominators]                         = useState(denominators);
  const [sDenominator, setDenominator]          = useState(props.denominators ?? '');
  const [cPartners,]                            = useState(partners);
  const [sPartner, setPartner]                  = useState(props.partners ?? '');
  const [euro, setEuro]                         = useState(props.euro);
  const [service, setService]                   = useState(props.serviceCharge);
  const [dayOpen, setDayOpen]                   = useState(props.isDayOpen);
  const [selectedTerminal, setSelectedTerminal] = useState(props.terminal);

  useEffect(() =>{
    setService(props.serviceCharge);
  }, [props])

  const SellingHandler = (e) => {
    setSelling(e.target.value);
  }

  const DenominatorHandler = (e) => {
    setDenominator(e.target.value);
  }

  const PartnerHandler = (e) => {
    setPartner(e.target.value);
  }

  const TerminalHandler = (e) => {
    setSelectedTerminal(e.target.value);
  }

  const Save = () => {
    props.ws.send('UPDATESETTINGS|'+Global.USER+'|'+sSelling+'|'+sDenominator+'|'+sPartner+'|'+euro+'|'+service+'|'+selectedTerminal);
  }

  const dayOpenHandler = () => {
    setDayOpen(!dayOpen);
    if (!dayOpen) props.ws.send('OPENDAY|'+Global.USER);
    else props.ws.send('CLOSEDAY|' + Global.USER);
  }
  
  useImperativeHandle(ref, () => {
    return {
      daySetOpen: daySetOpen,
      daySetClosed: daySetClosed
    }
  })

  const daySetOpen = () => {
    setDayOpen(true);
  }

  const daySetClosed = () => {
    setDayOpen(false);
  }

  return(
    <div>
      <Typography variant="h4" style={styles.title}>Beállítások</Typography>
      <Grid container direction={'column'} style={styles.main}>
        <FormControlLabel control={<Switch checked={dayOpen} onChange={dayOpenHandler} />} label={dayOpen ? 'Nap zárás' : 'Nap nyitás'} />
        <Grid item style={styles.paper}>
          <Typography>Eladási mód</Typography>
          <SimpleSelect
            style={{maxWidth:220}}
            selected={sSelling}
            datas={cSellings}
            onChange={e => SellingHandler(e)}
            goal={"eladási módok"}
          />
        </Grid>
        <Grid item style={styles.paper}>
          <Typography>Számlák számozása</Typography>
          <SimpleSelect
            style={{maxWidth:220}}
            selected={sDenominator}
            datas={cDenominators}
            onChange={e => DenominatorHandler(e)}
            goal={"számozások"}
          />
        </Grid>
        <Grid item style={styles.paper}>
          <Typography>Partner használat</Typography>
          <SimpleSelect
            style={{maxWidth:220}}
            selected={sPartner}
            datas={cPartners}
            onChange={e => PartnerHandler(e)}
            goal={"számozások"}
          />
        </Grid>
        <Grid item style={styles.paper}>
          <Typography>Szervizdíj</Typography>
          <TextField
            style={{maxWidth:220}}
            variant="outlined"
            //disabled={dayOpen}
            onChange={(e) => setService(e.target.value)}
            value={service}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item style={styles.paper}>
          <Typography>Terminál vonalkódok</Typography>
          <SimpleSelect
            style={{maxWidth:220}}
            selected={selectedTerminal}
            datas={terminals}
            onChange={e => TerminalHandler(e)}
            goal={"termiálok"}
          />
        </Grid>
        <Grid item style={styles.paper}>
          <Typography>Euró árfolyam</Typography>
          <TextField
            style={{maxWidth:220}}
            variant="outlined"
            onChange={(e) => setEuro(e.target.value)}
            value={euro}
            disabled={dayOpen}
          >

          </TextField>
        </Grid>
        <Grid item style={styles.paper}>
          <Button onClick={Save} size="large" variant="contained" component="span">Rögzítés</Button>
        </Grid>
      </Grid>
    </div>
  )
});

const styles = {
  main: {
    alignItems: 'center',
    minHeight: 'calc(100vh - 30px)',
    padding: '15px'
  },
  paper: {
    padding: 15,
    width: '100%',
    maxWidth: 500,
    textAlign: 'center'
  },
  logo: {
    maxWidth: '300px',
    width: '100%'
  },
  headerText: {
    marginBottom: 15
  },
  inputDiv: {
    marginBottom: 15,
  },
  loginButton: {
    marginBottom: 15
  },
  title: {
    textAlign: 'center',
    margin: '20px 0'
  },
  selectGrid: {
    minWidth: 200,
    marginBottom: 15,
    fullWidth: true,
  },
  checkbox: {
    padding: 0,
  }
}

const sellings = [
  {
    "label" : "Pultos értékesítés",
    "id"    : 1
  },
  {
    "label" : "Asztalos értékesítés",
    "id"    : 2
  },
  //etc
];

const denominators = [
  {
    "label" : "2 jegyű sorszámok",
    "id"    : 1
  },
  {
    "label" : "3 jegyű sorszámok",
    "id"    : 2
  }
];

const partners = [
  {
    "label" : "Nem használ partnereket",
    "id"    : 0
  },
  {
    "label" : "Partnerlistából választ",
    "id"    : 1
  },
  {
    "label" : "Kóddal adja meg a partnert",
    "id"    : 2
  }
];

const terminals = [
  {
    label: 'Nincs terminál',
    id:     0,
  },
  {
    label: 'Micra terminál',
    id:     1,
  },
];

export default Settings;
import { React, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { red, green } from '@mui/material/colors';
import * as Rights from '../Rights';
import * as Global from '../Global';

const styles = {
  li:{
    display:'inline-flex',
    width:'20%',
    minWidth:180,
    flex:'auto'
  },
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer'
  }
}

const ProductListItem = (props) => {
  const [status, setStatus]   = useState(props.product.status);    
  
  if(props.product.status !== status){
    setStatus(props.product.status);
  }

  const edit = (e) => {
    props.ws.send('GETPRODUCT|'+Global.USER+'|'+props.product.id);
  } 

  return(
    <li>
      <ul style={styles.ul}>
        <li style={styles.li}>{props.product.serNum}</li>
        <li style={styles.li}>{props.product.desc}</li>
        <li style={{...styles.li,minWidth:80,width:0,justifyContent:'center'}}>
          {status > 0 ?             
            <CheckCircleIcon 
              onClick={() => {
                let status2 = status > 0 ? "false" : "true";
                props.ws.send('UPDATEPRODUCTSTATUS|'+Global.USER+"|"+props.product.id+"|"+status2);
              }} 
              sx={{
                color: green[500],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
            :
            <CancelIcon
              onClick={() => {
                let status2 = status > 0 ? "false" : "true";
                props.ws.send('UPDATEPRODUCTSTATUS|'+Global.USER+"|"+props.product.id+"|"+status2);
              }} 
              sx={{
                color: red[400],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
          }
        </li>
        <li style={{...styles.li,minWidth:100,width:0,justifyContent:'right'}}>{props.product.gross}</li>      
        <li style={{...styles.li,minWidth:60,width:0,justifyContent:'center'}}>
          {Rights.ProductEditor &&
            <div style={styles.editDiv} onClick={edit}>
              <EditIcon  style={{color:'#7186fa'}}/>
            </div>}
        </li>
      </ul>
    </li>
  )    
}

export default ProductListItem;
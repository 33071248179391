import React, { useState} from 'react';
import { Button, TextField} from '@mui/material';

import * as Global from '../../Global';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: '1',
    position: 'fixed',
    backgroundColor: '#F5F5F5',
    left : '0',
    right : '0',
    margin: '5% auto',
    width: '400px',
    padding: '25px',
    border: '1px solid rgba(0, 0, 0, 0.21)',
    borderRadius: '5px',
    maxHeight: '70%',
    overflowY: 'auto',
    justifyContent: 'space-between',
    maxWidth: '80%',
  },
  button: {
    backgroundColor: '#FFFF',
    borderRadius: '8px',
    color: '#000',
    width: '100%',
    margin: '4px',
    border: 'solid #284aff 2px'
  },
  greyBtn:{
    backgroundColor:'rgb(245,247,250)', 
    borderRadius:8,
    display:'inline-flex',
    border:'1px solid #00000036',
    color:'black',
    maxWidth: 100,
    marginLeft: 20,
  }

}

const AmountDialog = (props) => {
  const [amount, setAmount] = useState(0.0);

  const handleDone = () => {
    let inAmount = amount.replace(',' , '.');
    let pattern = /^[.,?\d]+$/
    if(inAmount.match(pattern)) {
      if (Global.SALEMODE === '1') {
        props.ws.send('ADDPRODUCTTOBASKET|' + Global.USER + '|' + props.amountBarcode + '|' + props.partnerId + '|' + props.delivery + '|' + inAmount);
      }
      else if (Global.SALEMODE === '2' ) {
        props.ws.send('ADDPRODUCTTOTABLE|' + Global.USER + '|' + props.amountBarcode + '|' + props.chosenTable + '|' + props.partnerId + '|' + props.delivery + '|' + inAmount);
      }
      props.setAmountDialog(false);
      props.setDisableClick(false);
    }
  }

  return(
    <div style={styles.container}>
      <TextField
        value={amount}
        label="Mennyiség"
        variant="outlined"
        onChange={(event) => setAmount(event.target.value)}
      />
      <Button style={{...styles.greyBtn, flex:'60%',padding:'10px 2px',backgroundColor:'#005dff',color:'white',fontWeight:'bold'}}
      onClick={handleDone} >Hozzáadás</Button>
    </div>
  )

} 

export default AmountDialog;
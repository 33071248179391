import React, { useState } from 'react';
import { Typography, TextField, Grid, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

import * as Global from '../Global';
import { ws } from '../App.js';

const styles = {
  main: {
    alignItems: 'center',
    minHeight: 'calc(100vh - 30px)',
    padding: '15px'
  },
  paper: {
    padding: 15,
    width: '100%',
    maxWidth: 500,
    textAlign: 'center'
  },
  inputDiv: { 
    marginBottom: 15,
    minWidth: 250,
  },
  paymentButton: { 
    marginBottom: 15 
  }
}
   
const PaymentWindow = (props) => {
//kiválasztja a kezdőnapot, megadja, hogy plusz hány napot akar befizetni, comboboxból kiválasztja, hogy átut vagy bankkártyás fizetés
  const [paymentPackage, ] = useState(props.payPackages);
  const [chosenPackage, setChosenPackage] = useState('');
  const [date, setDate]                   = useState('');
  const [months, setMonths]               = useState('');
  const [amountToPay, setAmountToPay]     = useState('');
  const [loading, ]             = useState(false);

  const setPayment = () => {
    let today = new Date();
    today.setDate(today.getDate() - 1)
    let yesterday = today;
    if(new Date(date) <= yesterday){
      console.log('Nem elküldhető');
      return;
    }
    if(months === ''){return;}
    if(chosenPackage === ''){return;}
    else {
      let endDate = new Date(date);
      endDate.setHours(12,0,0);
      endDate.setMonth(new Date(date).getMonth() + parseInt(months));
      ws.send('ADDPAYMENT|'+Global.USER + '|' + chosenPackage + '|' + dateToString(new Date(date)) + '|' + dateToString(endDate));
      //ws.send('ADDPAYMENT|'+Global.USER + '|' + chosenPackage + '|2022-02-11 12:00|2022-04-12 11:00')
    }

  }

  const calculate = ( timeEnd = new Date(date), month = months, chosen = chosenPackage) => {
    let price = '';
    if(chosen !== ''){
      price = paymentPackage.find(obj => {
        return obj.id === chosen
      })
      price = price.price;
    }
    if(timeEnd !== ''){
      timeEnd = new Date(timeEnd);
      timeEnd.setHours(12,0,0); //kell, mert óraátállítás miatt lehet hogy napot veszítene, mivel alapból 00:00-kor van
      timeEnd.setMonth(timeEnd.getMonth() + parseInt(month));
    }
    if( month !== '' &&  price !== ''){
      let str = 'A fizetendő összeg ' + month*price + ' Ft ';
      if(timeEnd !== ''){
        str += ' és ' + dateToString(timeEnd) + ' dátumon járna le az előfizetés';
      }
      setAmountToPay(str);
    }
  }

  function dateToString(date){
		return '' + date.getFullYear() + '.'
							+ (date.getMonth() <9 ? '0' : '') + (date.getMonth() + 1) + '.'
							+ (date.getDate() <10 ? '0' : '') + date.getDate();
  }

  const dateChecker = (e) => {
    let today = new Date();
    today.setDate(today.getDate() - 1)
    let yesterday = today;
    if(new Date(e.target.value) <= yesterday){
      toast.error('A mai dátumnál nem adhat meg régebbi dátumot', {autoClose: 5000})
      e.preventDefault();
    }
  }

  return (
    <Grid container direction={'column'} style={ styles.main }>
      <Grid style={ styles.inputDiv }>
        <Typography>Dátumtól</Typography>
        <TextField
          value={date}
          fullWidth 
          type="date"
          onChange={(e) => {dateChecker(e); setDate(e.target.value); calculate(e.target.value, months, chosenPackage )}}
        />
      </Grid>
      <Grid style={ styles.inputDiv }>
        <Typography>Hónapok száma</Typography>
        <TextField
          value={months}
          fullWidth 
          type="number"  
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: '1', max: '12' }}
          onChange={(e) => {setMonths(e.target.value); calculate(date, e.target.value, chosenPackage)}}
        />
      </Grid>
      <Grid style={ styles.inputDiv }>
        <Typography>Csomag</Typography>
        <Select
          id="simple-select"
          value={chosenPackage}
          displayEmpty
          fullWidth
          onChange={(e) => {setChosenPackage(e.target.value); calculate(date, months, e.target.value)}}
          >
          {paymentPackage.map((payment) => {
            return(<MenuItem key={payment.id} value={payment.id}>{payment.name}  {payment.price} Ft</MenuItem>)
          })}
        </Select>
      </Grid>
      <Grid style={ styles.inputDiv }>
        <Typography>{amountToPay}</Typography>
      </Grid>
      <LoadingButton
        onClick={ () => setPayment() }
        loading={ loading }
        variant="contained"
        style={ styles.paymentButton }
        disabled={ loading }
      >
        Fizetés
      </LoadingButton>
    </Grid>
  )
}

export default PaymentWindow;
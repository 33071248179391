import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Accordion, Box } from '@mui/material';
import { Typography } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ReactApexChart from 'react-apexcharts';
import { createApexBarOptions, createApexBarSeries } from './DefaultApexOptions';
import moment from 'moment';

const DayByDayStats = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [isOpened, setOpened] = useState(false);
  const apexSeries = useMemo(() => createApexBarSeries(data.map(d => d.y)), [data]);
  const apexOptions = useMemo(() => createApexBarOptions(data.map(d => d.label), 'Napi forgalom adott intervallumon'), [data]);

  useImperativeHandle(ref, () => {
    return{
      setData: (_val) => {
        let val = [..._val];
        val = val.map(d => ({
          ...d,
          label: moment(d.label, 'DD-MM-YY').format('YYYY.MM.DD.')
        }));
        setData(val);
      },
    }
  });

  const getData = useCallback(() => {
    if(!isOpened){
      props.setModalProps({
        open:true,
        function:'GETDAYBYDAYTRAFFIC'
      });
    }
    else{
      setData([]);
    }
    setOpened(val => !val);
  }, [isOpened, props]);

  return (
  <>
    <Accordion onChange={getData}>
      <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
        <Typography>Napi forgalom adott intervallumon</Typography>
      </AccordionSummary>
      <Box display={data.length ? "block" : "none"}>
        <ReactApexChart options={apexOptions} series={apexSeries} type="bar" height={data.length * 60 + 100} />   
      </Box>
    </Accordion>
  </>
  );
})

export default DayByDayStats;
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';

/*****************************************
 props:
    datas: all the possible datas, array constant
    selected: the selected datas, changed by this component, array
    onChane: syncing the parents' "selected", function
    goal: fills when datas is empty, string
 *****************************************/

const SimpleSelect = forwardRef((props, ref) => {
  const [selected, setSelected] = React.useState(props.selected ?? '');
  const [datas, setDatas] = React.useState(props.datas ?? []);
  
  const handleChange = (event) => {
    props.onChange(event);
    setSelected(event.target.value);
  };

  useImperativeHandle(ref, () => {
    return {
      setDatas : setDatas,
      setSelected : setSelected
    }
  });

  return (
    <FormControl sx={{ m: 1, maxWidth: 227, margin:0,  width:"100%", ...props.style }}>
      <Select
        value={selected}
        onChange={handleChange}
        displayEmpty
        sx={{textAlign: "left"}}
      >
        {
          datas.length > 0 ?
          datas.map((data,i) => {
            return(
              <MenuItem key={i} value={data.id}>{data.label}</MenuItem>
            )
          })
          :
          <MenuItem value={""}>Nincsenek megadva {props.goal}</MenuItem>
        }
      
      </Select>
    </FormControl>
  );
});

export default SimpleSelect;
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import * as Global from '../../Global';
import { Accordion, AccordionDetails, Box, Button, Dialog, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { createApexBarOptions, createApexBarSeries } from './DefaultApexOptions';

const MonthlyTraffic = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const apexSeries = useMemo(() => createApexBarSeries(data.map(d => d.y)), [data]);
  const apexOptions = useMemo(() => createApexBarOptions(data.map(d => d.label), 'Havi forgalom'), [data]);

  useImperativeHandle(ref, () => {
    return{
      setData: (val) => {
        setData(val.sort((a, b) => moment(a.label, 'YYYY-MM') - moment(b.label, 'YYYY-MM')));
      },
    }
  });

  const getData = useCallback((date = moment()) => {
    props.ws.send('GETMONTHLYTRAFFIC|'+Global.USER+'|'+moment(date).year());
  }, [props.ws]);

  const onYearPick = useCallback((e) => {
    getData(e);
    setYearPickerOpen(false);
  }, [getData]);

  return (
  <>
    <Accordion onChange={getData}>
      <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
        <Typography>Havi forgalmak</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Button onClick={() => setYearPickerOpen(true)} variant="contained">
          Év választás
        </Button>
        <Box display={data.length ? "block" : "none"}>
          <ReactApexChart options={apexOptions} series={apexSeries} type="bar" height={data.length * 60 + 100} />    
        </Box>
      </AccordionDetails>
    </Accordion>
    <Dialog
      open={isYearPickerOpen}
      onClose={() => setYearPickerOpen(false)}
    >
      <DialogTitle>Válassz egy évet a havi statisztikák megnézéséhez</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {years.map((y, i) => 
            <Grid item xs={12} sm={4} key={i} onClick={() => onYearPick(moment(y, 'YYYY'))}>
              <Paper
                style={{padding: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}
                elevation={6}
              >
                {y}
              </Paper>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  </>
  );
});

const years = [];
for(let i = 0; i < 6; i++){ years.push(moment().year() - i); }

export default MonthlyTraffic;
import {useState, useEffect} from 'react';
import { Button, ButtonGroup, IconButton, TextField } from '@mui/material';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import * as Global from '../../Global';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const styles = {
  tableInvoice: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: '1',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      margin: '5% auto',
      width: 'fit-content',
      padding: '25px',
      border: '2px solid rgba(0,0,0,0.3)',
      borderRadius: '5px',
      maxHeight: '70%',
      maxWidth:'70%',
      overflowY: 'auto',

  },
  signs:{
    backgroundColor: 'rgb(245, 245, 245)',
    borderRadius:8
  },
  icon:{
    boxShadow:'rgb(28, 191, 219) 0px 0px 10px',
    backgroundColor:'rgb(28, 191, 219)',
    width:40,
    height:40,
    borderRadius:8,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    marginRight:16
  }
}


const StornoModal = (props) => {
  const [products, setProducts] = useState(props.items);
  const [total, setTotal] = useState(0);
  const [stornoTip, setStornoTip] = useState(0);

  useEffect(() => {
    getFinal();
    // eslint-disable-next-line
  }, [stornoTip])

  const plusOne = (product, index) => {
    let temp = products;
    if(isNaN(temp[index].tempAmount) && temp[index].amount !== 0){
      temp[index].tempAmount = 1;
    }
    else if (temp[index].tempAmount < temp[index].amount) temp[index].tempAmount += 1;
    setProducts(temp);
    getFinal();
  }

  const minusOne = (product, index) => {
    let temp = products;
    if(temp[index].tempAmount > 0){
      temp[index].tempAmount -= 1;
    }
    setProducts(temp);
    getFinal();
  }

  const getFinal = () => {
    let temp = 0;
    for(let count in products) {
      if(products[count].tempAmount > 0) {
        temp += products[count].tempAmount * products[count].gross;
      }
    }
    temp += parseInt(stornoTip);
    setTotal(temp);
  }

  const stornoHandler = () => {
    let wsString = '';
    for (let count in products) {
      if (products[count].tempAmount !== 0) {
        wsString += products[count].id + '|' + products[count].tempAmount + '|';
      }
    }
    if (wsString.length > 0 ) {
      wsString = 'STORNORECEIPT|' + Global.USER + '|' + props.szlaszam + '|' + stornoTip + '|' + wsString;
    }
    props.ws.send(wsString);
  }
 
  const handleTip = (e) => {
    if(e.target.value <= props.tip) {
      setStornoTip(e.target.value);
    }
  }

  return(
    <div style={styles.tableInvoice}>
      <p style={{display:'flex',height:'fit-content',alignItems:'center',  paddingBottom:24,borderBottom:'1px solid rgba(0,0,0,0.3)'}}>
        <div style={styles.icon}>
          <ThumbUpAltOutlinedIcon color="black"/>
        </div>
        Stornóhoz hozzáadott
      </p>
      <div style={{overflowX:'auto'}}>
        {products.map((product, index) => (
          <div key={index} style={{display:'flex', marginBottom:16}}>
            <div style={{display:'inline-flex',width:'min-content'}}>
              <IconButton style={styles.signs} onClick={() => minusOne(product, index)}>
                <RemoveIcon />
              </IconButton>
              <div style={{display:'inline-flex',margin:'0 12px',alignSelf:'center',fontWeight:700}}>{product.tempAmount}</div>
              <IconButton style={styles.signs} onClick={() => plusOne(product, index)}>
                <AddIcon/>
              </IconButton>
            </div>
            <div style={{display:'inline-flex',paddingLeft:16,paddingRight:16,width:'100%'}}>
              <div style={{width:'60%',minWidth:100,justifyContent:'left',display:'flex',alignItems:'center',fontWeight:600}}>{product.name} ({product.amount}&nbsp;db)</div>
              <div style={{width:'20%',minWidth:100,justifyContent:'center',display:'flex',alignItems:'center',fontWeight:600}}>{product.gross.toLocaleString('hu')}&nbsp;Ft/db</div>
              <div style={{width:'20%',minWidth:100,justifyContent:'right',display:'flex',alignItems:'center',fontWeight:600,color:'#fbae4b'}}>{(product.amount*product.gross).toLocaleString('hu')}&nbsp;Ft</div>
            </div>
          </div>
        ))}
        <div style={{display:'inline-flex',paddingLeft:16,paddingRight:16,width:'90%', marginBottom: 16}}>
          <div style={{width:'60%',minWidth:100,justifyContent:'left',display:'flex',alignItems:'center',fontWeight:600}}>Stornózható borravaló: {props.tip} Ft</div>
          <TextField size="small" value={stornoTip} onChange={(e) => handleTip(e)} ></TextField>
        </div>
      </div>
      <p style={{marginTop:0,paddingTop:16,borderTop:'1px solid rgba(0,0,0,0.3)',textAlign:'right',fontSize:26,fontWeight:'bold'}}>{total.toLocaleString('hu')} Ft</p>
      <div style={{textAlign:'right'}}>
        <ButtonGroup variant="contained" style={{boxShadow:'none'}}>
          <Button style={{backgroundColor:'#284aff',color:'white',boxShadow:'#284aff 0px 0px 10px',borderRadius:8,marginRight:16}} onClick={(e) => stornoHandler()}>Fizetés</Button>
          <Button style={{backgroundColor:'white',color:'#284aff',border:'#284aff 1px solid',borderRadius:8}} onClick={(e) => props.setModal(false)}>Mégse</Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default StornoModal;
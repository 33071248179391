
import React from 'react';

import { Typography, AppBar, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import BlackLogo from '../images/hardbit_fekete.svg';

const styles = {
  icon:{
    marginLeft:0,
    color:'black',
    backgroundColor:'#1cbfdb',
    boxShadow:'0px 0px 10px #1cbfdb',
    padding:4,
    borderRadius:8
  }
}

const Appbar = (props) => {
  return (
    <AppBar open={props.sidebar}
      sx={{
        width: `calc(100% - ${props.sidebar ? props.sidebarWidth : 0}px)`,
        marginLeft: `${props.sidebar ? props.sidebarWidth : 0}px`,
        transition: props.theme.transitions.create(['margin', 'width'], {
          easing: props.theme.transitions.easing.easeOut,
          duration: props.theme.transitions.duration.enteringScreen,
        }),
        position: 'static',
      }}    
      style={{backgroundColor:'rgba(0,0,0,0)',boxShadow:'0px 0px'}}
    >
      <Toolbar style={{padding:'0 23px'}} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => props.setSidebar(true)}
          edge="start"
          sx={{ mr: 2, ...(props.sidebar && { display: 'none' }) }}
          style={styles.icon}
        >
          <MenuIcon sx={{color:"black"}}/>
        </IconButton>
        <Typography style={{color:"black"}} variant="h6" noWrap component="div">
          {props.windowText}
        </Typography>
        <img src={BlackLogo} style={{height:25,right:25,position:'absolute'}} alt="Company logo"/>
      </Toolbar>
    </AppBar>
  )
};

export default Appbar;

import React, { useEffect, useState, useRef} from "react";
import { Button, ButtonGroup, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, Typography, Input, InputAdornment} from "@mui/material";
import { Search } from "@mui/icons-material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as Global from '../Global';

const COLUMNS = [
  {igazit:"l",fej:"Külső azonosító"},
  {igazit:"l",fej:"Keletkezés ideje"},
  {igazit:"r",fej:"Nettó ár"},
  {igazit:"r",fej:"Bruttó ár"},
  {igazit:"l",fej:"Eladó"}
];

const styles = {
  ul:{
    paddingLeft:0,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:0,
    marginRight:0,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'20%',
    minWidth:180,
    flex:'auto',
    borderBottom:'none',
    padding:0,
    justifyContent:'center',
  },
  pagingButton:{
    width:40,
    height:40,
    padding:0,
    justifyContent:'center',
    alignItems:'center',
    borderRadius:8,
    backgroundColor:'rgb(40, 74, 255)',
    boxShadow:'rgb(40, 74, 255) 0px 0px 10px',
  },
  buttons:{
    color:'white',
    backgroundColor:'rgb(40, 74, 255)',
    borderColor:'white',
    borderRadius:0
  }
}

const DataGrid = (props) => {
  const [columns, setColumns]               = useState(COLUMNS);
  const [constReceipts,]                    = useState(props.receipts);
  const [rows, setRows]                     = useState(props.receipts);
  const [filterString, setFilterString]     = useState('');
  const [maxRowCount, setMaxRowCount]       = useState(10);
  const [page, setPage]                     = useState(0);
  const [checkedRows, setCheckedRows]       = useState([]);

  const selected = useRef(null);

  useEffect(() => {
    setColumns(props?.adat?.fejlec ?? COLUMNS);
    setRows(props?.adat?.adatok ?? props.receipts);
    setCheckedRows(props?.adat?.kijelolve ?? []);
  }, [props]);

  useEffect(() => {//updated
    const filterList = () => {
      let temp = [];
      for(let i in constReceipts){
        for(let j in constReceipts[i]){
          if(String(constReceipts[i][j]).toLowerCase().includes(filterString.toLowerCase())){
            temp.push(constReceipts[i]);
            break;
          }
        }
      }
      setRows(temp);
    }
    if (filterString.length !== 0) {
      filterList();
      setPage(0);
      selected.current = 0;
    } 
    else {
      filterList(); 
    }
    // eslint-disable-next-line
  }, [filterString, props])

  const changePage = (direction) => {
    if (direction === 1) { // előre
      if (page >= Math.ceil(rows.length / maxRowCount) - 1) { return false; }
      setPage(page => page + 1);
    } else if (direction === -1) { // vissza
      if (page <= 0) { return false; }
      setPage(page => page - 1);
    }
  }

  const handleButtonClick = (count) => {
    if (count === maxRowCount) { return false; }
    selected.current = 0;
    setPage(0);
    setMaxRowCount(count);
  };

  const onRowClick = (e, row, index) => {
    if(props.type === undefined){
      props.ws.send('GETPRODUCTSBYRECEIPT|'+Global.USER+'|'+row[0]);
    }
    else if (props.type === 'receipts') {
      props.ws.send('GETPRODUCTSBYRECEIPT|'+Global.USER+'|'+row[0]);
    }
    else if (props.type === 'storno') {
      console.log('GETSTORNOPRODUCTS|'+Global.USER+'|'+row[0])
      props.ws.send('GETSTORNOPRODUCTS|'+Global.USER+'|'+row[0]);
    }
  }

  return (
    <div style={{ padding: 20, zIndex: 1370, margin: "auto", overflow:"auto", width:'fit-content' }}>
      <div className="modal-table-content" style={{textAlign:'center'}}>    
        <Input
          style={{marginBottom:30}}
          onChange={event => { selected.current = 0; setFilterString(event.target.value); }}
          placeholder={"Keresés ("+rows.length+")"}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
        <div id="list-div">
          <MuiTable>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell 
                    style={styles.li} 
                    key={`list_header_${index}`} 
                    align="center"                    
                  >
                    {column.fej}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * maxRowCount, maxRowCount + page * maxRowCount).map((row, rowIndex) => (
                <TableRow 
                  style={styles.ul}
                  key={`list_body_${rowIndex}`} 
                  onClick={(e) => onRowClick(e, row, rowIndex)} 
                  className={`${String(selected.current) === String(rowIndex) ? 'selected' : ''} ${checkedRows.includes(row[0]) ? 'checked' : ''}`} 
                  data-key={row[0]}
                  sx={[
                    {
                      '&:hover': {
                        backgroundColor: 'rgb(250,250,250) !important',
                        cursor:'pointer'
                      },
                    },
                  ]}
                >
                    {row.slice(2).map((rowColumn, rowColumnIndex) => (
                      <TableCell style={styles.li} key={`list_row_${rowColumnIndex}`} align={columns[rowColumnIndex].igazit === 'r' ? 'right' : 'left'}>
                        {rowColumn}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
        {rows.slice(page * maxRowCount, maxRowCount + page * maxRowCount).length === 0 &&
          <Typography
            style={{
              fontSize: 40,
              fontWeight: 600,
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            Nincsenek itt nyugták
          </Typography>
        }
        <ButtonGroup variant="outlined" sx={{marginTop:"10px"}}>
          <Button style={styles.buttons} className={maxRowCount === 10 ? 'selected-count' : ''} onClick={() => handleButtonClick(10)}>10</Button>
          <Button style={styles.buttons} className={maxRowCount === 25 ? 'selected-count' : ''} onClick={() => handleButtonClick(25)}>25</Button>
          <Button style={styles.buttons} className={maxRowCount === 50 ? 'selected-count' : ''} onClick={() => handleButtonClick(50)}>50</Button>
          <Button style={styles.buttons} className={maxRowCount === 100 ? 'selected-count' : ''} onClick={() => handleButtonClick(100)}>100</Button>
        </ButtonGroup>
        <ButtonGroup variant="outlined" sx={{marginTop:"10px", float:"right"}}>
          <Button sx={[{'& span':{margin:0}}]} style={styles.pagingButton} onClick={() => changePage(-1)} endIcon={<ChevronLeftIcon style={{margin:0}}/>} variant="contained"></Button>       
          <Typography component="span" sx={{margin:"10px"}}>
            {page + 1}&nbsp;/&nbsp;{Math.ceil(rows.length / maxRowCount)}
          </Typography>
          <Button sx={[{'& span':{margin:0}}]} style={styles.pagingButton} onClick={() => changePage(1)} endIcon={<ChevronRightIcon style={{margin:0}}/>} variant="contained"></Button> 
        </ButtonGroup>
      </div>
    </div>
  );
}

export default DataGrid;
export function passwordValidate(password){
  let regex = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[A-Z]).*$/;
  
  if (regex.test(password)) {
    return true;
  }
  else {
    return false;
  }
}

export function passwordError(password){
  let lengthRegex = /^.*(?=.{8,}).*$/;
  let numbersRegex = /^.*(?=.\d).*$/;
  let lowerRegex = /^.*(?=[a-z]).*$/;
  let upperRegex = /^.*(?=[A-Z]).*$/;

  if (!numbersRegex.test(password)){
    return 'A jelszónak tartalmaznia kell számokat!';
  }
  else if (!lowerRegex.test(password) || !upperRegex.test(password)){
    return 'A jelszónak tartalmaznia kell kis és nagy betűket!';
  }
  else if (!lengthRegex.test(password)){
    return 'A jelszó túl rövid!';
  }
}
import { React, useState } from "react";
import { TextField } from "@mui/material";
import * as Global from '../Global';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const styles={
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

const CurrencyRow = (props) => {
  const [currency, setCurrency] = useState(props.currency);
  const [edit, setEdit] = useState(false);

  const Saving = () => {
    if(edit){
      setEdit(false);    
      props.ws.send('UPDATECURRENCY|'+Global.USER+'|'+currency.id+'|'+currency.value)
    }
    else
      setEdit(true);
  };
  
  return(
    <li>
      <ul style={styles.ul}>
        <li style={{...styles.li,width:'15%',minWidth:60}}>{currency.name}</li>
        <li style={{...styles.li,width:'60%',minWidth:180,justifyContent:'left'}}>{currency.desc}</li>
        <li style={{...styles.li,width:'15%',minWidth:60,justifyContent:'right'}}>
          { edit ? 
            <TextField type="number" size="small" onChange={e => {
              setCurrency({...currency,value:parseFloat(e.target.value)})          
            }} value={currency.value}/>
            : currency.value.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })
          }
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={{...styles.editDiv,borderColor:'#7186fa'}} onClick={Saving} >
            {edit?
              <SaveIcon style={{color:'#7186fa'}}/>
              :
              <EditIcon  style={{color:'#7186fa'}}/>
            }
          </div>
        </li>
      </ul>
    </li>
  )    
}

export default CurrencyRow;
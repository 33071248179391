import { React, useState } from "react";
import { TextField } from "@mui/material";
import { red, green } from '@mui/material/colors';
import * as Global from '../Global';
import { toast } from 'react-toastify';
import DeleteCategoryModal from "./modals/DeleteCategoryModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimpleSelect from "./SimpleSelect";
import { useRef } from "react";
import { useEffect } from "react";

const styles={
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

const CategoryListItem = (props) => {
  const [category, setCategory] = useState(props.category);
  const [edit, setEdit] = useState(category.name === "" ? true : false);

  const ntakSelect = useRef();


  useEffect(() => {
    if(category !== props.category && !edit){
      setCategory(props.category);
      ntakSelect.current.setSelected(props.category.ntakid);
    }
  // eslint-disable-next-line
  },[props])
  

  const Save = () => {
    if(!edit){
      setEdit(true);
      return
    }
    else{
      props.ws.send('UPDATECATEGORY|'+Global.USER+"|"+category.id+"|"+category.name+"|"+category.status+"|"+props.groupID);
      setEdit(false);
    }
  }

  const Edit = (e) => {
    if(e.target.value.length <= 58)
      setCategory({...category,name:e.target.value})         
    else
      toast.error("A megnevezés nem lehet hosszabb, mint 58 karakter")
  }

  const ntakGroupUpdate = (value) => {
    props.ws.send('UPDATENTAKCATEGORY|' + Global.USER + '|' + category.id + '|' + value + '|' +props.groupID);
  } 

  return(
    <li>
      <ul style={styles.ul}>
        <li style={{...styles.li,width:'50%',minWidth:180}}>
          {
            edit ? 
              <TextField size="small" onChange={Edit} value={category.name}/>
              : category.name
          }
        </li>
        <li style={{...styles.li,width:'20%',minWidth:180}}>
          <SimpleSelect 
            ref={ntakSelect}
            selected={category.ntakid}
            datas={props.ntakCategories}
            onChange={e => ntakGroupUpdate(e.target.value)}
            goal={"kategóriák"}
          />
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          {category.status?
            <CheckCircleIcon 
              onClick={() => {
                if(edit){
                  toast.error("Szerkesztés alatt nem lehet státuszt változtatni");
                  return
                }
                props.ws.send('UPDATECATEGORYSTATUS|'+Global.USER+"|"+category.id+"|false|"+props.groupID);
              }} 
              sx={{
                color:green[500],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
            :
            <CancelIcon 
              onClick={() => {
                if(edit){
                  toast.error("Szerkesztés alatt nem lehet státuszt változtatni");
                  return
                }
                props.ws.send('UPDATECATEGORYSTATUS|'+Global.USER+"|"+category.id+"|true|"+props.groupID);
              }} 
              sx={{
                color:red[400],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
          }
          
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={styles.editDiv} onClick={Save}>
            {edit?
              <SaveIcon style={{color:'#7186fa'}}/>
              :
              <EditIcon  style={{color:'#7186fa'}}/>
            }
          </div>
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={{...styles.editDiv,borderColor:'red'}} onClick={() => props.setModal(<DeleteCategoryModal ws={props.ws} id={category.id} groupID={props.groupID} count={category.products} setModal={props.setModal}/>)}>
            <DeleteForeverIcon  style={{color:'red'}}/>
          </div>
        </li>
      </ul>
    </li>
  )
}

export default CategoryListItem

import { React, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import * as Global from '../Global';
import TableListItem from "./TableListItem";

const style = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  buttons: {
    marginBottom: "8px"
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
}

const TableList = (props) => {
  const [tables, setTables] = useState(props.tables);

  if(tables !== props.tables){
    setTables(props.tables);
  }

  return(
    <div style={style.main}>      
      <div style={style.tableDiv}>
        <ul style={style.mainUL}>
          <li style={{minWidth:600}}>
            <ul style={{listStyleType:'none',minWidth:600,paddingLeft:16, margin:'0px 6px', display:'flex'}}>
            <li style={{...style.li,width:'70%',minWidth:180, justifyContent:'left'}}>Megnevezés</li>
              <li style={{...style.li,width:'10%',minWidth:40, justifyContent:'center'}}>Státusz</li>
              <li style={{...style.li,width:'10%',minWidth:40, justifyContent:'left'}}></li>
              <li style={{...style.li,width:'10%',minWidth:40, justifyContent:'center'}}>
                <div style={style.plusSign} onClick={()=>props.ws.send("ADDTABLE|"+Global.USER)}>
                  <AddIcon sx={{color: 'white'}} />
                </div>
              </li>
            </ul>
          </li>
          <li>
            {
              tables.length > 0 ?
              tables.map((table, i) => {
                return(
                  <TableListItem ws={props.ws} key={i} table={table}/>
                )
              })
              :
              <li style={{...style.li,width:'100%',minWidth:40, justifyContent:'center'}}>
                Nincsenek asztalok
              </li>
            }
          </li>
        </ul>        
      </div>
    </div>
  );
}

export default TableList;
import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

import * as Global from '../../Global';

const styles = {
  partnerList: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: '1',
      position: 'fixed',
      backgroundColor: '#F5F5F5',
      left : '0',
      right : '0',
      margin: '5% auto',
      width: '400px',
      padding: '25px',
      border: '1px solid rgba(0, 0, 0, 0.61)',
      borderRadius: '5px',
      maxHeight: '70%',
      overflowY: 'auto',
      head: {

      },
      inside: {

      },
      button: {
        backgroundColor: '#FFFF',
        borderRadius: '8px',
        color: '#000',
        width: '100%',
        marginTop: '4px',
        marginBottom: '4px',
        border: 'solid #284aff 2px'
      }

  },

}

const SalePartnerList = (props) => {
  const [partners] = useState(props.partners);
  const [partnerSearch, setPartnerSearch] = useState("");

  const partnerClickHandler = (id, name) => {
    if (Global.SALEMODE === "2" && props.chosenTable !== 0) {
      props.ws.send('CHOOSETABLEPARTNER|' + Global.USER + '|' + props.chosenTable + '|' + id);
    }
    props.setShowPartner(false);
    props.setDisableClick(false);
    props.setPartnerName(name);
    props.setPartnerId(id);
  }

  const cancelHandler = () => {
    props.setShowPartner(false);
    props.setDisableClick(false);
  } 

  return (
    <div style={styles.partnerList}>
      <TextField
        style={styles.partnerList.textField}
        hiddenLabel
        size="small"
        fullWidth={true}
        value={partnerSearch}
        onChange={(e) => setPartnerSearch(e.target.value)}
      />
      {partners.map((partner) => {
        if(partner.name.toLowerCase().includes(partnerSearch.toLowerCase())){
          return(
          <div key={partner.id}>
            <Button style={styles.partnerList.button} variant="contained" onClick={() => partnerClickHandler(partner.id, partner.name + ' ( -' + partner.discount + '% )')}>
              <p>{partner.name} ( -{partner.discount}% ) {partner.address}</p>
            </Button>
          </div>
          )
        }
        else return(<></>)
      })}
      <Button style={{...styles.partnerList.button, borderColor: '#ef5350'}} variant="contained" onClick={() => cancelHandler()}>
        <p>Mégse</p>
      </Button>
    </div>
  )
}

export default SalePartnerList;
import React, { useState, useMemo } from 'react';
import { Typography, TextField, Button, ButtonGroup, Accordion, AccordionSummary, AccordionDetails, Grid, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import * as Global from '../../Global';
import Malfunctions from './Malfunctions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useBarcode } from 'react-barcodes';

const calcAmount = (beautyCard, cash, credit, transfer, coupon, euro, other, payAmount, exchange, roomCredit, givenAmount) => {
  let pBeauty   = parseInt(beautyCard);
  let pCash     = parseInt(cash);
  let pCredit   = parseInt(credit);
  let pCoupon   = parseInt(coupon);
  let pTransfer = parseInt(transfer);
  let pEuro     = parseInt(euro);
  let pOther    = parseInt(other);
  let pRoomCr   = parseInt(roomCredit);
  let pGiven    = parseInt(givenAmount);
  let round     = pGiven -  Math.round(pGiven/5)*5;

  if(isNaN(pBeauty))   pBeauty = 0;
  if(isNaN(pCash))     pCash = 0;
  if(isNaN(pCredit))   pCredit = 0;
  if(isNaN(round))     round = 0;
  if(isNaN(pCoupon))   pCoupon = 0;
  if(isNaN(pTransfer)) pTransfer = 0;
  if(isNaN(pEuro))     pEuro = 0;
  if(isNaN(pOther))    pOther = 0;
  if(isNaN(pRoomCr))   pRoomCr = 0;

  if((pBeauty + pCash + pCredit + pCoupon + pTransfer + (pEuro * exchange) + pOther + pRoomCr) > givenAmount) {
    return ((Math.round(givenAmount/5)*5 - (pBeauty + pCash + pCredit + pCoupon + pTransfer + (pEuro * exchange) + pOther + pRoomCr)) * -1)
  }
  else {
    return 0;
  }
}

const Barcode = (props) => {
  const { inputRef } = useBarcode(props.barcode);

  return <svg ref={inputRef}/>;
}

const CashierPay = (props) => {
  // const malfunction = 0;
  const [isNumpadOpen, setNumpadOpen] = useState(false);
  const [serviceSum,] = useState(props.serviceSum);
  const [givenAmount, setGivenAmount] = useState(props.gross);
  const [tip, setTip] = useState(0);
  const [payAmount, ] = useState(props.gross);
  //const [change, setChange] = useState(0);
  const [beautyCard, setBeautyCard] = useState('');
  const [cash, setCash] = useState('');
  const [credit, setCredit] = useState('');
  const [transfer, setTransfer] = useState('');
  const [coupon, setCoupon] = useState('');
  const [euro, setEuro] = useState('');
  const [other, setOther] = useState('');
  const [roomCredit, setRoomCredit] = useState('');
  const [malfunction, setMalfunction] = useState(0);
  const [setterOfFocusedInput, setSetterOfFocusedInput] = useState(() => () => {});
  const change = useMemo(() => calcAmount(beautyCard, cash, credit, transfer, coupon, euro, other, payAmount, props.exchange, roomCredit, givenAmount), 
                                         [beautyCard, cash, credit, transfer, coupon, euro, other, payAmount, props.exchange, roomCredit, givenAmount])
                                         //added props.exchange, remove if necessary

  const beautyCardHandler = (e) => {
    if(parseInt(beautyCard)+e.key > Math.round(givenAmount*5)/5){
      e.preventDefault();
    } 
  }

  const couponHandler = (e) => {
    if (parseInt(coupon)+e.key > Math.round(givenAmount*5)/5) {
      e.preventDefault();
    }
  }

  const onCashBtn = () => {
    if(givenAmount > 0)
      setCash(Math.round(parseInt(givenAmount) / 5) * 5);
    else
      setCash(Math.round(parseInt(payAmount) / 5) * 5);
  };

  const onGivenAmountBtn = () => {
    setGivenAmount(cash);
    setTip(isNaN(cash - payAmount) ? 0 : cash - payAmount);
  };

  const onOpenNumpadButton = () => {
    setNumpadOpen(val => !val);
  }

  const creditHandler = () => {
    let pBeauty   = parseInt(beautyCard);
    let pCash     = parseInt(cash);
    let pCoupon   = parseInt(coupon);
    let pTransfer = parseInt(transfer);
    let pEuro     = parseInt(euro);
    let pOther    = parseInt(other);
    let pRoom     = parseInt(roomCredit);
    let pGiven    = parseInt(givenAmount);
    if(isNaN(pGiven))    return;
    if(isNaN(pBeauty))   pBeauty = 0;
    if(isNaN(pCash))     pCash = 0;
    if(isNaN(pCoupon))   pCoupon = 0;
    if(isNaN(pTransfer)) pTransfer = 0;
    if(isNaN(pEuro))     pEuro = 0;
    if(isNaN(pOther))    pOther = 0;
    if(isNaN(pRoom))     pRoom = 0;

    if((pBeauty + pCash + pCoupon + pTransfer + (pEuro * props.exchange) + pOther + pRoom)  < pGiven) {
      setCredit(pGiven - (pBeauty + pCash + pCoupon + pTransfer + (pEuro * props.exchange) + pOther + pRoom));
    }
  }

  const payHandler = () => {
    //parsing the inputs
    let pBeauty   = parseInt(beautyCard);
    let pCash     = parseInt(cash);
    let pCredit   = parseInt(credit);
    let pChange   = parseInt(change);
    let pCoupon   = parseInt(coupon);
    let pTransfer = parseInt(transfer);
    let pEuro     = parseInt(euro);
    let pOther    = parseInt(other);
    let pRoomCr   = parseInt(roomCredit);
    let pGiven    = parseInt(givenAmount);

    //set to 0 the ungiven ones
    if(isNaN(pBeauty))   pBeauty = 0;
    if(isNaN(pCash))     pCash = 0;
    if(isNaN(pCredit))   pCredit = 0;
    if(isNaN(pChange))   pChange = 0;
    if(isNaN(pCoupon))   pCoupon = 0;
    if(isNaN(pTransfer)) pTransfer = 0;
    if(isNaN(pEuro))     pEuro = 0;
    if(isNaN(pOther))    pOther = 0;
    if(isNaN(pRoomCr))   pRoomCr = 0;
    if(isNaN(pGiven))    pGiven = 0;

    //rounding
    let round = pGiven - (pBeauty + pCredit + pCoupon + pTransfer + pOther + pRoomCr); //először leszedjük azokat amik nem kerek számok is lehetnek
    round = round - Math.round(round/5)*5;

    //all the inputs
    let currTotal = Math.round(pBeauty + pCash + pCredit + pCoupon + pTransfer + (pEuro * props.exchange) + pOther + pRoomCr);
    
    // non changable payment shall not be more then the payAmount
    if(pGiven < pBeauty + pCredit + pCoupon + pTransfer + pOther + pRoomCr){
      toast.error('A kártyák és egyéb fizetési módok nem haladhatják meg az átvett összegeket!');
      return;
    }

    //given must cover the sum
    if(pGiven < payAmount){
      toast.error('A megadott átvett összeg nem fedezi a fizetendő összeget');
      return;
    }

    //all the inputs must match the given
    if(currTotal + round < pGiven){
      toast.error('A fizetési összegek nem egyeznek meg az átvett összeggel');
      return;
    }

    if(pCash === 0 && currTotal < pGiven){
      toast.error('Nem lehet kerekítést alakalmazni, ha nincs forint megadva');
      return;
    }

    let table = '0';
    if (Global.SALEMODE === '2') {
      table = props.table;
    }
    props.ws.send('CLOSEORDER|' + Global.USER + '|' + pBeauty + '|' + pCash + '|' +
                                  pCredit + '|' + pCoupon + '|' + pTransfer + '|' + 
                                  pEuro + '|' + pOther + '|' + pRoomCr + '|' + props.order + '|' + 
                                  round + '|' + pChange + '|' + malfunction + '|' + tip + '|' + table);
  }

  const cancelHandler = () => {
    if (Global.SALEMODE === '1') {
      props.setPaymentActive(false);
      props.setDisableClick(false)
      props.ws.send('CANCELGROSSAMOUNT|' + Global.USER + '|' + props.order);
    }
    else if (Global.SALEMODE === '2') {
      props.ws.send('CANCELTABLEORDER|' + Global.USER + '|' + props.order + '|' + props.table);
    }
  }

  const getVATs = (orderVATs) => {
    let values = [];
    for(let count in orderVATs) {
      let j = values.findIndex(e => e.vatInt === parseInt(orderVATs[count].afakulcs));

      if (j >= 0) {
        values[j].sum += orderVATs[count].bruttoar;
      }
      else {
        let value = {sum: orderVATs[count].bruttoar, vat: orderVATs[count].afakulcs, vatInt: parseInt(orderVATs[count].afakulcs)};
        values.push(value);
      }
    }
    values = values.sort((a, b) => a.vatInt - b.vatInt);
    return(
      <>
        {values.map((afa, index) => (
          <p key={index} style={{textAlign: 'center', margin: 0, color: '#546E7A'}}>{afa.vat} összeg: {afa.sum} Ft</p>
        ))}
      </>
    )
  }

  const getTableVATs = (array) => {
    let values = [];
    for(let count in array) {
      let j = values.findIndex(e => e.vatInt === parseInt(array[count].desc));
      if (j >= 0) {
        values[j].sum += array[count].gross * array[count].amount;
      }
      else {
        let value = {sum: array[count].gross * array[count].amount, vat: array[count].desc, vatInt: parseInt(array[count].desc)};
        values.push(value);
      }
    }
    values = values.sort((a, b) => a.vatInt - b.vatInt);
    return(
      <>
        {values.map((afa, index) => (
          <p key={index} style={{textAlign: 'center', margin: 0, color: '#546E7A'}}>{afa.vat} összeg: {afa.sum} Ft</p>
        ))}
      </>
    )
  }

  const onNumpad = (val) => {
    console.log(setterOfFocusedInput)
    if(val === 'DEL')
      setterOfFocusedInput(v => v.toString().substring(0, v.toString().length - 1));
    else
      setterOfFocusedInput(v => {
        if(v === '0' || v === '' || v === 0)
          return val;
        else
          return v.toString() + val;
      });
  };

  return(
    <Box style={styles.payScreenBox}>
      <Box style={styles.openingDiv}>
        <IconButton onClick={onOpenNumpadButton}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box style={{
          ...styles.numpadBox,
          width: isNumpadOpen ? 200 : 0
        }}>
          <Box style={styles.numpadGrid}>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('1')}>1</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('2')}>2</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('3')}>3</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('4')}>4</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('5')}>5</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('6')}>6</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('7')}>7</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('8')}>8</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('9')}>9</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('.')}>.</Button>
            </Box>
            <Box>
              <Button style={styles.numpadButton} onClick={() => onNumpad('0')}>0</Button>
            </Box>
            <Box>
              <Button 
                style={{
                  ...styles.numpadButton,
                  fontSize: 23,
                }}
                onClick={() => onNumpad('DEL')}
              >
                DEL
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container style={styles.payScreen}>
        <Grid item lg={12} style={{overflowY:'auto', padding:'8px 25px'}}>
          <Typography style={{fontSize:32,fontWeight:700, textAlign: 'center'}}>Fizetés</Typography>
          <div style={{textAlign:'center', marginBottom: 16}}>
            <ButtonGroup style={{boxShadow:'none'}} variant="contained">
              <Button style={{backgroundColor:'#284aff',color:'white',boxShadow:'#284aff 0px 0px 10px',borderRadius:8,marginRight:16}} onClick={(e) => payHandler()}>Fizetés</Button>
              <Button style={{backgroundColor:'white',color:'#284aff',border:'#284aff 1px solid',borderRadius:8}} onClick={(e) => cancelHandler()}>Mégse</Button>
            </ButtonGroup>
          </div>
          <Grid item syle={styles.payScreen.inside}>
            {getVATs(props.orderVATs)}
            {getTableVATs(props.tableVATs)}
            <Typography style={{textAlign: 'center', margin: 0, marginRight: 2, color: '#455A64', fontWeight: 800}}>Összes fizetendő: {payAmount.toLocaleString('hu-HU')}&nbsp;Ft</Typography>
            <Typography style={{textAlign: 'center', margin: 0, marginRight: 2, color: 'rgb(84, 110, 122)', fontSize: 12}}>Ebből szervizdíj: {serviceSum.toLocaleString('hu-HU')}&nbsp;Ft</Typography>
            
            <Typography style={{marginTop:16}}>Bankkártya</Typography>
            <TextField
              style={{backgroundColor:'rgb(245,247,250)'}}
              hiddenLabel
              size="small"
              fullWidth={true}
              value={credit}
              onFocus={() => { creditHandler(); setSetterOfFocusedInput(() => setCredit);}}
              onChange={(e) => { setCredit(e.target.value); }}
            />

            <Typography style={{marginTop:16}}>Készpénz</Typography>
            <TextField
              style={{backgroundColor:'rgb(245,247,250)', width:'calc(100% - 40px)'}}
              hiddenLabel
              size="small"
              fullWidth={true}
              value={cash}
              onFocus={() => setSetterOfFocusedInput(() => setCash)}
              onChange={(e) => setCash(e.target.value)}
              onBlur={(e) => {setCash(Math.round(e.target.value / 5) * 5)}}
            />
            <IconButton aria-label="delete" onClick={onCashBtn}>
              <AddCircleIcon />
            </IconButton>


            <Typography style={{marginTop:16}}>Átvett összeg</Typography>
            <TextField
              style={{backgroundColor:'rgb(245,247,250)', width:'calc(100% - 40px)'}}
              hiddenLabel
              size="small"
              fullWidth={true}
              value={givenAmount}
              onFocus={() => setSetterOfFocusedInput(() => setGivenAmount)}
              onChange={(e) =>{ setGivenAmount(e.target.value); setTip(parseFloat(e.target.value) - payAmount); }}
            />
            <IconButton aria-label="delete" onClick={onGivenAmountBtn}>
              <AddCircleIcon />
            </IconButton>

            <Typography style={{marginTop:16}}>Borravaló</Typography>
            <TextField
              style={{backgroundColor:'rgb(245,247,250)'}}
              hiddenLabel
              size="small"
              fullWidth={true}
              value={tip}
              disabled
            />

            <Typography style={{marginTop:16}}>Szépkártya</Typography>
            <TextField
              style={{backgroundColor:'rgb(245,247,250)'}}
              hiddenLabel
              size="small"
              fullWidth={true}
              value={beautyCard}
              onFocus={() => setSetterOfFocusedInput(() => setBeautyCard)}
              onKeyDown={(e) => beautyCardHandler(e)}
              onChange={(e) => setBeautyCard(e.target.value)}
            />

            <Accordion style={{marginTop:16}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>További fizetési módok</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{marginTop:16}}>Átutalás</Typography>
                <TextField
                  style={{backgroundColor:'rgb(245,247,250)'}}
                  hiddenLabel
                  size="small"
                  fullWidth={true}
                  value={transfer}
                  onFocus={() => setSetterOfFocusedInput(() => setTransfer)}
                  onChange={(e) => setTransfer(e.target.value)}
                />
                <Typography style={{marginTop:16}}>Utalvány</Typography>
                <TextField
                  style={{backgroundColor:'rgb(245,247,250)'}}
                  hiddenLabel
                  size="small"
                  fullWidth={true}
                  value={coupon}
                  onFocus={() => setSetterOfFocusedInput(() => setCoupon)}
                  onChange={(e) => setCoupon(e.target.value)}
                  onKeyDown={(e) => couponHandler(e)}
                />
                <Typography style={{marginTop:16}}>Euró</Typography>
                <Grid container spacing={4}>
                  <Grid item xs={4} md={7}>
                    <TextField
                      style={{backgroundColor:'rgb(245,247,250)'}}
                      hiddenLabel
                      size="small"
                      fullWidth={true}
                      value={euro}
                      onFocus={() => setSetterOfFocusedInput(() => setEuro)}
                      onChange={(e) => setEuro(e.target.value)}                        
                    />
                  </Grid>
                  <Grid item xs={8} md={5} alignSelf={'center'} >
                    <Typography>{euro} * {props.exchange} = {euro * props.exchange} Ft</Typography>
                  </Grid>
                </Grid>
                <Typography style={{marginTop:16}}>Szoba hitel</Typography>
                <TextField
                  style={{backgroundColor:'rgb(245,247,250)'}}
                  hiddenLabel
                  size="small"
                  fullWidth={true}
                  value={roomCredit}
                  onFocus={() => setSetterOfFocusedInput(() => setRoomCredit)}
                  onChange={(e) => setRoomCredit(e.target.value)}
                />
                <Typography style={{marginTop:16}}>Egyéb</Typography>
                <TextField
                  style={{backgroundColor:'rgb(245,247,250)'}}
                  hiddenLabel
                  size="small"
                  fullWidth={true}
                  value={other}
                  onFocus={() => setSetterOfFocusedInput(() => setOther)}
                  onChange={(e) => setOther(e.target.value)}
                />
              </AccordionDetails>
            </Accordion>
            <Malfunctions malfunctions={props.malfunctions} setMalfunction={setMalfunction}/>
            <Typography style={{marginTop:8, marginBottom: 8, fontSize: 12}}>Visszajáró: {change.toLocaleString('hu-HU')}&nbsp;Ft</Typography>
          </Grid>
          <div style={{textAlign:'right'}}>
            <ButtonGroup style={{boxShadow:'none'}} variant="contained">
              <Button style={{backgroundColor:'#284aff',color:'white',boxShadow:'#284aff 0px 0px 10px',borderRadius:8,marginRight:16}} onClick={(e) => payHandler()}>Fizetés</Button>
              <Button style={{backgroundColor:'white',color:'#284aff',border:'#284aff 1px solid',borderRadius:8}} onClick={(e) => cancelHandler()}>Mégse</Button>
            </ButtonGroup>
          </div>
          <Box style={styles.barcodeBox}>
            {Global.TERMINAL === '1' && props.orderVATs.map((vat, i) => {
              return <Box key={i} style={{marginTop: '2cm'}}>
                <Barcode 
                  barcode={{
                    value: vat.serial,
                    options: {
                      background: '#ffffff',
                    },
                  }} 
                />
              </Box>
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const styles = {
  payScreen: {
    flexDirection: 'column',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  payScreenBox:{
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    zIndex: '1',
    position: 'fixed',
    backgroundColor: 'white',
    left : '0',
    right : '0',
    margin: '50px auto',
    maxHeight: 'calc(100% - 150px)',
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: '5px',
    width: 'min-content',
    scrollbarWidth: 'thin',
  },
  openingDiv:{
    display: 'flex',
  },
  numpadBox:{
    transition: 'width 1500ms',
    overflow: 'hidden',
  },
  numpadButton:{
    minWidth: 64, 
    height: 64,
    fontSize: 27,
    border: '1px solid black',
  },
  numpadGrid:{
    display: 'grid',
    alignContent: 'center',
    height: '100%',
    gridTemplateColumns: 'repeat(3, 64px)',
    gap: 3,
  },
  barcodeBox:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
}

export default CashierPay;

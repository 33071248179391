import { useState, useImperativeHandle, forwardRef } from "react";
import { Button, Input, Grid, TextField, Typography, Tooltip, Fab, Checkbox as Check, IconButton, Box } from "@mui/material";
import { Save } from "@mui/icons-material";
import profPic from '../images/blankProf.png';
import SimpleSelect from "./SimpleSelect";
import * as Global from "../Global";
import { toast } from 'react-toastify';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import { useRef } from "react";

const style = {
  main:{
    maxWidth: '1200px',
    minWidth:'fit-content',
    width: '100%',
    margin: 'auto',
    padding: '10px',
    textAlign: "center",
    overflowX:'auto'
  },
  title:{
    textAlign: "center",
    margin: "20px 0"
  },
  saveSign: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
  },
  pic: {
    maxHeight: 225,
    maxWidth: 225
  },
  fileUpload: {
    display: 'none'
  },
  row:{
    width:'100%',
    maxWidth:1000,
    paddingLeft:0,
  },
  inputDiv:{
    paddingLeft:0,
    display:'flex',
    width:'60%',
    justifyContent:'left',
    flex:'1 1 auto',
    minWidth:250
  },
  inputLabelDiv:{
    paddingLeft:0,
    display:'flex',
    width:'calc(40% - 20px)',
    marginRight:20,
    minWidth:200,
    justifyContent:'right',
    flex:'1 1 auto',
  },
  inputLabel:{
    display:'flex',
    alignItems:'center',
  },
  inputHelper:{
    color:'gray',
    fontSize:10,
    paddingTop:4,
    paddingLeft:8,
  },
  input:{
    width:'100%',
    maxWidth:225,
    minWidth:200,
  },
};

const ProductEditor = forwardRef((props, ref) => {
  const [constCategories, setConstCategories] = useState(props.categories ?? []);
  const [constGroups,]                        = useState(props.groups ?? []);
  const [constVatRates,]                      = useState(props.vatrates ?? []);
  const [constUnits,]                         = useState(props.units ?? []);
  const [editableID,]                         = useState(props.product.id ?? 0);
  const [formerSerNum,]                       = useState(props.product.serNum ?? '');
  const [serNum, setSerNum]                   = useState(props.product.serNum ?? '');
  const [genRecommend,]                       = useState(props.genRecommend ?? 1001);
  const [desc, setDesc]                       = useState(props.product.desc ?? '');
  const [group, setGroup]                     = useState(props.product.group ?? '');
  const [category, setCategory]               = useState(props.product.category ?? '');
  const [vatRate, setVatRate]                 = useState(props.product.vatRate ?? '');
  const [unit, setUnit]                       = useState(props.product.unit ?? '');
  const [unit2, setUnit2]                     = useState(props.product.unit2 ?? '');
  const [amount2, setAmount2]                 = useState(props.product.amount2 ?? '1000');
  const [vtsz, setVtsz]                       = useState(props.product.vtsz ?? '');
  const [listed, setListed]                   = useState(props.product.listed ?? true);
  const [gross, setGross]                     = useState(props.product.gross ?? '');
  const [isMade, setIsMade]                   = useState(props.product.keszitenikell ?? false);
  const [pic, setPic]                         = useState(props.pictureLink ? 'https://ntakapi.hardbit.hu/' + props.pictureLink+'?'+Math.random() : '');
  const [multiplier, setMultiplier]           = useState(vatRate ? (constVatRates.find(vat => {if(vat.id === vatRate) return vat; else return null})).multiplier : 0);
  const [net, setNet]                         = useState(((gross/(100+multiplier))*100).toFixed(2));
  const [vatValue, setVatValue]               = useState((gross-net).toFixed(2));
  const ws = props.ws;  

  const CategorySelect = useRef();

  useImperativeHandle(ref, () => {
    return {
      getcategories: getcategories,
    }
  });

  const getcategories = (json) => {
    setConstCategories(json.categories);
    setCategory('');
    CategorySelect.current.setDatas(json.categories);
    CategorySelect.current.setSelected('');
  }

  const saveProduct = (e) => {
    let message;
    let intReg = new RegExp(/^\d+$/);
    let doubleReg = new RegExp(/^-?(0|[1-9]\d?).?\d*$/);
    if(!intReg.test(gross)){ toast.error("A bruttó nem szám"); return; }
    if(!doubleReg.test(amount2)){ toast.error("A másodlagos mennyiség nem szám (tört) szám"); return; }
    if(unit2 === "1" && !intReg.test(amount2)){ toast.error("A másodlagos mennyiség csak egész lehet, ha a másodlagos mennyiségi egység db"); return; }
    if(!group){ toast.error("Nincs megadva csoport"); return; }
    if(!vatRate){ toast.error("Nincs megadva ÁFA kulcs"); return; }
    if(!unit){ toast.error("Nincs megadva mennyiségi egység"); return; }
    if(!serNum){ toast.error("Nincs megadva vonalkód"); return; }
    if(!desc){ toast.error("Nincs megadva megnevezés"); return; }
    if(!gross){ toast.error("Nincs bruttó megadva"); return; }
    if(!category){ toast.error("Nincs kategória megadva"); return; }
    if(!unit2){ toast.error("Nincs másodlagos mennyiségi egység megadva"); return; }
    if(!amount2){ toast.error("Nincs másodlagos mennyiség megadva"); return; }
    if(amount2 === '0' || parseInt(amount2) === 0){ toast.error("A másodlagos mennyiésg nem lehet 0"); return; }
    if(group === '0' && listed){ toast.error("Nem lehet csoport nélkül listázott terméket létrehozni"); return; }

    if(formerSerNum){      
      message = "UPDATEPRODUCT|"+Global.USER+"|"+editableID+"|"+serNum+"|"+desc+"|"+group+"|"+category+"|";
      message    += vatRate +"|"+unit+"|"+vtsz+"|"+listed+"|" + isMade + "|" ;
      message    += gross+"|"+unit2+"|"+amount2;
    }
    else{
      message = "ADDPRODUCT|"+Global.USER+"|"+serNum+"|"+desc+"|"+group+"|"+category+"|";
      message    += vatRate +"|"+unit+"|"+vtsz+"|"+listed+"|" + isMade + "|";
      message    += gross+"|"+unit2+"|"+amount2;
    }

    props.ws.send(message);
    setTimeout(() => {
      sendProductPic();
    }, 200)
  }

  const sendProductPic = async (tries = 0) => {
    console.log(pic);
    if(pic.includes("ntakapi.hardbit.hu")){return;}
    if(!pic || pic === profPic){return;}
    try {      
      const {data: response} = await axios('https://ntakapi.hardbit.hu/setProductImage', {
        method: 'POST',
        headers: {
          "Cache-Control":"no-store",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("jwtToken")
        },
        data: {
          barcode: serNum,
          image: pic.split(',')[1]  //az első részét leszedjük, mert az nem kell a backnek
        }
      })
      if (response.status === 200) {
        toast.success('Sikeres képrögzítés')
      }
    }
    catch (e) {
      toast.error('A kép rögzítése nem sikerült');
      if(e.response?.status === 404 && tries < 3) {
        setTimeout(() => {
          sendProductPic(tries++);
        }, 2000);
      }
    }
  }

  const CategoryHandler = (e) => {
    setCategory(e.target.value);
  }

  const GroupHandler = (e) => {
    setGroup(e.target.value);
    ws.send("GETCATEGORIES|"+Global.USER+"|"+e.target.value);
  }

  const VatHandler = (e) => { 
    setVatRate(e.target.value);
    setMultiplier((constVatRates.find(vat => {if(vat.id === e.target.value) return vat; else return null})).multiplier);
    setNet(((gross/(100+(constVatRates.find(vat => {if(vat.id === e.target.value) return vat; else return null})).multiplier))*100).toFixed(2));
    setVatValue(gross-((gross/(100+(constVatRates.find(vat => {if(vat.id === e.target.value) return vat; else return null})).multiplier))*100).toFixed(2));
  }

  const UnitHandler = (e) => {
    setUnit(e.target.value);
  }

  const Unit2Handler = (e) => {
    setUnit2(e.target.value);
  }

  const amount2Handler = e => {
    setAmount2(e.target.value.replace(',', '.'));
  };

  const setPicture = (e) => {
    if(e){
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = function () {
        let base64 = btoa(reader.result);
        setPic("data:image/png;base64," + base64);
        //ws.send(base64)
      };
      reader.readAsBinaryString(file); 
    }
    else
      setPic(profPic);   
  } 

  const onGenerator = () => {
    //add if empty
    if(serNum === ''){
      setSerNum(genRecommend);
    }
    //inc if number
    else if(parseInt(serNum).toString() === serNum.toString()){
      setSerNum((_serNum) => (parseInt(_serNum) + 1).toString());
    }
    //error otherwise
    else{
      toast.error('Nem lehet generálni, nem szám van megadva cikkszámnak');
    }
  }

  const removePicture = async (tries = 1) => {
    try {      
      const {data: response} = await axios('https://ntakapi.hardbit.hu/setProductImage', {
        method: 'POST',
        headers: {
          "Cache-Control":"no-store",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("jwtToken")
        },
        data: {
          barcode: serNum,
          image: 'removepicture'
        }
      })
      if (response.status === 200) {
        setPic('');
        toast.success('Sikeres kép törlés');
      }
    }
    catch (e) {
      console.log(e);
      toast.error('A kép törlése nem sikerült');
      if(e.response?.status === 404 && tries < 3) {
        setTimeout(() => {
          removePicture(tries++);
        }, 2000);
      }
    }
  }


  return(
    <div style={{maxWidth:600, margin:'auto'}}>
      <Grid style={style.main} container spacing={2}>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Cikkszám</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) =>{ 
                if(e.target.value.length <= 20) setSerNum(e.target.value)
                else toast.error("A megnevezés nem lehet hosszabb, mint 20 karakter");
              }} value={serNum}/>
              <Button 
                variant="contained" 
                style={{height: 30, alignSelf: 'center', marginLeft: 12}}
                onClick={onGenerator}
              >
                Generálás
              </Button>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Megnevezés</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) => {
                if(e.target.value.length <= 80) setDesc(e.target.value)
                else toast.error("A megnevezés nem lehet hosszabb, mint 80 karakter");
              }} value={desc}/>
            </Grid>
          </Grid>          
        </Grid>                
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Főcsoport</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
                <SimpleSelect
                  style={style.input}
                  selected={group}
                  datas={constGroups}
                  onChange={e => GroupHandler(e)}
                  goal={"csoportok"}
                />               
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Kategóriák</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
                <SimpleSelect
                  ref={CategorySelect}
                  style={style.input}                  
                  selected={category} 
                  datas={constCategories}
                  onChange={(e) => CategoryHandler(e)}
                  goal={"kategóriák"}
                />
            </Grid>
          </Grid> 
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>ÁFA Kulcs</Typography>
            </Grid>
            <Grid item style={style.inputDiv} direction="column">            
              <SimpleSelect
                style={style.input}
                selected={vatRate}
                datas={constVatRates}
                onChange={e => VatHandler(e)}
                goal={"áfa kulcsok"}
              />      
              <Typography align="left" style={style.inputHelper}>
                *A cikk elviteles áfakulcsát kell megadni.
              </Typography>           
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Mennyiségi egység</Typography>
            </Grid>
            <Grid item style={style.inputDiv} direction="row">            
              <SimpleSelect
                style={style.input}
                selected={unit}
                datas={constUnits}
                onChange={e => UnitHandler(e)}
                goal={"mennyiségi egységek"}
              /> 
              <Tooltip 
                title={
                  <Typography>
                    A termék értékesítése során használt mennyiségi egység. Ez a mennyiségi egység jelenik 
                    meg a nyugtán is. Pl. 0,2 literes Cola a termékem - mennyiségi egysége: db, másodlagos 
                    mennyiségi egysége: L, másodlagos mennyisége: 200
                  </Typography>
                }
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel} align="right">Másodlagos mennyiségi egység</Typography>
            </Grid>
            <Grid item style={style.inputDiv} direction="column">         
              <Box display={'flex'}>
                <SimpleSelect
                  style={style.input}
                  selected={unit2}
                  datas={constUnits}
                  onChange={e => Unit2Handler(e)}
                  goal={"Másodlagos mennyiségi egységek"}
                /> 
                <Tooltip 
                  title={
                    <Typography>
                      Termék kiszerelése. Pl. 0,2 literes Cola a termékem - mennyiségi egysége: db, másodlagos 
                      mennyiségi egysége: L, másodlagos mennyisége: 200
                    </Typography>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>  
              </Box>   
              <Typography align="left" style={style.inputHelper}>
                *Ebben a mennyiségi egységben történik az értékesítés.
              </Typography>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Másodlagos mennyiség</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} 
                onChange={(e) => {amount2Handler(e);}} value={amount2}
              />
              <Tooltip 
                title={
                  <Typography>
                    Kiszerelés mennyiségi egysége. Pl. 0,2 literes Cola a termékem - mennyiségi 
                    egysége: db, másodlagos mennyiségi egysége: L, másodlagos mennyisége: 200
                  </Typography>
                }
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>  
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>VTSZ</Typography>
            </Grid>
            <Grid item style={style.inputDiv} direction="column">            
              <TextField
                style={style.input} onChange={(e) => {
                  if(e.target.value.length <= 10) setVtsz(e.target.value);
                  else toast.error("A megnevezés nem lehet hosszabb, mint 10 karakter");
                }} 
                value={vtsz}
              />
              <Typography align="left" style={style.inputHelper}>
                Nem kötelező mező
              </Typography>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Listázott</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>        
              <Check onChange={(e) => {setListed(e.target.checked)}} checked={listed}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Helyben készített termék</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>        
              <Check onChange={(e) => {setIsMade(e.target.checked)}} checked={isMade}/>
              <Tooltip 
                title={
                  <Typography>
                    Azoknál a cikkeknél, amelyeknél ki van pipálva, azoknál váltjuk az áfát helyben fogyasztásnál 5%-ra.
                  </Typography>
                }
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Nettó ár</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} 
                InputProps={{readOnly:true}} 
                value={net}
                disabled  
              />
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Áfa</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} 
                InputProps={{readOnly:true}} 
                value={parseFloat(vatValue).toFixed(2)}
                disabled
              />
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Bruttó ár</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) => {
                setGross(e.target.value);
                setNet(((e.target.value/(100+multiplier))*100).toFixed(2));
                setVatValue((e.target.value/(100+multiplier)*multiplier).toFixed(2));
              }} 
              value={gross}/>
            </Grid>
          </Grid>          
        </Grid>        
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Kép feltöltés</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>
              <Grid container spacing={1} direction="column" style={{justifyContent:'center',flexWrap:'nowrap',marginLeft:0}}>
                <Grid item style={{paddingLeft:0, display:'flex',justifyContent:'left'}}>
                  <label htmlFor="contained-button-file">
                    <Input style={style.fileUpload} accept="image/.png, .jpg" onChange={(e) => setPicture(e)} id="contained-button-file" type="file" />
                    <Button size="large" variant="contained" component="span">
                      Feltöltés
                    </Button>
                  </label> 
                </Grid>
                <Grid item style={{paddingLeft:0, display:'flex',justifyContent:'left'}}>
                  <Button onClick={() => removePicture()} size="large" variant="contained" component="span">
                    Törlés
                  </Button>
                </Grid>  
              </Grid>              
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Kép</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>       
              <div style={{width:225,height:225,display:'grid','alignContent':'center',justifyContent:'center'}}>
                <img style={style.pic} alt={"A termék képe"}  src={pic !== '' ? pic : profPic} />
              </div>     
            </Grid>
          </Grid>          
        </Grid>
      </Grid>
      <Tooltip title="Új tétel hozzáadása">
        <Fab size="medium" color="primary" onClick={saveProduct} style={style.saveSign} aria-label="Új tétel hozzáadása">
          <Save/>
        </Fab>
      </Tooltip>
    </div>
  )
})

export default ProductEditor;
import { React, useState }  from "react";
import SimpleSelect         from "./SimpleSelect";
import * as Global          from '../Global';
import EditIcon             from '@mui/icons-material/Edit';
import VATEditor            from "./VATEditor";

const VATTypesRow = (props) => {
  const [ntakVATs,] = useState(props.ntakVATs);
  const [vatType,] =  useState(props.vatType);

  //update the otherid, when theres an interaction, with the simple select
  const ntakVATUpdate = (value) => {
    props.ws.send('UPDATENTAKVATID|' + Global.USER + '|' + vatType.id + '|' + value.target.value);
  } 

  //editbutton event
  const edit = () => {
    props.setWindowText('ÁFA kulcs szerkesztése');
    props.setWindow(<VATEditor vatType={vatType} ws={props.ws} ntakVATs={ntakVATs}/>)
  }

  return(
    <li>
      <ul style={styles.ul}>
        <li style={{...styles.li,width:'50%',minWidth:250}}>{vatType.name}</li>
        <li style={{...styles.li,width:'30%',minWidth:250}}>
          <SimpleSelect
            selected={vatType.ntakVATID}
            datas={ntakVATs}
            onChange={e => {ntakVATUpdate(e)}}
            goal={"NTAK ÁFA-k"}
          />
        </li>
        <li style={{...styles.li,width:'20%',minWidth:100,justifyContent:'center'}}>
          <div style={styles.editDiv} onClick={edit}>
            <EditIcon style={{color:'#7186fa'}}/>
          </div>
        </li>
      </ul>
    </li>
  )    
}

const styles = {
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

export default VATTypesRow;
import { React, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CategoryListItem from "./CategoryListItem";
import * as Global from '../Global';

const styles = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  buttons: {
    marginBottom: "8px"
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
  noContent:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    listStyleType:'none',
    alignItems:'center',
    textAlign:'center',
    minWidth:600,
    boxShadow:'0px 0px 10px #d5d5d5',
  },
}

const CategoryList = (props) => {
  const [categories, setCategories]  = useState(props.categories);
  const [groupID,]                   = useState(props.group);

  if(categories !== props.categories){
    setCategories(props.categories);
  }

  return(
    <div style={styles.main}>
      <div style={styles.tableDiv}>
        <ul style={styles.mainUL}>
          <li style={{minWidth:600}}>
            <ul style={{listStyleType:'none', paddingLeft:16, margin:'0px 6px', display:'flex', minWidth:600}}>
              <li style={{...styles.li,width:'50%',minWidth:180, justifyContent:'left'}}>Megnevezés</li>
              <li style={{...styles.li,width:'20%',minWidth:180, justifyContent:'left'}}>NTAK kategória</li>
              <li style={{...styles.li,width:'10%',minWidth:40, justifyContent:'center'}}>Státusz</li>
              <li style={{...styles.li,width:'10%',minWidth:40, justifyContent:'center'}}>
                <div style={styles.plusSign} onClick={() => {props.ws.send("GETGROUPS|"+Global.USER)}}>
                  <ArrowBackIcon sx={{color: 'white'}} />
                </div>
              </li>
              <li style={{...styles.li,width:'10%',minWidth:40, justifyContent:'center'}}>
                <div style={styles.plusSign} onClick={()=>props.ws.send('ADDCATEGORY|'+Global.USER+"|"+groupID)}>
                  <AddIcon sx={{color: 'white'}} />
                </div>
              </li>
            </ul>
          </li>
          {
            categories.length > 0 ?
              categories.map((category, i) => {
                return(
                  <CategoryListItem setModal={props.setModal} groupID={groupID} ws={props.ws} key={i} category={category} ntakCategories={props.ntakCategories}/>
                )
              })
              :
              <li style={styles.noContent}>
                Nincsenek kategóriái a csoportnak
              </li>
          }
        </ul>       
      </div>
    </div>
  );
}

export default CategoryList;
import * as React from "react";
import { useState, forwardRef } from "react";
import { Grid, TextField, Typography, Tooltip, Fab } from "@mui/material";
import { Save } from "@mui/icons-material";
import { IMaskInput } from 'react-imask';
import { toast } from "react-toastify";
import * as Global from '../Global';

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00000000-0-00"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const style = {
  main:{
    maxWidth: '1200px',
    width: 'fit-content',
    margin: 'auto',
    padding: '10px',
    textAlign: "center",
  },
  title:{
    textAlign: "center",
    margin: "20px 0"
  },
  saveSign: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
  },
  pic: {
    width: '100%',
  },
  fileUpload: {
    display: 'none'
  },  
  row:{
    width:'100%',
    maxWidth:1000,
    paddingLeft:0,
  },
  inputDiv:{
    paddingLeft:0,
    display:'flex',
    width:'60%',
    justifyContent:'left',
    flex:'1 1 auto',
    minWidth:250
  },
  inputLabelDiv:{
    paddingLeft:0,
    display:'flex',
    width:'calc(40% - 20px)',
    marginRight:20,
    minWidth:200,
    justifyContent:'right',
    flex:'1 1 auto',
  },
  inputLabel:{
    display:'flex',
    alignItems:'center',
  },
  input:{
    width:'100%',
    maxWidth:225,
    minWidth:200,
  },
};

const PartnerEditor = (props) => {
  const [id,]                     = useState(props.partner.id ?? 0);
  const [name, setName]                 = useState(props.partner.name ?? '');
  const [address, setAddress]           = useState(props.partner.address ?? { zipCode: '', city: '', street: '', streetType: '', houseNumber: '' });
  const [taxNumber, setTaxNumber]       = useState(props.partner.taxNumber ?? '')
  const [email, setEmail]               = useState(props.partner.email ?? '');
  const [tel, setTel]                   = useState(props.partner.tel ?? '');
  const [contactName, setContactName]   = useState(props.partner.contactName ?? '');
  const [discount, setDiscount]         = useState(props.partner.discount ?? 0);

  const Validate = () => {
    let intReg = new RegExp(/^\d+$/);
    let doubleReg = new RegExp(/^(-?)(0|([1-9][0-9]*))(\.[0-9]+)?$/);
    if(name.length === 0){
      toast.error("Nem elég hosszú a név");
      return;
    }
    if(address.zipCode.length !== 4 || !intReg.test(address.zipCode)){
      toast.error("Nem jó az irányítószám");
      return;
    }
    if(taxNumber.length !== 13 && taxNumber.length !== 0){
      toast.error("Nem elég hosszú az adószám");
      return;
    }
    if(discount < 0 || discount > 100 || !doubleReg.test(discount)){
      toast.error("Nem jó a kedvezmény");
      return;
    }    
    if(taxNumber.length === 0){
      if(!id){
        props.ws.send('ADDPARTNER|'+Global.USER+'|'+name+'|'+address.zipCode+'|'+address.city+
        '|'+address.street+'|'+address.streetType+'|'+address.houseNumber+'|'+taxNumber+'|'+
        email+'|'+tel+'|'+contactName+'|'+discount);
      }
      else{
        props.ws.send('UPDATEPARTNER|'+Global.USER+'|'+id+'|'+name+'|'+address.zipCode+'|'+address.city+
        '|'+address.street+'|'+address.streetType+'|'+address.houseNumber+'|'+taxNumber+'|'+
        email+'|'+tel+'|'+contactName+'|'+discount);
      }
    }
    else{
      let pattern = /^(\d{7})(\d)-([1-5])-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
      let matches = taxNumber.match(pattern);
      if (matches) {
        // Szorzók az adószám törzsszám része (első 8 jegy) ellenőrző számjegyének
        // meghatározásához (a 8. jegy az első hét jegy alapján). Az adott szorzóval, a
        // törzsszám megfelelő számjegyét (elölről indulva) megszorozzuk, majd ezen
        // szorzatok összegét képezzük.
        let mul = [9, 7, 3, 1, 9, 7, 3];
        // Az adószám törzsszám részének első 7 jegye.
        let base = matches[1].split("");
        // Ellenőrző számjegy (az adószám 8. jegye).
        //let check = parseInt(matches[2]);
        // Ellenőrző összeg meghatározása.
        let sum = 0;
        for (let i = 0; i < 7; i++) { sum += parseInt(base[i]) * mul[i]; }
        // Az ellenőrző összeg utolsó jegyének meghatározása (= 10-zel való osztás maradéka).
        let last = sum % 10;
        // Ha a kiszámított ellenőrző számjegy nem nulla, akkor 10-ből kivonjuk.
        if (last > 0) { last = 10 - last; }
        // A kiszámított és kapott ellenőrző számjegyek egyezősége alapján
        // lesz helyes vagy helytelen az adószám.
        console.log("sum: "+sum);
        console.log("last: "+last);
        console.log(taxNumber[7]);
        if(parseInt(taxNumber[7]) === last || taxNumber.length === 0){
          if(!id){
            props.ws.send('ADDPARTNER|'+Global.USER+'|'+name+'|'+address.zipCode+'|'+address.city+
            '|'+address.street+'|'+address.streetType+'|'+address.houseNumber+'|'+taxNumber+'|'+
            email+'|'+tel+'|'+contactName+'|'+discount);
          }
          else{
            props.ws.send('UPDATEPARTNER|'+Global.USER+'|'+id+'|'+name+'|'+address.zipCode+'|'+address.city+
            '|'+address.street+'|'+address.streetType+'|'+address.houseNumber+'|'+taxNumber+'|'+
            email+'|'+tel+'|'+contactName+'|'+discount);
          }
        }
        else{
          toast.error("Az adószám nem létezik");
        }
      } else {
        toast.error("Adó fotmátum nem jó");
      }
    }
  }

  return(
    <div style={{maxWidth:600, margin:'auto'}}>
      <Grid style={style.main} container spacing={2}>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Neve</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) => {
                  if(e.target.value.length <= 150) setName(e.target.value);
                }} value={name}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Irányítószám</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) => setAddress({...address, zipCode:e.target.value})} value={address.zipCode}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Település</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) => {
                if(e.target.value.length <= 60)setAddress({...address, city:e.target.value});
              }} value={address.city}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Utca név</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
            <TextField
                style={style.input} onChange={(e) => {
                if(e.target.value.length <= 60)setAddress({...address, street:e.target.value});
              }} value={address.street}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Utca jellege</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) => {if(e.target.value.length <= 50)setAddress({...address, streetType:e.target.value})}} value={address.streetType}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Ház szám</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} onChange={(e) => {if(e.target.value.length <= 25)setAddress({...address, houseNumber:e.target.value})}} value={address.houseNumber}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Adószám</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>         
              <TextField
                style={style.input}
                helperText="Magánszemély esetén hagyja üresen"
                variant="outlined"
                value={taxNumber}
                onChange={(e) => setTaxNumber(e.target.value)}
                name="textmask"
                id="formatted-text-mask-input"
                InputProps={{
                  inputComponent: TextMaskCustom
                }}
              />
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>E-mail cím</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input}  onChange={(e) => {if(e.target.value.length <= 200)setEmail(e.target.value)}} value={email}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Telefonszám</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input}  onChange={(e) => {if(e.target.value.length <= 25)setTel(e.target.value)}} value={tel}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Kapcsolattartó neve</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input}  onChange={(e) => {if(e.target.value.length <= 150)setContactName(e.target.value)}} value={contactName}/>
            </Grid>
          </Grid>          
        </Grid>
        <Grid item xs={12} style={style.row}>
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={style.inputLabelDiv}>
              <Typography style={style.inputLabel}>Kedvezmény mértéke (%)</Typography>
            </Grid>
            <Grid item style={style.inputDiv}>            
              <TextField
                style={style.input} type="number" onFocus={e => e.currentTarget.select()} onChange={(e) => {setDiscount(e.target.value)}} value={discount}/>
            </Grid>
          </Grid>          
        </Grid>
      </Grid>
      <Tooltip title="Új partner hozzáadása">
        <Fab size="medium" color="primary" onClick={() => Validate()} style={style.saveSign} aria-label="Új partner hozzáadása">
          <Save/>
        </Fab>
      </Tooltip>
    </div>
  );
}

export default PartnerEditor;
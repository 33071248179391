import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Accordion, IconButton } from '@mui/material';
import { Typography } from '@mui/material';
import { AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ReactApexChart from 'react-apexcharts';

const DailyStats = forwardRef((props,ref) => {
  const [stats, setStats] = useState(props.stats);  
  const [date, setDate] = useState(new Date());

  const dailySum =        useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.sum),        0), [stats]);
  const dailyCard =       useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.card),       0), [stats]);
  const dailyBeautyCard = useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.beautyCard), 0), [stats]);
  const dailyCash =       useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.cash),       0), [stats]);
  const dailyCashEUR =    useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.cashEUR),    0), [stats]);
  const dailyCredit =     useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.credit),     0), [stats]);
  const dailyOther =      useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.other),      0), [stats]);
  const dailyTransfer =   useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.transfer),   0), [stats]);
  const dailyVoucher =    useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.voucher),    0), [stats]);
  const dailyTip =        useMemo(() => !stats.length ? 0 : stats.reduce((acc, cur) => acc + parseInt(cur.tip),        0), [stats]);
  const apexSeries = useMemo(() => [dailyBeautyCard, dailyCard, dailyCash, dailyCashEUR, dailyCredit, dailyOther, dailyTransfer, dailyVoucher].map(v => Math.abs(v)), [
    dailyBeautyCard, dailyCard, dailyCash, dailyCashEUR, dailyCredit, dailyOther, dailyTransfer, dailyVoucher
  ]);
  
  useImperativeHandle(ref, () => {
    return {
      updateData:updateData
    }
  })

  const updateData = useCallback((data, date) => {
    setStats(data);
    setDate(new Date(date));
  }, []);

  return(
    <Accordion>
      <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
        <Typography>
          Részletes napi {props.isStorno && 'sztornó'} forgalom
          {stats.length <= 0 && window.innerWidth >= 1000 && <b style={{right:50,position:"absolute"}}>Nem volt ma még forgalom</b>}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={styles.dailyTraffic.datePicker}>
          <p style={{display:'flex',alignItems:'center',margin:0}}>
            Kiválasztott dátum: {date.toLocaleDateString('hu-HU')}
            <IconButton 
              color="primary"
              onClick={() => {props.setDateModalProps({open:true,function:'GETTRAFFICBYDAY',dateSetter:setDate})}}>
              <CalendarTodayIcon/>
            </IconButton>
          </p>
        </div>
        <div style={styles.dailyTraffic.main}>
          <div style={styles.dailyTraffic.tableDiv}>
            <ul style={{...styles.dailyTraffic.mainUL}}>
              <li style={{...styles.dailyTraffic.outerLI,width:150}}>
                <ul style={styles.dailyTraffic.innerUL}>
                  <li style={{textAlign:'right',width:150}}><b>Forgalom</b></li>
                  <li style={{textAlign:'right',width:150,marginBottom:15}}><b>Borravaló</b></li>          
                  <li style={{textAlign:'right',width:150}}>Szépkártya</li>              
                  <li style={{textAlign:'right',width:150}}>Bankkártya</li>              
                  <li style={{textAlign:'right',width:150}}>Készpénz</li>
                  <li style={{textAlign:'right',width:150}}>Készpénz (EUR)</li>          
                </ul>
              </li>
              <li style={{...styles.dailyTraffic.outerLI,width:200}}>
                <ul style={{width:150, paddingLeft:0}}>
                  {
                    stats.length > 0 &&
                      <>
                        <li style={{...styles.dailyTraffic.innerLI}}><b>{dailySum.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</b></li>
                        <li style={{...styles.dailyTraffic.innerLI,marginBottom:15}}><b>{dailyTip.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</b></li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyBeautyCard.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyCard.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyCash.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyCashEUR.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                        <li style={styles.dailyTraffic.innerLI}></li>
                      </>
                  }
                </ul>
              </li>          
            </ul>
            <ul style={styles.dailyTraffic.mainUL}>
              <li style={{...styles.dailyTraffic.outerLI,width:150}}>
                <ul style={styles.dailyTraffic.innerUL}>
                  <li style={{textAlign:'right',width:150}}><b>Nyugták száma</b></li>  
                  <li style={{textAlign:'right',width:150,marginBottom:15}}><b>&nbsp;</b></li>  
                  <li style={{textAlign:'right',width:150}}>Hitel</li>
                  <li style={{textAlign:'right',width:150}}>Egyéb</li>
                  <li style={{textAlign:'right',width:150}}>Átutalás</li>
                  <li style={{textAlign:'right',width:150}}>Utalvány</li>              
                </ul>
              </li>
              <li style={{...styles.dailyTraffic.outerLI,width:200}}>
                <ul style={{width:150, paddingLeft:0}}>
                  {
                    stats.length > 0 ?
                      <>
                        <li style={{...styles.dailyTraffic.innerLI}}><b>{stats.length}&nbsp;db</b></li>
                        <li style={{...styles.dailyTraffic.innerLI,marginBottom:15}}><b>&nbsp;</b></li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyCredit.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyOther.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyTransfer.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                        <li style={styles.dailyTraffic.innerLI}>{dailyVoucher.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
                      </>
                      : <></>                  
                  }
                </ul>
              </li>          
            </ul>
          </div>
        </div>
        <div style={{width: '100%', display:'flex',justifyContent:'center'}}>  
          <div style={{width:'80%'}}>
            <ReactApexChart
              options={apexOptions}
              series={apexSeries}
              type="pie"
              height={400}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
});

const styles = {
  dailyTraffic:{
    main: {
      margin: '15px',
      width: 'calc(100% - 30px)',
      border: '1px solid #8992a5',
      backgroundColor:'rgb(245,247,250)'
    },
    tableDiv: {
      clear: 'both',
      maxWidth: '800px',
      width: '100%',
      margin: 'auto',
    },
    table: {
      maxWidth: '800px',
      width: '100%',
      margin: 'auto',
    },
    title: {
      textAlign: 'center'
    },
    plusSign: {
      borderRadius:8,
      alignItems:'center',
      display:'flex',
      cursor:'pointer',
      backgroundColor:'#284aff',
      boxShadow:'0px 0px 10px rgb(40, 74, 255)',
      width:40,
      height:40,
      justifyContent:'center',
      marginTop:10,
      marginLeft:10
    },
    mainUL:{
      display:'inline-block',
      padding:0,
      alignItems:'center',
      maxWidth:400,
      listStyle:'none',
      marginBottom:16
    },
    outerLI:{
      display:'inline-flex',

    },
    innerUL:{
      listStyle:'none',
    },
    innerLI:{
      listStyle:'none',
      textAlign:'right'
    },
    datePicker:{
      marginLeft:15,
      marginRight:15,
      width:'calc(100% - 30px)'
    }
  }
};

const apexOptions = { labels: [ "Szépkártya", "Bankártya", "Készpénz", "Euró", "Hitel", "Egyéb", "Átutalás", "Utalvány"] };

export default DailyStats;
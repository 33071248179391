import React, { useState } from 'react';
import { Typography, TextField, Grid, FormControl, FormHelperText} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

import { passwordValidate, passwordError } from '../core';
import * as Global from '../Global';
import AddSite from './AddSite';

const styles = {
  main: {
    alignItems: 'center',
    minHeight: 'calc(100vh - 30px)',
    padding: '15px'
  },
  paper: {
    padding: 15,
    width: '100%',
    maxWidth: 500,
    textAlign: 'center'
  },
  logo: {
    maxWidth: '300px',
    width: '100%'
  },
  headerText: {
    marginBottom: 15
  },
  inputDiv: {
    marginBottom: 15,
  },
  loginButton: {
    marginBottom: 15
  },
  title: {
    textAlign: 'center'
  },
  selectGrid: {
    minWidth: 200,
    marginBottom: 15,
    fullWidth: true,
  },
  checkbox: {
    padding: 0,
  }
}

const MyProfile = (props) => {
  const [fullName, setFullName]           = useState(props.data.nev);
  const [currentPass, setCurrentPass]     = useState('');
  const [password, setPassword]           = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passError, setPassError]         = useState(false);
  const [passHelper, setPassHelper]       = useState('');
  const [againError, setAgainError]       = useState(false);
  const [againHelper, setAgainHelper]     = useState('');
  const [email, setEmail]                 = useState(props.data.email);
  const [phone, setPhone]                 = useState(props.data.telefon);
  //const [twoFa, setTwoFa]                 = useState('none');
  const [loading, setLoading]             = useState(false);

  // useEffect(() => {
  //   if(props.data.hitelesites === '0') setTwoFa('none');
  //   if(props.data.hitelesites === '1') setTwoFa('phone');
  //   if(props.data.hitelesites === '2') setTwoFa('email');
  // }, [props])

  const updateEmploye = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    //if(fullName === '')                            toast.error('A teljes név megadása kötelező', {autoClose: 5000});
    if(password !== '' && currentPass === '') toast.error('A jelszó megváltoztatásához szükséges a jelenlegi jelszó', {autoClose: 5000});
    //else if(!passwordValidate(password))           toast.error('A jelszó nem felel meg a követelményeknek', {autoClose: 5000});
    //else if(password !== passwordAgain)            toast.error('A jelszavak nem egyeznek meg', {autoClose: 5000});
    /*else if(twoFa !== 'none'){
      if(twoFa === 'phone' && phone === ''){
        toast.error('A telefonszám megadása szükséges', {autoClose: 5000});
      }
      else if(twoFa === 'email' && email === ''){
        toast.error('Az email megadása szükséges', {autoClose: 5000});
      }
    } */
    else{
      // ws.send('UPDATEEMPLOYEE|' + Global.USER + '|' + fullName + '|' + currentPass + '|' + password + '|' + email + '|' + phone + '|' + twoFa)
      props.ws.send('UPDATEEMPLOYEE|' + Global.USER + '|' + fullName + '|' + currentPass + '|' + password + '|' + email + '|' + phone + '|none')
    }

  }

  const passwordValidateHandler = (e) => {
    if(passwordValidate(e.target.value)){
      setPassError(false);
      setPassHelper('');
    }
    else {
      setPassError(true);
      setPassHelper(passwordError(e.target.value));
    }
    if(e.target.value === ''){
      setPassError(false);
      setPassHelper('');
    }
  }

  const passwordMatch = () => {
    if(passwordAgain !== password ) {
      setAgainError(true);
      setAgainHelper('A két jelszó nem egyezik meg!');
    }
    else {
      setAgainError(false);
      setAgainHelper('');
    }
  }

  return(
    <Grid container direction={'column'} style={ styles.main }>
      <Typography variant='h4' style={ styles.title }>Beállítások</Typography>
      <Grid md={2} style={ styles.inputDiv }>
        <Typography>Teljes név</Typography>
        <TextField
          value={fullName}
          onChange={e => setFullName(e.target.value)}
          autoComplete="off"
          variant="outlined"
          placeholder='Példa Pali'
          hiddenLabel
          size="small"
        />
      </Grid>
      {/* <Grid md={12} sm={12} sx={12} style={ styles.selectGrid }>
        <Typography>2 faktoros hitelesítés</Typography>
        <Select
          id="simple-select"
          value={twoFa}
          displayEmpty
          fullWidth
          onChange={(e) => setTwoFa(e.target.value)}
          >
          <MenuItem value={'none'}>Nincs</MenuItem>
          <MenuItem value={'phone'}>Telefon</MenuItem>
          <MenuItem value={'email'}>Email</MenuItem>
        </Select>
      </Grid> */}
      <Grid md={2} style={ styles.inputDiv }>
        <Typography>Telefonszám</Typography>
        <TextField
          value={phone}
          onChange={e => setPhone(e.target.value)}
          autoComplete="off"
          variant="outlined"
          placeholder='06 12 3456789'
          hiddenLabel
          size="small"
        />
      </Grid>
      <Grid md={2} style={ styles.inputDiv }>
        <Typography>Email</Typography>    
        <TextField
          value={email}
          onChange={e => setEmail(e.target.value)}
          autoComplete="off"
          variant="outlined"
          placeholder='pelda@email.com'
          hiddenLabel
          size="small"
        />
      </Grid>
      <Grid md={2} style={ styles.inputDiv }>
        <Typography>Régi jelszó</Typography>
        <TextField
          value={currentPass}
          onChange={e => setCurrentPass(e.target.value)}
          autoComplete="off"
          variant="outlined"
          placeholder='******'
          hiddenLabel
          size="small"
          type={'password'}
        />
        
      </Grid>
      <Grid md={2} style={ styles.inputDiv }>
        <Typography>Jelszó</Typography>        
        <FormControl variant="standard">
          <TextField
            value={password}
            onChange={(e) => {setPassword(e.target.value); passwordValidateHandler(e)}}
            autoComplete="off"
            variant="outlined"
            placeholder='******'
            hiddenLabel
            size="small"
            type={'password'}
            error={passError}
            helperText={passHelper}
          />
          <FormHelperText id="component-helper-text" style={{color:'black'}}>
            Legalább 8 karakter hosszú<br/>
            Tartalmaz kis- és nagybetűt, és számot is
          </FormHelperText>
        </FormControl> 
      </Grid>
      <Grid md={2} style={ styles.inputDiv }>
        <Typography>Jelszó mégegyszer</Typography>
        <TextField
          value={passwordAgain}
          onChange={e => setPasswordAgain(e.target.value)}
          autoComplete="off"
          onBlur={e => passwordMatch()}
          variant="outlined"
          placeholder='******'
          hiddenLabel
          size="small"
          type={'password'}
          error={againError}
          helperText={againHelper}
        />
      </Grid>

      <LoadingButton
        onClick={ () => updateEmploye() }
        loading={ loading }
        variant="contained"
        style={ styles.loginButton }
        disabled={ loading }
      >
        Módosítás
      </LoadingButton>

      <LoadingButton
        onClick={ () => {
          props.setWindowText("Új telephely hozzáadása");
          props.setWindow(<AddSite ws={props.ws}/>)
        }}
        loading={ loading }
        variant="contained"
        style={ styles.loginButton }
        disabled={ loading }
      >
        +&nbsp;Telepely
      </LoadingButton>
    </Grid>
  )
}

export default MyProfile;
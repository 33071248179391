import React, { useState} from 'react';
import { Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const styles = {
  tableList: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: '1',
    position: 'fixed',
    backgroundColor: '#F5F5F5',
    left : '0',
    right : '0',
    margin: '5% auto',
    width: '400px',
    padding: '25px',
    border: '1px solid rgba(0, 0, 0, 0.21)',
    borderRadius: '5px',
    maxHeight: '70%',
    overflowY: 'auto',
    maxWidth: '75%',
  },
  button: {
    backgroundColor: '#FFFF',
    borderRadius: '8px',
    color: '#000',
    width: '100%',
    margin: '4px',
    border: 'solid #284aff 2px',
  },
}

const TableList = (props) => {
  const [tables] = useState(props.tables);

  const clickHandler = (table) => {
    props.setChosenTable(table.id);
    props.setTableName(table.name);
    props.setTableList(false);
    props.setDisableClick(false);
    props.ws.send('GETITEMSONTABLE|' + props.user + '|' + table.id);
  }

  return(
    <div style={styles.tableList}>
      { tables.length > 0 &&
        tables.map((table) => {
          if (table.status === 1) {
            return(
              <div key={table.id} >
                <Button style={{...styles.button, backgroundColor: (table.items === "true" ? '#284aff' : '#fff'), color: (table.items === "true" ? '#fff' : '#000')}} variant="contained" onClick={(e) => {clickHandler(table);}}>{table.name}</Button>
              </div>
            )
          }
          else {
            return null;
          }
        })
      }
      { tables.length === 0 &&
        <div>
          <IconButton sx={{position:"absolute",top:"8px",right:"8px"}} onClick={() => {props.setTableList(false);props.setDisableClick(false);}}>
              <Close/>
          </IconButton>
          <p>
            Nincsenek engedélyezve asztalok            
          </p>
          
        </div>
      }
    </div>
  )
}
export default TableList;
import React, { useState, useEffect, useRef} from 'react';
import { styled } from '@mui/material';
import { Typography, TextField, Link, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import logo from '../images/logo_white.png';
import logo2 from '../images/light_frezer.svg';

import * as Global from '../Global';
import { IP } from '../ip';

const TEST = IP === "192.168.253.20";

const SITE_KEY = IP === "192.168.253.3" ? "6Lcpgk8eAAAAAAKlTb0zQUK54crUYsT8WJb5PZEj" : "6Lf0I-8lAAAAADIx3yydxqPzTZb-RqkzKbuBuqpl"; 

console.log(SITE_KEY);

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root':{
    '& fieldset': {
      borderColor:'#23b9db'
    },
    '&::placeholder':{
      color:'#000000'
    },
    '&:hover fieldset': {
      borderColor: '#23b9db',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#23b9db',
    },
    '& input':{
      color: 'white'
    }
  }
})

const styles = {
  main: {
    display:'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 30px)',
    padding: '15px',
    backgroundColor:'#0e1f42',
    textAlign: 'center',
    color: '#23b9db'
  },
  gridContainer: {
    width: '100%',
    maxWidth: 200,
  },
  gridItem:{
    textAlign:'center'
  },
  titleText:{
    fontWeight:'bold',
    borderRight:'1px solid #23b9db',
    paddingTop:0
  },
  logo: {
    maxWidth: '300px',
    width: '50%'
  },
  headerText: {
    marginBottom: 15
  },
  inputDiv: {
    marginBottom: 15
  },
  loginButton: {
    marginBottom: 15,
    backgroundColor:'#284aff',
  },
  registrationLink: {
    width: '100%'
  }
}

const Login = (props) => {
  const [companyCode, setCompanyCode] = useState('');
  const [username, setUsername]       = useState('');
  const [password, setPassword]       = useState('');
  const [loading, setLoading]         = useState(false);
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    //if(window.location.hostname !== "localhost"){
      IP === "192.168.253.3"
        ? loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`)
        : loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/enterprise.js?render=6Lf0I-8lAAAAADIx3yydxqPzTZb-RqkzKbuBuqpl`);
    //}
  }, []);

  const loginButtonClick = () => {
    setLoading(true);
    if (IP === "192.168.253.3") {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
          Global.USER = username.trim();
          props.ws.send('LOGIN|'+companyCode+'|'+username.trim()+'|'+password.trim()+'|'+token+'|'+TEST);
        })
      });
    }
    else{
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(SITE_KEY, { action: 'submit' }).then(token => {
          Global.USER = username.trim();
          props.ws.send('LOGIN|'+companyCode+'|'+username.trim()+'|'+password.trim()+'|'+token+'|'+TEST);
        })
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  const handleKeyPressCompanyCode = (event) => {
    if(event.key === 'Enter'){
      inputRef.current.focus();
    }
  }

  const handleKeyPressUsername = (event) => {
    if(event.key === 'Enter'){
      inputRef2.current.focus();
    }
  }

  const handleKeyPressPassword = (event) => {
    if(event.key === 'Enter'){
      loginButtonClick();
    }
  }

  return(
    <div style={ styles.main }>
      <Grid container style={styles.gridContainer}>
        <Grid item xs={12} style={{marginBottom:24}}>
        <img src={logo2} alt="Frézer" style={{width:'80%',margin:'auto',marginTop:16}}/>
        </Grid>
        <Grid item container style={{...styles.gridItem,marginBottom:50}} spacing={2}>
          <Grid item xs={12} style={{display:'block',paddingTop:0}}>
            <img src={logo} alt="Hardbit logo" style={ styles.logo }/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={ styles.inputDiv }>
            <Typography>Céges azonosító</Typography>
            <StyledInput
              value={companyCode}
              onChange={e => setCompanyCode(e.target.value)}
              onKeyDown={e => handleKeyPressCompanyCode(e)}
              autoFocus
              variant="outlined"
              hiddenLabel
              size="small"
              // placeholder="123456"
              style={styles.input}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={ styles.inputDiv }>
            <Typography>Felhasználónév</Typography>
            <StyledInput
              value={username}
              onChange={e => setUsername(e.target.value)}
              onKeyDown={e => handleKeyPressUsername(e)}
              inputRef={inputRef}
              variant="outlined"
              hiddenLabel
              size="small"
              // placeholder="Példa Péter"
              style={styles.input}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={ styles.inputDiv }>
            <Typography>Jelszó</Typography>
            <StyledInput
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => handleKeyPressPassword(e)}
              inputRef={inputRef2}
              variant="outlined"
              hiddenLabel
              size="small"
              type={'password'}
              placeholder="********"
              style={styles.input}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
        <LoadingButton
            onClick={ () => loginButtonClick() }
            loading={ loading }
            variant="contained"
            style={ styles.loginButton }
            disabled={ loading }
          >
            Bejelentkezés
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
        <Link
            component="button"
            variant="body1"
            underline="none"
            onClick={() => {
              props.loginToRegistration();
            }}
            style={ styles.registrationLink }
          >
            Még nincs céges fiókja? Regisztráljon most!
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login;
import React, { useState } from 'react';
import { Typography, TextField, Button, ButtonGroup } from '@mui/material';
import { toast } from 'react-toastify';
import * as Global from '../../Global';

const styles = {
  payScreen: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: '1',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    //margin: '5% auto',
    width: 'fit-content',
    padding: '25px',
    border: '2px solid rgba(0,0,0,0.3)',
    borderRadius: '5px',
    minHeight: '450px',
    minWidth:'400px',
    overflowY: 'auto',
      head: {

      },
      inside: {

      },

  },

}

const PayStornoModal = (props) => {
  const [payAmount, ] = useState(props.gross);
  const [change, setChange] = useState(0);
  const [coupon, setCoupon] = useState();
  const [cash, setCash] = useState();
  const [credit, setCredit] = useState(0);
  const [tip, setTip] = useState(0);

  const calcAmount = (calcCoupon, calcCash, calcCredit) => {
    calcCoupon = parseInt(calcCoupon);
    calcCash = parseInt(calcCash);
    calcCredit = parseInt(calcCredit);
    if(isNaN(calcCoupon)) calcCoupon = 0;
    if(isNaN(calcCash)) calcCash = 0;
    if(isNaN(calcCredit)) calcCredit = 0;
    console.log(calcCoupon, calcCash, calcCredit);
    if((calcCoupon + calcCash + calcCredit) > payAmount ){
      setChange((payAmount - (calcCoupon + calcCash + calcCredit)) * -1);
    }
    else {
      setChange(0);
    }
  }

  const couponHandler = (e) => {
    if(parseInt(coupon)+e.key > Math.round(payAmount*5)/5){
      e.preventDefault();
    } 
    else {
      //setCoupon(e.target.value);
    }
  }

  const cashHandler = (e) => {
    //setCash(e.target.value);
    //console.log('itt target value', e.target.value);
    if((coupon + e.target.value + credit) < payAmount){
      setChange(0);
    }
    else {
      calcAmount(coupon, e.target.value, credit);
    }
  }

  const creditHandler = (e) => {
    let cCash = parseInt(cash);
    let cCoupon = parseInt(coupon);
    if(isNaN(cCash)) cCash = 0;
    if(isNaN(cCoupon)) cCoupon = 0;

    if((cCash + cCoupon)  < payAmount) {
      setCredit(payAmount - (cCash + cCoupon));
      setChange(0);
    }
  }

  const payHandler = () => {
    let pCoupon = parseInt(coupon);
    let pCash = parseInt(cash);
    let pCredit = parseInt(credit);
    let pChange = parseInt(change);
    let round = payAmount -  Math.round(payAmount*5)/5;

    if(isNaN(pCoupon)) pCoupon = 0;
    if(isNaN(pCash))   pCash = 0;
    if(isNaN(pCredit)) pCredit = 0;
    if(isNaN(pChange)) pChange = 0;
    if(isNaN(round))   round = 0;
    console.log(pCoupon + pCash + pCredit);
    let currTotal = Math.round( (pCoupon + pCash + pCredit) / 5 ) * 5;
    if((currTotal) < Math.round(payAmount / 5 ) * 5){
      toast.error('A megadott összeg nem éri el a fizetendő összeget');
    }
    else {
      props.ws.send('CLOSESTORNO|' + Global.USER + '|' + pCoupon + '|' + pCash + '|' + pCredit + '|' + props.order + '|' + round + '|' + pChange + '|' + tip);
    }
  }

  const cancelHandler = () => {
    props.setModal(false);
  }

  const creditKeyHandler = (e) => {
    if (e.which === 8 ) {
      setCredit(0);
    }
  }

  const tipHandler = (e) => {
    if(e.target.value <= props.tip) { setTip(e.target.value) };
  }

  const getVATs = (orderVATs) => {
    let values = [];
    for(let count in orderVATs) {
      let j = values.findIndex(e => e.vatInt === parseInt(orderVATs[count].afakulcs));

      if (j >= 0) {
        values[j].sum += orderVATs[count].bruttoar;
      }
      else {
        let value = {sum: orderVATs[count].bruttoar, vat: orderVATs[count].afakulcs, vatInt: parseInt(orderVATs[count].afakulcs)};
        values.push(value);
      }
    }
    values = values.sort((a, b) => a.vatInt - b.vatInt);
    return(
      <>
        {values.map((afa, index) => (
          <p style={{textAlign: 'end', margin: 2, color: '#546E7A'}}>{afa.vat} összeg: {afa.sum} Ft</p>
        ))}
      </>
    )
  }

  return(
      <div style={styles.payScreen}>
        <div style={styles.payScreen.head}>
          <Typography style={{fontSize:32,fontWeight:700}}>Fizetés</Typography>
        </div>
        <div syle={styles.payScreen.inside}>
          {getVATs(props.orderVATs)}
          <Typography style={{textAlign: 'end', margin: 8, marginRight: 2, color: '#455A64', fontWeight: 800}}>Összes fizetendő: {payAmount.toLocaleString('hu-HU')}&nbsp;Ft</Typography>
          <Typography style={{marginTop:16}}>Utalvány</Typography>
          <TextField
            style={{backgroundColor:'rgb(245,247,250)'}}
            hiddenLabel
            size="small"
            fullWidth={true}
            value={coupon}
            onKeyDown={(e) => couponHandler(e)}
            onChange={(e) =>setCoupon(e.target.value)}
          />
          <Typography style={{marginTop:16}}>Készpénz</Typography>
          <TextField
            style={{backgroundColor:'rgb(245,247,250)'}}
            hiddenLabel
            size="small"
            fullWidth={true}
            value={cash}
            //onKeyDown={(e) => }
            onChange={(e) => {setCash(e.target.value); cashHandler(e)}}
            onBlur={(e) => {setCash(Math.round(e.target.value / 5) * 5)}}
          />
          <Typography style={{marginTop:16}}>Bankkártya</Typography>
          <TextField
            style={{backgroundColor:'rgb(245,247,250)'}}
            hiddenLabel
            size="small"
            fullWidth={true}
            value={credit}
            onFocus={(e) => creditHandler(e)}
            onKeyDown={(e) =>  creditKeyHandler(e)}
          />
          <Typography style={{marginTop:16}}>Borravaló (max {props.tip} Ft)</Typography>
          <TextField
            style={{backgroundColor:'rgb(245,247,250)'}}
            hiddenLabel
            size="small"
            fullWidth={true}
            value={tip}
            onChange={(e) =>  tipHandler(e)}
          />
          <Typography style={{marginTop:8}}>Visszajáró: {change.toLocaleString('hu-HU')}&nbsp;Ft</Typography>
        </div>
        <div style={{textAlign:'right'}}>
          <ButtonGroup style={{boxShadow:'none'}} variant="contained">
            <Button style={{backgroundColor:'#284aff',color:'white',boxShadow:'#284aff 0px 0px 10px',borderRadius:8,marginRight:16}} onClick={(e) => payHandler()}>Fizetés</Button>
            <Button style={{backgroundColor:'white',color:'#284aff',border:'#284aff 1px solid',borderRadius:8}} onClick={(e) => cancelHandler()}>Mégse</Button>
          </ButtonGroup>
        </div>
      </div>
  )
}
export default PayStornoModal;
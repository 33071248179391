import { useState } from 'react';
import CurrencyRow from './CurrencyRow';

const styles = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  table: {
    maxWidth: '500px',
    width: '100%',
    margin: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
  noContent:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    listStyleType:'none',
    alignItems:'center',
    textAlign:'center',
    minWidth:600,
    boxShadow:'0px 0px 10px #d5d5d5',
  },
}

const CurrencyList = (props) => {
  const [currencies, setCurrencies] = useState(props.currencies);

  if(currencies !== props.currencies){
    setCurrencies(props.currencies);
  }

  return(
    <div style={styles.main}>
      <div style={styles.tableDiv}>
      <ul style={styles.mainUL}>
        <li style={{minWidth:600}}>
          <ul style={{listStyleType:'none', paddingLeft:16, margin:'0px 6px', display:'flex', minWidth:600}}>
            <li style={{...styles.li,width:'15%',minWidth:60, justifyContent:'left'}}>Név</li>
            <li style={{...styles.li,width:'60%',minWidth:180, justifyContent:'left'}}>Megnevezés</li>
            <li style={{...styles.li,width:'15%',minWidth:60, justifyContent:'right'}}>Érték</li>
            <li style={{...styles.li,width:'10%',minWidth:40, justifyContent:'center'}}></li>
          </ul>
        </li>
        {
          currencies.length > 0 ?
            currencies.map((currency, i) => {
              return(
                <CurrencyRow ws={props.ws} setWindow={props.setWindow} key={i} currency={currency}/>
              )
            })
            :
            <li style={styles.noContent}>
              Nincsenek pénznemek
            </li>
        }
      </ul>
    </div>
  </div>
  )
}

export default CurrencyList;
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, IconButton } from '@mui/material';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import * as Global from '../../Global';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { toast } from 'react-toastify';

const styles = {
  tableInvoice: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: '1',
      position: 'fixed',
      backgroundColor: 'white',
      left : '0',
      right : '0',
      margin: '5% auto',
      width: 'fit-content',
      padding: '25px',
      border: '2px solid rgba(0,0,0,0.3)',
      borderRadius: '5px',
      maxHeight: '70%',
      maxWidth:'70%',
      overflowY: 'auto',
      head: {

      },
      inside: {

      },

  },
  signs:{
    backgroundColor: 'rgb(245, 245, 245)',
    borderRadius:8
  },
  icon:{
    boxShadow:'rgb(28, 191, 219) 0px 0px 10px',
    backgroundColor:'rgb(28, 191, 219)',
    width:40,
    height:40,
    borderRadius:8,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    marginRight:16
  }
}

const TableInvoice = (props) => {
  const [products, setProducts] = useState(props.products);
  const [total, setTotal] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);

  useEffect(() => {
    let temp = products;
    for (let count in temp) {
      if(isNaN(temp[count].amount)) {
        temp[count].amount = temp[count].quantity;
      }
    }
    getFinal();
    //TODO
    //eslint-disable-next-line
  },[props] )

  const plusOne = (product, index) => {
    let temp = products;
    if(isNaN(temp[index].amount)){
      temp[index].amount = 1;
    }
    else if (temp[index].amount < temp[index].quantity) temp[index].amount += 1;
    setProducts(temp);
    getFinal();
  }

  const minusOne = (product, index) => {
    let temp = products;
    if(temp[index].amount > 0){
      temp[index].amount -= 1;
    }
    setProducts(temp);
    getFinal();
  }

  const getFinal = () => {
    let temp = 0;
    for(let count in products) {
      if(products[count].amount > 0) {
        temp += products[count].amount * products[count].price;
      }
    }
    if (props.serviceCharge !== 0) {
      setTotal(Math.round(temp * (props.serviceCharge / 100) + temp));
    }
    else setTotal(temp);
    setItemsTotal(temp);
  }

  const payHandler = () => {
    //itt fel kell vinnem a dolgokat hogy megrendelés legyen belőle. Ha a cashier pay-ben arra nyomnak hogy mégsem, akkor törölni kell majd ezt a megrendelést
    let temp = '';
    for(let count in products) {
      if(products[count].amount > 0) {
        temp += products[count].prodid + '|' + products[count].amount + '|' + products[count].id + '|';
      }
    }
    if(temp.length === 0){
      toast.error('Nincsen tétel kiválasztva');
      return;
    }
    props.ws.send('ADDTABLEITEMSTOINVOICE|' + Global.USER + '|' + temp)
  }
//a product amount alapból legyen egyenlő a product.quantity vel
  return(
    <div style={styles.tableInvoice}>
      <p style={{display:'flex',height:'fit-content',alignItems:'center',  paddingBottom:24,borderBottom:'1px solid rgba(0,0,0,0.3)'}}>
        <div style={styles.icon}>
          <ThumbUpAltOutlinedIcon color="black"/>
        </div>
        Fizetéshez hozzáadott
      </p>
      <div style={{overflowX:'auto'}}>
        {products.map((product, index) => (
          <div key={index} style={{display:'flex', marginBottom:16}}>
            <div style={{display:'inline-flex',width:'min-content'}}>
              <IconButton style={styles.signs} onClick={() => minusOne(product, index)}>
                <RemoveIcon />
              </IconButton>
              <div style={{display:'inline-flex',margin:'0 12px',alignSelf:'center',fontWeight:700}}>{product.amount}</div>
              <IconButton style={styles.signs} onClick={() => plusOne(product, index)}>
                <AddIcon/>
              </IconButton>
            </div>
            <div style={{display:'inline-flex',paddingLeft:16,paddingRight:16,width:'100%'}}>
              <div style={{width:'60%',minWidth:100,justifyContent:'left',display:'flex',alignItems:'center',fontWeight:600}}>{product.name} ({product.quantity}&nbsp;db)</div>
              <div style={{width:'20%',minWidth:100,justifyContent:'center',display:'flex',alignItems:'center',fontWeight:600}}>{product.price.toLocaleString('hu')}&nbsp;Ft/db</div>
              <div style={{width:'20%',minWidth:100,justifyContent:'right',display:'flex',alignItems:'center',fontWeight:600,color:'#fbae4b'}}>{(product.amount*product.price).toLocaleString('hu')}&nbsp;Ft</div>
            </div>
          </div>
        ))}
        {props.serviceCharge !== 0 &&
          <div style={{display:'flex', marginBottom:16}}>
            <div style={{display:'inline-flex',paddingLeft:16,paddingRight:16,width:'100%'}}>
              <div style={{width:'60%',minWidth:100,justifyContent:'left',display:'flex',alignItems:'center',fontWeight:600}}>Szervizdíj:</div>
              <div style={{width:'20%',minWidth:100,justifyContent:'center',display:'flex',alignItems:'center',fontWeight:600}}>{Math.round(itemsTotal * (props.serviceCharge / 100))} Ft</div>
            </div>
          </div>
        }
      </div>
      <p style={{marginTop:0,paddingTop:16,borderTop:'1px solid rgba(0,0,0,0.3)',textAlign:'right',fontSize:26,fontWeight:'bold'}}>{total.toLocaleString('hu')} Ft</p>
      <div style={{textAlign:'right'}}>
        <ButtonGroup variant="contained" style={{boxShadow:'none'}}>
          <Button style={{backgroundColor:'#284aff',color:'white',boxShadow:'#284aff 0px 0px 10px',borderRadius:8,marginRight:16}} onClick={(e) => payHandler()}>Fizetés</Button>
          <Button style={{backgroundColor:'white',color:'#284aff',border:'#284aff 1px solid',borderRadius:8}} onClick={(e) => {props.setTableInvoice(false); props.setDisableClick(false)}}>Mégse</Button>
        </ButtonGroup>
      </div>
    </div>
  )
}
export default TableInvoice;

import React from 'react';

import { Divider, Drawer, List, ListItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import CategoryIcon from '@mui/icons-material/Category';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessIcon from '@mui/icons-material/Business';
import TimelineIcon from '@mui/icons-material/Timeline';
import EuroIcon from '@mui/icons-material/Euro';
import BentoIcon from '@mui/icons-material/Bento';
// import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import AssignmentIndSharpIcon from '@mui/icons-material/AssignmentIndSharp';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Logo from '../images/light_frezer.svg';

import * as Rights from '../Rights';
import * as Global from '../Global';
import { Receipt, SettingsSharp } from '@mui/icons-material';
import { useState } from 'react';

const styles = {
  title:{
    color:'#23b9db',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 16,
    fontSize:22,
    marginTop:0,
    marginBottom:0,
    fontWeight:'bold',
  },
  listItemText:{
    color:'inherit',
    padding:5,
    fontWeight:'lighter'
  },
  ListItem:{
    marginLeft:16,
    paddingRight:16,
    paddingTop:1,
    paddingBottom:1,
    width:'auto',
    color:'white'
  },
  icon:{
    color:'white',
    padding:4,
  },
  activeItem:{
    marginLeft:16,
    paddingRight:16,
    paddingTop:1,
    paddingBottom:1,
    width:'auto',  
    borderRadius:'16px 0 0 16px',
    backgroundColor:'#f5f7fa',
    color:'black',       
    "&:hover svg":{
      color:'black !important'
    }  
  },
  activeIcon:{
    color:'black',
    backgroundColor:'#1cbfdb',
    boxShadow:'0px 0px 4px #1cbfdb',
    padding:4,
    borderRadius:8
  },
  hoveredLIstItem:{
    backgroundColor:'red'
  }
  
}

const Sidebar = (props) => {
  const [active, setActive] = useState("sale");

  const menuItemSelect = (e,id) => {
    setActive(id);
    switch (id) {
      case 'employees':
        props.ws.send("LISTEMPLOYEE|"+Global.USER);   
        props.setWindow(false);
        break;

      case 'products':
        props.ws.send("GETPRODUCTS|"+Global.USER);        
        props.setWindow(false);
        break;

      case 'tables':
        props.ws.send("GETTABLES|"+Global.USER);
        props.setWindow(false);
        break;

      case 'sale':
        props.ws.send("SALE|"+Global.USER);
        props.setWindow(false);
        break;
    
      case 'myProfile':
        props.ws.send('GETEMPLOYEEDATA|'+Global.USER);
        props.setWindow(false);
        break;

      case 'payedPeriods':
        props.ws.send("GETPAYEDPERIODS|"+Global.USER);
        props.setWindow(false);
        break;

      case 'groups':
        props.ws.send("GETGROUPS|"+Global.USER);
        props.setWindow(false);
        break;

      case 'receipt':
        props.ws.send('GETRECEIPTS|'+Global.USER);
        props.setWindow(false);
        break;

      case 'partnerList':
        props.ws.send("GETPARTNERS|"+Global.USER);
        props.setWindow(false);
        break;

      case 'currencies':
        props.ws.send("GETCURRENCIES|"+Global.USER);
        props.setWindow(false);
        break;

      case 'units':
        props.ws.send("GETAMOUNTUNITS|"+Global.USER);
        props.setWindow(false);
        break;

      case 'settings':
        props.ws.send('GETSETTINGS|'+Global.USER);
        props.setWindow(false);
        break;

      case 'stats':
        //TODO:deploy another, create the view 
        let date = new Date();
        let dateStr = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
        console.log(dateStr);
        props.ws.send('GETDAILYTRAFFIC|'+Global.USER+'|'+dateStr);
        break;

      case 'storno':
        props.ws.send('STORNOLIST|'+Global.USER);
        props.setWindow(false);
        break;

      case 'vatTypes':
        props.ws.send('AFAKULCSOK|'+Global.USER);
        props.setWindow(false);
        break;

      default:
        props.setWindow(false);
        props.setWindowText('');
        break;
    }
  }

  return (
    <Drawer
      PaperProps={{
        style:{borderRight:'1px solid rgba(255, 255, 255, 0.9)'}
      }}
      sx={{
        width: props.sidebarWidth ?? 240,
        maxWidth: '100vw',        
        flexShrink: 0,        
        '& .MuiDrawer-paper': {
          width: props.sidebarWidth ?? 240,
          maxWidth: '100vw',
          boxSizing: 'border-box',
          backgroundColor:'#0e1f42',
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open ?? false}
    >
      <img src={Logo} alt="Frézer" style={{width:'66%',margin:'auto',marginTop:16}}/>

      <div style={{color:'white', textAlign:'center',paddingLeft:20, paddingRight:20, fontSize:9, paddingTop:5}}>{props.compName}, {props.siteName}</div>

      <div>
        <IconButton onClick={() => props.setSidebar(false)} style={{ marginLeft:16 }}>
          <ChevronLeftIcon style={{color:'white',marginLeft:8}} />
        </IconButton>
      </div>

      <List style={styles.list}>
         <ListItem style={active==="sale"?styles.activeItem:styles.ListItem} button onClick={ (e) => menuItemSelect(e,'sale') }
          sx={active!=="sale"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <ShoppingCartIcon style={active==="sale"?styles.activeIcon:styles.icon}/>
          </ListItemIcon>
          <ListItemText primary={'Eladás'} style={styles.listItemText} />
        </ListItem>

         <ListItem style={active==="products"?styles.activeItem:styles.ListItem} button onClick={ (e) => menuItemSelect(e,'products') }
          sx={active!=="products"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <FastfoodIcon style={active==="products"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Cikktörzs'} style={styles.listItemText} />
        </ListItem>

         <ListItem style={active==="groups"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'groups') }
          sx={active!=="groups"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <CategoryIcon style={active==="groups"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Csoportok'} style={styles.listItemText} />
        </ListItem>

         <ListItem style={active==="tables"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'tables') }
          sx={active!=="tables"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <TableRestaurantIcon style={active==="tables"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Asztalok'} style={styles.listItemText} />
        </ListItem>

         <ListItem style={active==="employees"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'employees') }
          sx={active!=="employees"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <PeopleAltIcon style={active==="employees"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Felhasználók'} style={styles.listItemText} />
        </ListItem>

         <ListItem style={active==="partnerList"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'partnerList') }
          sx={active!=="partnerList"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
          >
          <ListItemIcon>
            <BusinessIcon style={active==="partnerList"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Partnerek'} style={styles.listItemText} />
        </ListItem>
        {Rights.SeeStatistics
          // && false
          && <ListItem style={active===""?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'stats') }
            sx={active !== ""
              ? {
                '&:hover svg, &:hover span' :{
                  color:'#23b9db !important'
                }
              } : {}
            }
          >
            <ListItemIcon>
              <TimelineIcon style={active===""?styles.activeIcon:styles.icon} />
            </ListItemIcon>
            <ListItemText primary={'Statisztikák'} style={styles.listItemText} />
          </ListItem>
        }

         <ListItem style={active==="receipt"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'receipt') }
          sx={active!=="receipt"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <ReceiptLongIcon style={active==="receipt"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Nyugták'} style={styles.listItemText} />
        </ListItem>

        <ListItem style={active==="storno"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'storno') }
          sx={active!=="storno"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <Receipt style={active==="storno"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Stornó bizonylatok'} style={styles.listItemText} />
        </ListItem>

        {/* <ListItem style={active==="payedPeriods"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'payedPeriods') }
          sx={active!=="payedPeriods"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
        >
          <ListItemIcon>
            <CalendarViewWeekIcon style={active==="payedPeriods"?styles.activeIcon:styles.icon}/>
          </ListItemIcon>
          <ListItemText primary={'Fizetett időszakok'} style={styles.listItemText} />
        </ListItem> */}

        {/* {Rights.CurrencyEditor &&
           <ListItem style={active==="currencies"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'currencies') }
           sx={active!=="currencies"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
           >
            <ListItemIcon>
              <EuroIcon style={active==="currencies"?styles.activeIcon:styles.icon}/>
            </ListItemIcon>
            <ListItemText primary={'Deviza'} style={styles.listItemText} />
          </ListItem>
        }   */}
        
        <ListItem 
          style={ active==="vatTypes" ? styles.activeItem: styles.ListItem } button onClick={ (e) => menuItemSelect(e,'vatTypes') }
          sx={ active !== "vatTypes" ? {
            '&:hover svg, &:hover span' : {
              color:'#23b9db !important'
            }
          }:{}}
        >
          <ListItemIcon>
            <EuroIcon style={ active === "vatTypes" ? styles.activeIcon: styles.icon }/>
          </ListItemIcon>
          <ListItemText primary={'ÁFA kulcsok'} style={styles.listItemText} />
        </ListItem>
      
         <ListItem style={active==="myProfile"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'myProfile') }
          sx={active!=="myProfile"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <AssignmentIndSharpIcon style={active==="myProfile"?styles.activeIcon:styles.icon}/>
          </ListItemIcon>
          <ListItemText primary={'Profilom'} style={styles.listItemText} />
        </ListItem>

        <ListItem style={active==="units"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'units') }
          sx={active!=="units"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <BentoIcon style={active==="units"?styles.activeIcon:styles.icon}/>
          </ListItemIcon>
          <ListItemText primary={'Mennyiségi egységek'} style={styles.listItemText} />
        </ListItem>
      </List>

      <Divider variant='middle' style={{borderColor:'rgb(90,90,90)'}}/>

      <List>
        {Rights.SeeSettings &&
           <ListItem style={active==="settings"?styles.activeItem:styles.ListItem}button onClick={ (e) => menuItemSelect(e,'settings') }
           sx={active!=="settings"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
           >
            <ListItemIcon>
              <SettingsSharp style={active==="settings"?styles.activeIcon:styles.icon}/>
            </ListItemIcon>
            <ListItemText primary={'Beállítások'} style={styles.listItemText} />
          </ListItem>
        }

         <ListItem style={active==="exit"?styles.activeItem:styles.ListItem}button onClick={ () => props.ws.send('LOGOUT|' + Global.USER) }
          sx={active!=="exit"?{
            '&:hover svg, &:hover span' :{
              color:'#23b9db !important'
            }
          }:{}}
         >
          <ListItemIcon>
            <LogoutIcon style={active==="exit"?styles.activeIcon:styles.icon} />
          </ListItemIcon>
          <ListItemText primary={'Kijelentkezés'} style={styles.listItemText} />
        </ListItem>
      </List>

      <div style={{flex: 1}}></div>
    </Drawer>
  )
};

export default Sidebar;

const defaultApexOptions = {
  chart: {
    type: 'bar',
    height: 380,
  },
  plotOptions: {
    bar: {
      barHeight: '100%',
      distributed: true,
      horizontal: true,
      dataLabels: { position: 'bottom' },
    }
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: { colors: ['#000'], fontSize: 20 },
    formatter: (val, opt) => opt.w.globals.labels[opt.dataPointIndex] + ":  " + val,
    offsetX: 0,
    dropShadow: { enabled: false }
  },
  stroke: { width: 1, colors: ['#fff'] },
  yaxis: { labels: { show: false } },
};

const createApexBarSeries = (dataArray) => [{ data: dataArray }];
const createApexBarOptions = (dataArray, titleString, yFormatterString = 'Ft') => ({
  ...defaultApexOptions,
  xaxis: { categories: dataArray },
  title: {
    text: titleString,
    align: 'center',
    floating: true
  },
  tooltip: {
    theme: 'light',  
    y: {
      formatter: (value) => `${value} ${yFormatterString}`,
      title: { formatter: () => '' }
    }
  }
})

export {
  createApexBarSeries,
  createApexBarOptions,
  defaultApexOptions
};
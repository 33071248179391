import { React, useState } from "react";
import { TextField, Container } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { red, green } from '@mui/material/colors';
import * as Global from '../Global';
import { toast } from 'react-toastify';
import SimpleSelect from "./SimpleSelect";
import { useRef } from "react";
import { useEffect } from "react";

const styles = {
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

const ItemGroupsRow = (props) => {
  const [group, setGroup] = useState(props.group);
  const [edit, setEdit]   = useState(group.name === "" ? true : false);

  const ntakSelect = useRef();

  useEffect(() => {
    if(group !== props.group && !edit){
      setGroup(props.group);
      ntakSelect.current.setSelected(props.group.ntakid);
    }
  // eslint-disable-next-line
  },[props])

  const Save = () => {
    if(!edit){
      setEdit(true);
      return
    }
    else{
      if(!group.ntakid){
        toast.error("NTAK csoportot meg kell adni");
        return;
      }
      if(!group.name){
        toast.error("Üres névvel nem lehet menteni");
        return;
      }
      props.ws.send('UPDATEGROUP|'+Global.USER+"|"+group.id+"|"+group.name+"|"+group.status)
      setEdit(false);
    }
  }

  const Edit = (e) => {
    if(e.target.value.length <= 20)
      setGroup({...group,name:e.target.value})   
    else
      toast.error("A megnevezés nem lehet hosszabb, mint 20 karakter");      
  }

  const ntakGroupUpdate = (value) => {
    setGroup({...group,ntakid:value});
    props.ws.send('UPDATENTAKGROUP|' + Global.USER + '|' + group.id + '|' + value);
  } 

  return(
    <li>
      <ul style={styles.ul}>
        <li style={{...styles.li,width:'50%',minWidth:180}}>
          {
            edit ? 
            <TextField maxLength={20} size="small" onChange={Edit} value={group.name}/>
            : group.name
          }
        </li>
        <li style={{...styles.li,width:'20%',minWidth:180}}>
          <SimpleSelect
            ref={ntakSelect}
            selected={group.ntakid}
            datas={props.ntakGroups}
            onChange={e => ntakGroupUpdate(e.target.value)}
            goal={"csoportok"}
          />
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          {group.status?
            <CheckCircleIcon             
              onClick={() => {
                if(edit){
                  toast.error("Szerkesztés alatt nem lehet státuszt változtatni");
                  return
                }
                props.ws.send('UPDATEGROUPSTATUS|'+Global.USER+"|"+group.id+"|false");
              }} 
              sx={{
                color:green[500],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
            :
            <CancelIcon 
              onClick={() => {
                if(edit){
                  toast.error("Szerkesztés alatt nem lehet státuszt változtatni");
                  return
                }
                props.ws.send('UPDATEGROUPSTATUS|'+Global.USER+"|"+group.id+"|true");
              }} 
              sx={{
                color:red[400],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
          }
          
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={styles.editDiv} onClick={Save}>
            {edit?
              <SaveIcon style={{color:'#7186fa'}}/>
              :
              <EditIcon  style={{color:'#7186fa'}}/>
            }
          </div>
        </li>
        <li style={{...styles.li,width:'10%',minWidth:120,justifyContent:'center'}}>          
          <Container
            style={styles.editDiv} 
            onClick={() => props.ws.send('GETCATEGORIESEDIT|'+Global.USER+"|"+group.id)}
            sx={{
              '&:hover':{
                backgroundColor:'rgb(247,247,247)'
              }
            }}
          >
            Kategóriák
          </Container>
        </li>
      </ul>
    </li>
  )    
}

export default ItemGroupsRow;
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Accordion, Box, IconButton, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ReactApexChart from 'react-apexcharts';
import { createApexBarOptions, createApexBarSeries } from './DefaultApexOptions';

const TrafficByHour = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [isOpened, setOpened] = useState(false);
  const [date, setDate] = useState(new Date());
  const apexSeries = useMemo(() => createApexBarSeries(data.map(d => d.y)), [data]);
  const apexOptions = useMemo(() => createApexBarOptions(data.map(d => d.label), 'Napi forgalom óránkénti bontásban'), [data]);

  useImperativeHandle(ref, () => {
    return{
      setData: setData  
    }
  });

  const getData = useCallback(() => {
    if(!isOpened){
      props.setDateModalProps({
        open:true,
        function:'GETTRAFFICBYHOUR',
        dateSetter:setDate
      });
    }
    else{
      setData([]);
    }
    setOpened(!isOpened);
  }, [isOpened, props]);

  return (
    <Accordion onChange={getData}>
      <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
        <Typography>Napi forgalom óránkénti bontásban</Typography>
      </AccordionSummary>
      <Box style={styles.datePicker}>
        <Stack style={{display:'flex', flexDirection: 'row', alignItems:'center',margin:0}}>
          Kiválasztott dátum: {date.toLocaleDateString('hu-HU')}
          <IconButton 
            color="primary"
            onClick={() => {props.setDateModalProps({open:true,function:'GETTRAFFICBYHOUR',dateSetter:setDate})}}
          >
            <CalendarTodayIcon/>
          </IconButton>
        </Stack>
      </Box>
      <Box display={data.length ? "block" : "none"}>
        <ReactApexChart options={apexOptions} series={apexSeries} type="bar" height={data.length * 60 + 100} />
      </Box>
    </Accordion>
  );
})

const styles={
  datePicker:{
    marginLeft:15,
    marginRight:15,
    width:'calc(100% - 30px)'
  }
};

export default TrafficByHour;
import AddIcon from '@mui/icons-material/Add';
import ProductListItem from './ProductListItem';
import ProductListFilter from './ProductListFilter';
import { useState } from 'react';
import * as Global from '../Global.jsx';
import * as Rights from '../Rights';
import Button from '@mui/material/Button';

const ProductList = (props) => {  
  const [constProductList, setConstProductList] = useState(props.products ?? []);
  const [productList, setProductList] = useState(props.products ?? []);
  const [categoryList,] = useState(props.categories ?? []);
  const addProduct = () => {
    props.ws.send('PRECREPRODUCT|'+Global.USER);
  }

  if(constProductList !== props.products){
    setConstProductList(props.products);
    setProductList(props.products);
  }

  const onFileChange = async (e) => {
    const file = (e.target).files[0];
    const base64 = await convertBase64(file);
    props.ws.send('IMPORTPRODUCT|'+Global.USER+"|"+base64);
    //console.log(base64)
  }

  const convertBase64 = (file)=> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  return(
    <div style={style.main}> 
      <ProductListFilter groKeys={props.groups} keys={categoryList} setList={setProductList} products={constProductList} />
      <div style={style.tableDiv}>
        <ul style={style.mainUL}>
          <Button style={style.plusSign}
            variant="contained"
            component="label"
          >
          Cikk import
          <input
            type="file"
            onChange={onFileChange}
            accept={".csv"}
            hidden
          />
          </Button>
        </ul>
      </div>
      <div style={style.tableDiv}>
        <ul style={style.mainUL}>
          <li style={{minWidth:600}}>
            <ul style={{listStyleType:'none', paddingLeft:16, marginRight:6, display:'flex'}}>
              <li style={style.li}>Vonalkód</li>
              <li style={style.li}>Megnevezés</li>
              <li style={{...style.li,width:0,minWidth:80, justifyContent:'center'}}>Státusz</li>
              <li style={{...style.li,width:0,minWidth:100,justifyContent:'right'}}>Bruttó érték</li>
              <li style={{...style.li,width:0,minWidth:60,justifyContent:'center'}}>
                {Rights.ProductEditor &&
                  <div style={style.plusSign} onClick={addProduct}>
                    <AddIcon sx={{color: 'white'}} />
                  </div>
                }
              </li>
            </ul>
          </li>
          {productList.map((product, i) => {
            return(
              <ProductListItem ws={props.ws} setWindow={props.setWindow} key={i} product={product}/>
            )
          })}
        </ul>          
      </div>
    </div>
  )
}

const style = {
  main: {
    padding: '15px',
    paddingTop:0,
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
    clear: 'both',
    maxWidth:1200,
    width:'100%',
    margin:'auto'
  },
  table: {
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  }
}

export default ProductList;
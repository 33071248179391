import React, { useState, useReducer, useEffect } from 'react';
import { FormControl, FormHelperText, styled } from '@mui/material';
import { Typography, TextField, Link, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import logo from '../images/logo_white.png';

import { toast } from 'react-toastify';
import { ws } from '../App';
import { passwordValidate, passwordError } from '../core';
import { IP } from '../ip';

const TEST = IP === "192.168.253.20";

const SITE_KEY = IP === "192.168.253.3" ? "6Lcpgk8eAAAAAAKlTb0zQUK54crUYsT8WJb5PZEj" : "6Lf0I-8lAAAAADIx3yydxqPzTZb-RqkzKbuBuqpl"; 

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root':{
    '& fieldset': {
      borderColor:'#23b9db'
    },
    '&::placeholder':{
      color:'#000000'
    },
    '&:hover fieldset': {
      borderColor: '#23b9db',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#23b9db',
    },
    '& input':{
      color: 'white'
    },
  },
  '& .MuiOutlinedInput-root.Mui-error':{
    '& fieldset':{
      borderColor:'yellow'
    },
  },
  '& .MuiFormHelperText-root.Mui-error':{
    color:'yellow'
  }
})

const styles = {
  main: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 30px)',
    padding: '15px',
    backgroundColor:'#0e1f42',
    textAlign: 'center',
    color: '#23b9db'
  },
  gridContainer: {
    width: '100%',
    maxWidth: 200,
  },
  gridItem:{
    textAlign:'center'
  },
  titleText:{
    fontWeight:'bold',
    borderRight:'1px solid #23b9db',
    paddingTop:0
  },
  logo: {
    maxWidth: '300px',
    width: '100%'
  },
  headerText: {
    marginBottom: 15
  },
  inputDiv: {
    marginBottom: 15
  },
  loginButton: {
    marginBottom: 15,
    backgroundColor:'#284aff',
  },
  registrationLink: {
    width: '100%'
  }
}

const Registration = (props) => {
  const [companyName, setCompanyName]         = useState('');
  const [taxNumber, setTaxNumber]             = useState('');
  const [taxNumberError, setTaxNumberError]   = useState(false);
  const [email, setEmail]                     = useState('');
  const [bankNumber, setBankNumber]           = useState('');
  const [address, setAddress]                 = useState({ zipCode: '', city: '', street: '', streetType: '', streetNumber: '' });
  const [isSiteDifferent, setIsSiteDifferent] = useState(false);
  const [siteAddress, setSiteAddress]         = useState({ zipCode: '', city: '', street: '', streetType: '', streetNumber: '' });
  const [contactName, setContactName]         = useState('');
  const [contactPhone, setContactPhone]       = useState('');
  const [username, setUsername]               = useState('');
  const [password, setPassword]               = useState('');
  const [loading, setLoading]                 = useState(false);
  const [passError, setPassError]             = useState(false);
  const [passHelper, setPassHelper]           = useState('');
  const [passwordAgain, setPasswordAgain]     = useState('');

  const [, forceUpdate] = useReducer(x => !x);

  const registrationButtonClick = () => {
    setLoading(true);
    if(companyName === '')         toast.error('A cégnév megadása kötelező', {autoClose: 5000}); 
    else if(  taxNumber === '')    toast.error('Az adószám megadása kötelező', {autoClose: 5000});
    else if(   email === '')       toast.error('Az email cím megadása kötelező', {autoClose: 5000});
    else if(   contactName === '') toast.error('A kapcsolat tartó megadása kötelező', {autoClose: 5000});
    else if(   username === '')    toast.error('A felhasználónév megadása kötelező', {autoClose: 5000});
    else if(   password === '')    toast.error('A jelszó megadása kötelező', {autoClose: 5000});
    else {
        if(isSiteDifferent && (siteAddress.zipCode === '' && 
                               siteAddress.city === '' && 
                               siteAddress.street === '' && 
                               siteAddress.streetType === '' && 
                               siteAddress.streetNumber === '')){
          alert('Szükséges mezők hiányoznak');
          return;
        }
        let differentSite = '';
        if(isSiteDifferent){
          differentSite = siteAddress.zipCode + '|' + siteAddress.city + '|' + siteAddress.street + '|' + siteAddress.streetType + '|' + siteAddress.streetNumber   
        }
        else {
          differentSite = '||||';
        }

        if (IP === "192.168.253.3") {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
            //submitData(token);
            /*console.log('REGISZTRACIO|'+companyName+'|'+
                                        taxNumber+'|'+    //12971169-2-02 hardbit
                                        email+'|'+
                                        bankNumber+'|'+
                                        address.zipCode+'|'+
                                        address.city+'|'+
                                        address.street+'|'+
                                        address.streetType+'|'+
                                        address.streetNumber+'|'+
                                        contactName+'|'+
                                        contactPhone+'|'+
                                        username+'|'+
                                        password+'|'+
                                        token+'|'+
                                        differentSite
                                        );*/
            ws.send('REGISZTRACIO|'+companyName+'|'+
                                    taxNumber+'|'+    //12971169-2-02 hardbit
                                    email+'|'+
                                    bankNumber+'|'+
                                    address.zipCode+'|'+
                                    address.city+'|'+
                                    address.street+'|'+
                                    address.streetType+'|'+
                                    address.streetNumber+'|'+
                                    contactName+'|'+
                                    contactPhone+'|'+
                                    username+'|'+
                                    password+'|'+
                                    token+'|'+
                                    TEST+'|'+
                                    differentSite
                                    );
          })
        });
      }
      else{
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise.execute(SITE_KEY, { action: 'submit' }).then(token => {
            ws.send('REGISZTRACIO|'+companyName+'|'+
                                    taxNumber+'|'+    //12971169-2-02 hardbit
                                    email+'|'+
                                    bankNumber+'|'+
                                    address.zipCode+'|'+
                                    address.city+'|'+
                                    address.street+'|'+
                                    address.streetType+'|'+
                                    address.streetNumber+'|'+
                                    contactName+'|'+
                                    contactPhone+'|'+
                                    username+'|'+
                                    password+'|'+
                                    token+'|'+
                                    TEST+'|'+
                                    differentSite
                                    );
          })
        });
      }
        
    }


    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    //if(window.location.hostname !== "localhost"){
      IP === "192.168.253.3"
        ? loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`)
        : loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/enterprise.js?render=6Lf0I-8lAAAAADIx3yydxqPzTZb-RqkzKbuBuqpl`);
    //}
  }, []);

  const passwordValidateHandler = (e) => {
    if(passwordValidate(e.target.value)){
      setPassError(false);
      setPassHelper('');
    }
    else {
      setPassError(true);
      setPassHelper(passwordError(e.target.value));
    }
    if(e.target.value === ''){
      setPassError(false);
      setPassHelper('');
    }
  }

  const changeAddressValue = (object, index, value) => {
    if (object === 'address') {
      const fullAddress = address;
      fullAddress[index] = value;
      setAddress(fullAddress);
    } else if (object === 'siteAddress') {
      const fullAddress = siteAddress;
      fullAddress[index] = value;
      setSiteAddress(fullAddress);
    }
    forceUpdate();
  }

  const changeTaxNumber = (value) => {
    setTaxNumber(value);

    var pattern = /^(\d{7})(\d)-([1-5])-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
    var matches = value.match(pattern);
    if (matches) {
      // Szorzók az adószám törzsszám része (első 8 jegy) ellenőrző számjegyének
      // meghatározásához (a 8. jegy az első hét jegy alapján). Az adott szorzóval, a
      // törzsszám megfelelő számjegyét (elölről indulva) megszorozzuk, majd ezen
      // szorzatok összegét képezzük.
      var mul = [9, 7, 3, 1, 9, 7, 3];
      // Az adószám törzsszám részének első 7 jegye.
      var base = matches[1].split("");
      // Ellenőrző számjegy (az adószám 8. jegye).
      var check = parseInt(matches[2]);
      // Ellenőrző összeg meghatározása.
      var sum = 0;
      for (var i = 0; i < 7; i++) { sum += parseInt(base[i]) * mul[i]; }
      // Az ellenőrző összeg utolsó jegyének meghatározása (= 10-zel való osztás maradéka).
      var last = sum % 10;
      // Ha a kiszámított ellenőrző számjegy nem nulla, akkor 10-ből kivonjuk.
      if (last > 0) { last = 10 - last; }
      // A kiszámított és kapott ellenőrző számjegyek egyezősége alapján
      // lesz helyes vagy helytelen az adószám.
      setTaxNumberError(last !== check);
    } else {
      setTaxNumberError(true);
    }
  }

  return(
    <div style={ styles.main }>
      <Grid container style={{...styles.gridContainer,marginTop:50,marginBottom:50}} spacing={2}>
        <Grid item xs={6} style={styles.titleText}>
          Frézer
        </Grid>
        <Grid item xs={6} style={{display:'grid',alignContent:'center',paddingTop:0}}>
          <img src={logo} alt="Hardbit logo" style={ styles.logo } />
        </Grid>
      </Grid>

      <Typography variant="h6">Céges alapadatok</Typography>

      <div style={ styles.inputDiv }>
        <Typography>Cégnév</Typography>
        <StyledInput
          value={companyName}
          onChange={e => setCompanyName(e.target.value)}
          autoComplete="organization"
          variant="outlined"
          placeholder='Példa Kft.'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Adószám</Typography>
        <StyledInput
          value={taxNumber}
          onChange={e => changeTaxNumber(e.target.value)}
          autoComplete="off"
          variant="outlined"
          placeholder='xxxxxxxx-y-zz'
          hiddenLabel
          size="small"
          error={taxNumberError}
          helperText={taxNumberError ? "Hibás adószám" : ""}
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Bankszámlaszám</Typography>
        <StyledInput
          value={bankNumber}
          onChange={e => setBankNumber(e.target.value)}
          autoComplete="off"
          variant="outlined"
          placeholder='xxxxxxxx-xxxxxxxx'
          hiddenLabel
          size="small"
        />
      </div>
      
      <Typography variant="h6">Székhely</Typography>

      <div style={ styles.inputDiv }>
        <Typography>Irányítószám</Typography>
        <StyledInput
          value={address.zipCode}
          onChange={e => changeAddressValue('address', 'zipCode', e.target.value)}
          autoComplete="postal-code"
          variant="outlined"
          placeholder='1234'
          hiddenLabel
          size="small"
          error={!address.zipCode.match(/^(\d{4})$/)}
          helperText={!address.zipCode.match(/^(\d{4})$/) && "Hibás irányítószám"}
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Város</Typography>
        <StyledInput
          value={address.city}
          onChange={e => changeAddressValue('address', 'city', e.target.value)}
          autoComplete="address-level2"
          variant="outlined"
          placeholder='Budapest'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Közterület neve</Typography>
        <StyledInput
          value={address.street}
          onChange={e => changeAddressValue('address', 'street', e.target.value)}
          variant="outlined"
          placeholder='Király'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Közterület típusa</Typography>
        <StyledInput
          value={address.streetType}
          onChange={e => changeAddressValue('address', 'streetType', e.target.value)}
          variant="outlined"
          placeholder='utca'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Házszám</Typography>
        <StyledInput
          value={address.streetNumber}
          onChange={e => changeAddressValue('address', 'streetNumber', e.target.value)}
          variant="outlined"
          placeholder='5'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <FormControlLabel control={
          <Checkbox
            style={{color:'#23b9db'}}
            checked={isSiteDifferent}
            onChange={(e) => setIsSiteDifferent(e.target.checked)}
          />
        } label="A telephely eltér a székhelytől"
          style={{marginRight:0}}
        />
      </div>

      {isSiteDifferent &&
        <>
          <Typography variant="h6">Telephely</Typography>

          <div style={ styles.inputDiv }>
            <Typography>Irányítószám</Typography>
            <StyledInput
              value={siteAddress.zipCode}
              onChange={e => changeAddressValue('siteAddress', 'zipCode', e.target.value)}
              autoComplete="postal-code"
              variant="outlined"
              placeholder='1234'
              hiddenLabel
              size="small"
              error={!siteAddress.zipCode.match(/^(\d{4})$/)}
              helperText={!siteAddress.zipCode.match(/^(\d{4})$/) && "Hibás irányítószám"}
            />
          </div>

          <div style={ styles.inputDiv }>
            <Typography>Város</Typography>
            <StyledInput
              value={siteAddress.city}
              onChange={e => changeAddressValue('siteAddress', 'city', e.target.value)}
              autoComplete="address-level2"
              variant="outlined"
              placeholder='Budapest'
              hiddenLabel
              size="small"
            />
          </div>

          <div style={ styles.inputDiv }>
            <Typography>Közterület neve</Typography>
            <StyledInput
              value={siteAddress.street}
              onChange={e => changeAddressValue('siteAddress', 'street', e.target.value)}
              variant="outlined"
              placeholder='Király'
              hiddenLabel
              size="small"
            />
          </div>

          <div style={ styles.inputDiv }>
            <Typography>Közterület típusa</Typography>
            <StyledInput
              value={siteAddress.streetType}
              onChange={e => changeAddressValue('siteAddress', 'streetType', e.target.value)}
              variant="outlined"
              placeholder='utca'
              hiddenLabel
              size="small"
            />
          </div>

          <div style={ styles.inputDiv }>
            <Typography>Házszám</Typography>
            <StyledInput
              value={siteAddress.streetNumber}
              onChange={e => changeAddressValue('siteAddress', 'streetNumber', e.target.value)}
              variant="outlined"
              placeholder='5'
              hiddenLabel
              size="small"
            />
          </div>
        </>
      }

      <Typography variant="h6">Kapcsolattartó</Typography>

      <div style={ styles.inputDiv }>
        <Typography>Kapcsolattartó neve</Typography>
        <StyledInput
          value={contactName}
          onChange={e => setContactName(e.target.value)}
          autoComplete="name"
          variant="outlined"
          placeholder='Példa Péter'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Felhasználónév</Typography>
        <StyledInput
          value={username}
          onChange={e => setUsername(e.target.value)}
          autoComplete="username"
          variant="outlined"
          placeholder='PeldaP'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Telefonszám</Typography>
        <StyledInput
          value={contactPhone}
          onChange={e => setContactPhone(e.target.value)}
          autoComplete="tel"
          variant="outlined"
          placeholder='+36301234567'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>E-mail cím</Typography>
        <StyledInput
          value={email}
          type={'email'}
          onChange={e => setEmail(e.target.value)}
          autoComplete="email"
          variant="outlined"
          placeholder='info@cegem.hu'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Jelszó</Typography>
        <FormControl variant="standard">
          <StyledInput
            value={password}
            onChange={(e) => {setPassword(e.target.value); passwordValidateHandler(e)}}
            autoComplete="new-password"
            variant="outlined"
            placeholder='******'
            hiddenLabel
            size="small"
            type={'password'}
            error={passError}
            helperText={passHelper}
          />
          <FormHelperText id="component-helper-text" style={{color:'#23b9db'}}>
            Legalább 8 karakter hosszú<br/>
            Tartalmaz kis- és nagybetűt, és számot is
          </FormHelperText>
        </FormControl> 
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Jelszó mégegyszer</Typography>
        <StyledInput
          value={passwordAgain}
          onChange={e => setPasswordAgain(e.target.value)}
          variant="outlined"
          placeholder='******'
          hiddenLabel
          size="small"
          type={'password'}
          error={password !== passwordAgain}
          helperText={password !== passwordAgain ? 'A két jelszó nem egyezik meg' : ' '}
        />
      </div>

      <LoadingButton
        onClick={ () => registrationButtonClick() }
        loading={ loading }
        variant="contained"
        style={ styles.loginButton }
        disabled={ loading }
      >
        Regisztráció
      </LoadingButton>

      <Link
        component="button"
        variant="body1"
        underline="none"
        onClick={ () => props.registrationToLogin() }
        style={ styles.registrationLink }
      >
        Vissza a bejelentkezéshez
      </Link>
    </div>
  )
}

export default Registration;
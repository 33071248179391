import React, { useState } from "react";
import { Button } from "@mui/material";

const CategoryModal = (props) => {
  const [categories,] = useState(props.categories);
  const [parent, setParent] = useState(-1);

  const onCategory = (id) => {
    if(parent === -1){
      setParent(id);
      props.changeCategory(id, -1);
    }
    else{
      props.changeCategory(id, parent);
      props.setCategoryModal(false);
      props.setDisableClick(false);
    }
  };

  const onParent = () => {
    props.changeCategory(parent, -1);
    props.setCategoryModal(false);
    props.setDisableClick(false);
  }

  const onReturn = () => {
    if(parent !== -1){
      setParent(-1);
    }
    else{
      props.changeCategory(-1, -1);
      props.setCategoryModal(false);
      props.setDisableClick(false);
    }
  }

  return(
    <div style={styles.tableList}>
      {categories.filter(category => category.parent === parent).map((category, i) => {
        return(
          <Button
            key={i}
            onClick={() => onCategory(category.id)}
            style={styles.button}
          >
            {category.name}
          </Button>
        );
      })}
      {parent !== -1 &&
        <Button
          onClick={onParent}
          style={{...styles.button, borderColor: 'darkred'}}
        >
          Csak csoport választása
        </Button>
      }
      <Button
        onClick={onReturn}
        style={{...styles.button, borderColor: 'red'}}
      >
        Mégse
      </Button>
    </div>    
  )
}

const styles = {
  tableList: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: '1',
    position: 'fixed',
    backgroundColor: '#F5F5F5',
    left : '0',
    right : '0',
    margin: '5% auto',
    width: '400px',
    padding: '25px',
    border: '1px solid rgba(0, 0, 0, 0.21)',
    borderRadius: '5px',
    maxHeight: '70%',
    overflowY: 'auto',
    maxWidth: '75%',
  },
  button: {
    backgroundColor: '#FFFF',
    borderRadius: '8px',
    color: '#000',
    width: '100%',
    margin: '4px',
    border: 'solid #284aff 2px',
  },
};

export default CategoryModal;
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Modal, Typography } from "@mui/material";
import * as Global from '../../Global';
import { useState } from 'react';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius:10,
  p:4,
  boxShadow: 24,
};

const iconStyle = {
  fontSize: "2rem",
  float: "right",
  color: "red",
  '&:hover':{
    cursor:'pointer'
  }
};

const DeleteTableModal = (props) => {
  const [deletable,] = useState(props.deletable);
  const [id,]        = useState(props.id ?? -1);

  const Delete = () => {
    props.ws.send('DELETETABLE|'+Global.USER+'|'+id);
    props.setModal(false);
  }

  return(
    <Modal
      open={true}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Asztal törlés
          <DisabledByDefaultOutlinedIcon sx={iconStyle} onClick={() => props.setModal(false)}/>  
        </Typography>          
        {!deletable && 
          <Typography id="modal-modal-title">          
            Az asztalhoz tételek tartoznak.<br/>
            Távolítsa el a tételeket az asztal törlése előtt.
          </Typography> 
        }
        {deletable &&
          <div>
            <Typography id="modal-modal-title">          
              Az asztal üres.<br/>
              Biztosan törölni kívánja?
            </Typography>
            <br/>      
            <Grid container textAlign="center">
              <Grid item xs={6}>
                <LoadingButton onClick={() => props.setModal(false)} variant="contained" color="success">Nem</LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton onClick={Delete} variant="contained" color="error">Igen</LoadingButton>
              </Grid>
            </Grid>
          </div>
        }
      </Box>
    </Modal>
  )
}

export default DeleteTableModal;

import { forwardRef, useImperativeHandle, useState } from 'react';
import * as Global from '../../Global';
import DailyStats from './DailyStats';
import DayByDayStats from './DayByDayStats';
import { useRef } from 'react';
import { Box, Button, Modal } from '@mui/material';
import { DateRange, Calendar } from 'react-date-range';
import * as Locales from 'react-date-range/dist/locale';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import MonthlyTraffic from './MonthlyTraffic';
import Top10Items from './Top10Items';
import Top100Items from './Top100Items';
import AmountByGroups from './AmountByGroups';
import Top10ItemsByTraffic from './Top10ItemsByTraffic';
import Top100ItemsByTraffic from './Top100ItemsByTraffic';
import TrafficByGroups from './TrafficByGroups';
import TrafficByHour from './TrafficByHour';

const summer = payments => {
  let sum = 0;
  payments.forEach( payment => {sum += parseInt(payment.sum);})
  return sum;
}

const Stats = forwardRef((props, ref) => {
  const [stats, ] = useState(props.stats);
  const [stornoStats, ] = useState(props.stornoStats);
  const [sum,] = useState(summer(props.stats));
  const [modalProps, setModalProps] = useState({
    open:false,
    function:''
  });
  const [dateModalProps, setDateModalProps] = useState({
    open:false,
    function:''
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);
  const [dateState,setDateState] = useState(new Date());

  const DailyRef = useRef();
  const DailyRef2 = useRef();
  const TrafficByHourRef = useRef();
  const DayByDayRef = useRef();  
  const MonthlyRef = useRef();
  const AmountByGroupsRef = useRef();
  const TrafficByGroupsRef = useRef();
  const Top10Ref = useRef();
  const Top100Ref = useRef();
  const Top100ByTrafficRef = useRef();
  const Top10ByTrafficRef = useRef();

  useImperativeHandle(ref, () => {
    return{
      setDailyTraffic: DailyRef.current.updateData,
      setDailyStornoTraffic: DailyRef2.current.updateData,
      setTrafficByHour: TrafficByHourRef.current.setData,
      setDayByDayData: DayByDayRef.current.setData,
      setMonthlyTraffic: MonthlyRef.current.setData,
      setAmountByGroups: AmountByGroupsRef.current.setData,
      setTrafficByGroups: TrafficByGroupsRef.current.setData,
      setTop10Traffic: Top10Ref.current.setData,
      setTop100Traffic: Top100Ref.current.setData,
      setTop100ByTraffic: Top100ByTrafficRef.current.setData,
      setTop10ByTraffic: Top10ByTrafficRef.current.setData
    }
  })

  const sendDateInterval = () => {
    console.log(state);
    let date1, date2;
    date1 = state[0].startDate.getFullYear()+'-'+(state[0].startDate.getMonth()+1)+'-'+state[0].startDate.getDate();
    if(state[0].endDate){
      date2 = state[0].endDate.getFullYear()+'-'+(state[0].endDate.getMonth()+1)+'-'+state[0].endDate.getDate();
    }
    else{
      date2 = date1;
    }
    props.ws.send(modalProps.function+'|'+Global.USER+'|'+date1+'|'+date2);
    setModalProps({
      open:false,
      function:''
    });
  };

  const sendDate = () => {
    let date = dateState.getFullYear()+'-'+(parseInt(dateState.getMonth())+1)+'-'+dateState.getDate();
    dateModalProps.dateSetter(new Date(date));
    props.ws.send(dateModalProps.function+'|'+Global.USER+'|'+date);
    setDateModalProps({
      open:false,
      function:'',
      dateSetter:null
    });
  }


  return(
    <div style={{paddingLeft:16,paddingRight:16}}>
      <div style={styles.sum}>
        Teljes napi forgalom:&nbsp;{sum}&nbsp;Ft {sum === 0 && "(Nem volt ma még forgalom)"}
      </div>
      <DailyStats stats={stats} setDateModalProps={setDateModalProps} ref={DailyRef}/>  
      <DailyStats stats={stornoStats} setDateModalProps={setDateModalProps} ref={DailyRef2} isStorno/>
      <TrafficByHour ws={props.ws} ref={TrafficByHourRef} setDateModalProps={setDateModalProps}/>
      <DayByDayStats ws={props.ws} ref={DayByDayRef} setModalProps={setModalProps}/>
      <MonthlyTraffic ws={props.ws} ref={MonthlyRef} setModalProps={setModalProps}/>
      <AmountByGroups ws={props.ws} ref={AmountByGroupsRef} setModalProps={setModalProps}/>
      <TrafficByGroups ws={props.ws} ref={TrafficByGroupsRef} setModalProps={setModalProps}/>
      <Top10Items ws={props.ws} ref={Top10Ref} setModalProps={setModalProps}/>
      <Top10ItemsByTraffic ws={props.ws} ref={Top10ByTrafficRef} setModalProps={setModalProps}/>
      <Top100Items ws={props.ws} ref={Top100Ref} setModalProps={setModalProps}/>
      <Top100ItemsByTraffic ws={props.ws} ref={Top100ByTrafficRef} setModalProps={setModalProps}/>
      <Modal
        open={modalProps.open}
        function={modalProps.function}
        onClose={() => setModalProps({
          open:false,
          function:''
        })}
      >
        <Box sx={styles.modal}>
          <DateRange
            editableDateInputs={true}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
            locale={Locales.hu}
          />
          <Button onClick={sendDateInterval}>Küldés</Button>
        </Box>
      </Modal>
      <Modal
        open={dateModalProps.open}
        function={dateModalProps.function}
        onClose={() => setDateModalProps({
          open:false,
          function:''
        })}
      >
        <Box sx={styles.modal}>
          <Calendar
            date={dateState}
            onChange={item => setDateState(item)}
            locale={Locales.hu}
          />
          <Button onClick={sendDate}>Küldés</Button>
        </Box>
      </Modal>
    </div>
  )
})

const styles = {
  sum:{
    marginLeft: 24,
    fontSize: 19,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  modal:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
};

export default Stats;

import { React, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { red, green } from '@mui/material/colors';
import * as Global from '../Global';

const styles = {
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

const UserListItem = (props) => {
  const [status, setStatus] = useState(props.user.status);
  if(status !== props.user.status){
    setStatus(props.user.status);
  }

  const edit = (e) => {
    props.ws.send('PREEDITEMPLOYEE|'+Global.USER+"|"+props.user.id);
  } 

  const statusHandler = (e) => {
    if(status){
      props.ws.send("SETEMPLOYEESTATUS|"+Global.USER+"|"+props.user.id+"|false");
    }
    else{
      props.ws.send("SETEMPLOYEESTATUS|"+Global.USER+"|"+props.user.id+"|true");      
    }
  }

  return(
    <li>
      <ul style={styles.ul}>
        <li style={{...styles.li,width:'40%',minWidth:180}}>
          {props.user.name}
        </li>
        <li style={{...styles.li,width:'40%',minWidth:180}}>
          {props.user.level} ({props.user.l_num})
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          {status?
            <CheckCircleIcon 
              onClick={statusHandler} 
              sx={{
                color:green[500],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
            :
            <CancelIcon 
              onClick={statusHandler} 
              sx={{
                color:red[400],
                '&:hover': {
                  cursor: 'pointer'
                }  
              }}
            />
          }
          
        </li>
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={styles.editDiv} onClick={edit}>
            <EditIcon  style={{color:'#7186fa'}}/>
          </div>
        </li>      
      </ul>
    </li>  
    // <TableRow
    //   sx={{
    //     '& td': {
    //       padding: '7px'
    //     },
    //     '&:nth-of-type(2n+1)': {
    //       background: '#eee'
    //     },
    //     '&:hover': {
    //       background: '#ddd'
    //     }
    //   }}
    // >
    //   <TableCell>{props.user.name}</TableCell>
    //   <TableCell align="center">{props.user.level} ({props.user.l_num})</TableCell>
    //   <TableCell align="center">
    //     <Tooltip title={(status ? 'Inaktívra' : 'Aktívra') + ' állítás'}>
    //       <IconButton onClick={statusHandler}>
    //         <CircleIcon sx={{ color: status ? green[500] : red[400] }}/>
    //       </IconButton>
    //     </Tooltip>
    //   </TableCell>
    //   <TableCell align="right" style={{ width: '1%' }}><LoadingButton loading={loading} onClick={edit} variant="contained">Szerkesztés</LoadingButton></TableCell>
    // </TableRow>
  )    
}

export default UserListItem;
import { useState } from 'react';
import {Accordion, AccordionSummary, Typography, AccordionDetails, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Malfunctions = (props) => {
  const [value, setValue] = useState(0);
  
  const handleChange = (e) => {
    setValue(e.target.value);
    props.setMalfunction(e.target.value);
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <Typography>Üzemzavar okok</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {props?.malfunctions.map((mal) => (
            <FormControlLabel key={mal.id} value={mal.id} control={<Radio />} label={mal.name} />
          ))}
        </RadioGroup>
       
      </AccordionDetails>
    </Accordion>
  )

}

export default Malfunctions;
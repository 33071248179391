import React, { useState, useReducer } from 'react';
import { Button, ButtonGroup, IconButton, Typography, FormControl, Select, MenuItem, Grid } from '@mui/material';

import * as Global from '../../Global';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { toast } from 'react-toastify';

const styles = {
  tableTransfer: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: '1',
      position: 'fixed',
      backgroundColor: '#F5F5F5',
      left : '0',
      right : '0',
      margin: '5% auto',
      //width: '400px',
      padding: '25px',
      border: '2px solid black',
      borderRadius: '5px',
      maxHeight: '70%',
      overflowY: 'auto',
      head: {

      },
      inside: {

      },

  },

}

const TableTransfer = (props) => {
  const [products, setProducts] = useState(props.products);
  const [tables] = useState(props.tables);
  const [table, setTable] = useState(props.currentTable);
  const [, forceUpdate] = useReducer(x => !x);

  const plusOne = (product, index) => {

    let temp = products;
    if(isNaN(temp[index].amount)){
      temp[index].amount = 1;
    }
    else if (temp[index].amount < temp[index].quantity) temp[index].amount += 1;
    setProducts(temp);
    forceUpdate();
  }

  const minusOne = (product, index) => {
    let temp = products;
    if(temp[index].amount > 0){
      temp[index].amount -= 1;
    }
    setProducts(temp);
    forceUpdate();
  }

  const transferHandler = () => {
    console.log(table, products);
    if(table === props.currentTable){
      toast.error('A jelenleg aktív asztalra nem lehet áthelyezni.');
    }
    else {
      let temp = '';
      for(let count in products) {
        if(products[count].amount > 0) {
          temp += products[count].prodid + '|' + products[count].amount + '|' + products[count].id + '|';
        }
      }
      props.ws.send('TRANSFERBETWEENTABLES|' + Global.USER + '|' + props.currentTable + '|' + table + '|' + temp);
    }
  }

  return (
    <Grid item lg={6} md={6} sm={8} xs={11} style={styles.tableTransfer}>
      <FormControl md={2} style={ styles.inputDiv }>
        <Typography>Asztal</Typography>
        <Select
          id="imple-select"
          value={table}
          displayEmpty
          onChange={(e) => setTable(e.target.value)}
        >
          {tables && tables.map((table) => {
            if (table.status === 1) {
              return (  
                <MenuItem value={table.id} key={table.id}>{table.name}</MenuItem>
              )
            }
            else {
              return null;
            }
          })}
        </Select>
      </FormControl>
      {products.map((product, index) => (
        <div key={index}>
          <p>{product.name} - {product.quantity} db {product.price} Ft Másik asztalra:  {product.amount ?? 0} db</p>
          <div>
            <IconButton onClick={() => plusOne(product, index)}>
              <AddIcon/>
            </IconButton>
            <IconButton onClick={() => minusOne(product, index)}>
              <RemoveIcon />
            </IconButton>
          </div>
        </div>
      ))}
      <div>
        <ButtonGroup variant="contained">
          <Button onClick={(e) => transferHandler()}>Áthelyezés</Button>
          <Button onClick={(e) => {props.setTableTransfer(false); props.setDisableClick(false)}}>Mégse</Button>
        </ButtonGroup>
      </div>
    </Grid>
  )
}

export default TableTransfer;
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Modal, Typography } from "@mui/material";
import * as Global from '../../Global';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius:10,
};

const DeleteCategoryModal = (props) => {

  const Delete = () => {
    props.ws.send('DELETECATEGORY|'+Global.USER+'|'+props.id+'|'+props.groupID);
    props.setModal(false);
  }

  return(
    <Modal
      open={true}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Kategória törlés
        </Typography>        
        <Typography id="modal-modal-title">
          A kategóriához összesen {props.count} tétel tartozik.<br/>
          Biztosan törölni akarja a kategóriát?
        </Typography>          
        <br/>      
        <Grid container textAlign="center">
          <Grid item xs={6}>
            <LoadingButton onClick={() => props.setModal(false)} variant="contained" color="success">Nem</LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton onClick={Delete}variant="contained" color="error">Igen</LoadingButton>
          </Grid>
        </Grid>
        {props.count > 0 &&
          <div>
            <br/>
            <i>* A kategória tételei nem törlödnek</i>
          </div>
        }
      </Box>
    </Modal>
  )
}

export default DeleteCategoryModal;

import { Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import PayedPeriodsItem from './PayedPeriodsItem';
import PaymentWindow from './paymentWindow';

const styles = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',  
    clear: 'both'
  },
  table: {
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    position:'fixed',
    bottom:10,
    right:10,
    padding:6,
    borderRadius:8,
    alignItems:'center',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
  noContent:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    listStyleType:'none',
    alignItems:'center',
    textAlign:'center',
    minWidth:600,
    boxShadow:'0px 0px 10px #d5d5d5',
  },
}

const PayedPeriods = (props) => {
  const [periods,] = useState(props.periods);
  
  const addPeriod = (e) => {
    props.setWindow(<PaymentWindow payPackages={props.payPackages} />);
  }

  return(
    <div style={styles.main}>
      <div style={styles.tableDiv}>
      <ul style={styles.mainUL}>
        <li style={{minWidth:600}}>
          <ul style={{listStyleType:'none', paddingLeft:16, margin:'0px 6px', display:'flex', minWidth:600}}>
            <li style={{...styles.li,width:'16%',minWidth:100, justifyContent:'center'}}>Kezdés</li>
            <li style={{...styles.li,width:'16%',minWidth:100, justifyContent:'center'}}>Időszak vége</li>
            <li style={{...styles.li,width:'17%',minWidth:100, justifyContent:'center'}}>Fizetett összeg</li>
            <li style={{...styles.li,width:'17%',minWidth:100, justifyContent:'center'}}>Fizetés dátuma</li>
            <li style={{...styles.li,width:'17%',minWidth:100, justifyContent:'center'}}>Fizetés módja</li>
            <li style={{...styles.li,width:'17%',minWidth:100, justifyContent:'center'}}>Fizetés állapota</li>            
          </ul>
        </li>
        {
          periods.length > 0 ?
            periods.map((period, i) => {
              return(
                <PayedPeriodsItem key={i} period={period}/>
              )
            })
            :
            <li style={styles.noContent}>
              Nincsenek még befizetések
            </li>
        }
      </ul> 
      </div>
      <Tooltip title="Új befizetés">
        <Fab size="medium" color="primary" onClick={addPeriod} style={styles.plusSign} aria-label="Új befizetés">
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  )
}

export default PayedPeriods;
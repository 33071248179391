import { React, useState } from "react";

const styles={
  ul:{
    paddingLeft:16,
    paddingRight:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

const PBRRow = (props) => {
  const [product, ] = useState(props.product);
  
  return(
    <li>
      <ul style={styles.ul}>
        <li style={{...styles.li,width:'25%',minWidth:140}}>{product.desc} ({(product.gross / product.amount).toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })})</li>
        <li style={{...styles.li,width: '5%',minWidth:40,justifyContent:'center'}}>{product.amount}</li>
        <li style={{...styles.li,width:'20%',minWidth:80,justifyContent:'right'}}>{product.net.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
        <li style={{...styles.li,width:'20%',minWidth:80,justifyContent:'right'}}>{(product.gross - product.net).toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
        <li style={{...styles.li,width:'25%',minWidth:80,justifyContent:'right'}}>{product.gross.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
        <li style={{...styles.li,width:'15%',minWidth:60,justifyContent:'right'}}>{product.vat}</li>
      </ul>
    </li>
  )    
}

export default PBRRow;
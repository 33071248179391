import React, { useState }              from 'react';
import { Typography, TextField, Grid,}  from '@mui/material';
import { LoadingButton }                from '@mui/lab';
import { toast }                        from 'react-toastify';
import * as Global                      from '../Global';
import SimpleSelect                     from './SimpleSelect';


const VATEditor = (props) => {
  const [loading,] =            useState(false);
  const [ntakVATs,] =           useState(props.ntakVATs);
  const [id,] =                 useState(props.vatType?.id        ?? 0);
  const [ntakVAT, setNtakVAT] = useState(props.vatType?.ntakVATID ?? 0);
  const [value,   setValue] =   useState(props.vatType?.value     ?? 0);
  const [name,    setName] =    useState(props.vatType?.name      ?? '');
  const [name2,   setName2] =   useState(props.vatType?.name2     ?? '');

  const addVAT = () => {
    //guards
    if(isNaN(value)){     toast.error('Az érték nem szám');                                       return;}
    if(name.length < 1){  toast.error('A megnevezés legalább 1 karakter hosszú kell legyen');     return;}
    if(name.length > 3){  toast.error('A megnevezés legfeljebb 3 karakter hosszú kell legyen');   return;}
    if(name2.length < 3){ toast.error('A megnevezés 2 legalább 3 karakter hosszú kell legyen');   return;}
    if(name2.length > 6){ toast.error('A megnevezés 2 legfeljebb 6 karakter hosszú kell legyen'); return;}
    if(parseFloat(value) !== parseInt(value)){toast.error('Az érték egész szám kell legyen');     return;}

    //update
    if(id > 0)  props.ws.send('UPDATEVAT|'+Global.USER+'|'+id+'|'+name+'|'+name2+'|'+parseInt(value).toString()+'|'+ntakVAT);
    
    //create
    else        props.ws.send('CREATEVAT|'+Global.USER+'|'+name+'|'+name2+'|'+parseInt(value).toString()+'|'+ntakVAT);
  }

  return(
    <div style={{maxWidth:600, margin:'auto',width:'fit-content'}}>
      <Grid container style={ styles.main } spacing={2}>
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Megnevezés</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}> 
            <TextField
              style={styles.input}
              value={name}
              onChange={e => setName(e.target.value)}
              variant="outlined"
              placeholder='Példa Pali'
              hiddenLabel
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Megnevezés 2</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
            <TextField
              style={styles.input}
              value={name2}
              onChange={e => setName2(e.target.value)}
              variant="outlined"
              placeholder='PELDA'
              hiddenLabel
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Érték (%)</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
            <TextField
              style={styles.input}
              value={value}
              onChange={e => setValue(e.target.value)}
              variant="outlined"
              placeholder='PELDA'
              hiddenLabel
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>NTAK ÁFA kulcs</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
           <SimpleSelect
              selected={ntakVAT}
              datas={ntakVATs}
              onChange={e => setNtakVAT(e.target.value)}
              goal={"NTAK áfa kulcsok"}
            />
          </Grid>
        </Grid>
        <LoadingButton
          onClick={ () => addVAT() }
          loading={ loading }
          variant="contained"
          style={ styles.loginButton }
          disabled={ loading }
        >
          {id > 0 ? 'Mentés' : 'Hozzáadás' }
        </LoadingButton>
      </Grid>
    </div>
  )
}

const styles = {
  main: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 30px)',
    padding: '15px'
  },
  paper: {
    padding: 15,
    width: '100%',
    maxWidth: 500,
    textAlign: 'center'
  },
  logo: {
    maxWidth: '300px',
    width: '100%'
  },
  headerText: {
    marginBottom: 15
  },
  loginButton: {
    marginBottom: 15
  },
  registrationLink: {
    width: '100%',
  },
  checkDiv: {
    marginBottom: 15,
    alignItems: 'center',
  },
  checkbox: {
    padding: 0,
  },
  row:{
    width:'100%',
    maxWidth:1000,
    paddingLeft:0,
  },
  inputDiv:{
    paddingLeft:0,
    display:'flex',
    width:'60%',
    justifyContent:'left',
    flex:'1 1 auto',
    minWidth:250
  },
  inputLabelDiv:{
    paddingLeft:0,
    display:'flex',
    width:'calc(40% - 20px)',
    marginRight:20,
    minWidth:200,
    justifyContent:'right',
    flex:'1 1 auto',
  },
  inputLabel:{
    display:'flex',
    alignItems:'center',
  },
  input:{
    width:'100%',
    maxWidth:225,
    minWidth:200,
  },
}

export default VATEditor;
import React, { useState } from 'react';
import { Typography, TextField, Grid, FormHelperText, FormControl} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

import { passwordValidate, passwordError } from '../core';

import * as Global from '../Global';
import SimpleSelect from './SimpleSelect';

const styles = {
  main: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 30px)',
    padding: '15px'
  },
  paper: {
    padding: 15,
    width: '100%',
    maxWidth: 500,
    textAlign: 'center'
  },
  logo: {
    maxWidth: '300px',
    width: '100%'
  },
  headerText: {
    marginBottom: 15
  },
  loginButton: {
    marginBottom: 15
  },
  registrationLink: {
    width: '100%',
  },
  checkDiv: {
    marginBottom: 15,
    alignItems: 'center',
  },
  checkbox: {
    padding: 0,
  },
  row:{
    width:'100%',
    maxWidth:1000,
    paddingLeft:0,
  },
  inputDiv:{
    paddingLeft:0,
    display:'flex',
    width:'60%',
    justifyContent:'left',
    flex:'1 1 auto',
    minWidth:250
  },
  inputLabelDiv:{
    paddingLeft:0,
    display:'flex',
    width:'calc(40% - 20px)',
    marginRight:20,
    minWidth:200,
    justifyContent:'right',
    flex:'1 1 auto',
  },
  inputLabel:{
    display:'flex',
    alignItems:'center',
  },
  input:{
    width:'100%',
    maxWidth:225,
    minWidth:200,
  },
}

const UserEditor = (props) => {
  const [id,]                             = useState(props.employee.id ?? null)
  const [editing,]                        = useState(props.editing);
  const [constRights,]     = useState(props.constRights); 
  const [right, setRight]                 = useState(props.employee.right ?? null);
  const [fullName, setFullName]           = useState(props.employee.fullName ?? '');
  const [username, setUsername]           = useState(props.employee.username ?? '');
  const [password, setPassword]           = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passError, setPassError]         = useState(false);
  const [passHelper, setPassHelper]       = useState('');
  const [againError, setAgainError]       = useState(false);
  const [againHelper, setAgainHelper]     = useState('');
  const [telefon,]             = useState(props.employee.telefon ?? '');
  const [email,]                 = useState(props.employee.email ?? '');
  const [twoFa,]                 = useState(props.employee.twoFa ?? 'none');
  const [loading, setLoading]             = useState(false);

  const addEmployee = () => {
    setLoading(true);
    

    if(fullName === '')                                 toast.error('A teljes név megadása kötelező', {autoClose: 5000});
    else if(username === '')                            toast.error('A felhasználónév megadása kötelező', {autoClose: 5000});
    else if(!passwordValidate(password) && !editing)    toast.error('A jelszó nem felel meg a követelményeknek', {autoClose: 5000});
    else if(password !== passwordAgain)                 toast.error('A jelszavak nem egyeznek meg', {autoClose: 5000});
    else if(twoFa !== 'none' && (email === '' && telefon === '')) toast.error('Két faktoros azonosítás megadása szükséges', {autoClose: 5000});
    else{
      if(editing){
        // props.ws.send('EDITEMPLOYEE|'+Global.USER+'|'+id+'|'+right+'|'+fullName+'|'+username+ '|'+twoFa+'|'+email+'|'+telefon)
        props.ws.send('EDITEMPLOYEE|'+Global.USER+'|'+id+'|'+right+'|'+fullName+'|'+username+ '|none|'+email+'|'+telefon)
      }
      else{
        // props.ws.send('ADDEMPLOYEE|'+Global.USER+'|'+right+'|'+fullName+'|'+username+'|'+password + '|'+twoFa+'|'+email+'|'+telefon);
        props.ws.send('ADDEMPLOYEE|'+Global.USER+'|'+right+'|'+fullName+'|'+username+'|'+password + '|none|'+email+'|'+telefon);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  const RightHandler = (e) => {
    setRight(e.target.value);
  }

  const passwordValidateHandler = (e) => {
    if(passwordValidate(e.target.value)){
      setPassError(false);
      setPassHelper('');
    }
    else {
      setPassError(true);
      setPassHelper(passwordError(e.target.value));
    }
    if(e.target.value === ''){
      setPassError(false);
      setPassHelper('');
    }
  }

  const passwordMatch = () => {
    if(passwordAgain !== password ) {
      setAgainError(true);
      setAgainHelper('A két jelszó nem egyezik meg!');
    }
    else {
      setAgainError(false);
      setAgainHelper('');
    }
  }

  return(
    <div style={{maxWidth:600, margin:'auto',width:'fit-content'}}>
      <Grid container style={ styles.main } spacing={2}>
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Teljes név</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}> 
            <TextField
              style={styles.input}
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              variant="outlined"
              placeholder='Példa Pali'
              hiddenLabel
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Felhasználó név</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
            <TextField
              style={styles.input}
              value={username}
              onChange={e => setUsername(e.target.value)}
              variant="outlined"
              placeholder='PELDA'
              hiddenLabel
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Jogosultság</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
           <SimpleSelect
              selected={right}
              datas={constRights}
              onChange={e => RightHandler(e)}
              goal={"jogosultságok"}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>2 faktoros azonosítás</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
            <Select
              id="imple-select"
              value={twoFa}
              displayEmpty
              onChange={(e) => setTwoFa(e.target.value)}
            >
              <MenuItem value={'none'}>Nincs</MenuItem>
              <MenuItem value={'Telefon'}>Telefon</MenuItem>
              <MenuItem value={'Email'}>Email</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {twoFa === 'Telefon' &&
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Telefonszám</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
            <TextField
              style={styles.input}
              value={telefon}
              onChange={e => setTelefon(e.target.value)}
              variant="outlined"
              placeholder=''
              hiddenLabel
              size="small"
            />
          </Grid>
        </Grid>
        }
        {twoFa === 'Email' &&
        <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
          <Grid item style={styles.inputLabelDiv}>
            <Typography style={styles.inputLabel}>Email cím</Typography>
          </Grid>
          <Grid item style={styles.inputDiv}>       
            <TextField
              style={styles.input}
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant="outlined"
              placeholder=''
              hiddenLabel
              size="small"
            />
          </Grid>
        </Grid>
        } */}
        {!editing  && 
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={styles.inputLabelDiv}>
              <Typography style={styles.inputLabel}>Jelszó</Typography>
            </Grid>
            <Grid item style={styles.inputDiv}>  
              <FormControl variant="standard">
                <TextField
                  style={styles.input}
                  value={password}
                  onChange={(e) => {setPassword(e.target.value); passwordValidateHandler(e)}}
                  variant="outlined"
                  placeholder='******'
                  hiddenLabel
                  size="small"
                  type={'password'}
                  error={passError}
                  helperText={passHelper}
                />
                <FormHelperText id="component-helper-text" style={{color:'#23b9db'}}>
                  Legalább 8 karakter hosszú<br/>
                  Tartalmaz kis- és nagybetűt, és számot is
                </FormHelperText>
              </FormControl> 
            </Grid>
          </Grid>
        }
        {!editing &&
          <Grid container spacing={1} direction="row" style={{justifyContent:'center',flexWrap:'nowrap'}}>
            <Grid item style={styles.inputLabelDiv}>
              <Typography style={styles.inputLabel}>Jelszó mégegyszer</Typography>
            </Grid>
            <Grid item style={styles.inputDiv}>       
              <TextField
                style={styles.input}
                value={passwordAgain}
                onChange={e => setPasswordAgain(e.target.value)}
                onBlur={e => passwordMatch()}
                variant="outlined"
                placeholder='******'
                hiddenLabel
                size="small"
                type={'password'}
                error={againError}
                helperText={againHelper}
              />
            </Grid>     
          </Grid>
        }
        <LoadingButton
          onClick={ () => addEmployee() }
          loading={ loading }
          variant="contained"
          style={ styles.loginButton }
          disabled={ loading }
        >
          Hozzáadás
        </LoadingButton>
      </Grid>
    </div>
  )
}

export default UserEditor;
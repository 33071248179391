import { useEffect, useState }  from 'react';
import AddIcon                  from '@mui/icons-material/Add';
import VATTypesRow              from './VATTypesRow';
import VATEditor                from './VATEditor';

const VATTypes = (props) => {
  const [vatTypes, setVatTypes] = useState(props.vatTypes);
  const [ntakVATs, setNtakVATs] = useState(props.ntakVATs)

  //reload data, when necessary
  useEffect(() => {
    if(vatTypes !== props.vatTypes){
      setVatTypes(props.vatTypes);
    }
    if(ntakVATs !== props.ntakVATs){
      setNtakVATs(props.ntakVATs);
    }
  //eslint-disable-next-line
  }, [props]);

  return(
    <div style={style.main}>
      <div style={style.tableDiv}>
        <ul style={style.mainUL}>
          <li style={{minWidth:600}}>
            <ul style={{listStyleType:'none',minWidth:600,paddingLeft:16, margin:'0px 6px', display:'flex'}}>
              <li style={{...style.li,width:'50%',minWidth:250, justifyContent:'left'}}>Érték</li>
              <li style={{...style.li,width:'30%',minWidth:250, justifyContent:'left'}}>Megnevezés</li>
              <li style={{...style.li,width:'20%',minWidth:100, justifyContent:'center'}}>
                <div style={style.plusSign} onClick={()=>{props.setWindowText('ÁFA szerkesztő');props.setWindow(<VATEditor ws={props.ws} ntakVATs={ntakVATs}/>)}}>
                  <AddIcon sx={{color: 'white'}} />
                </div>
              </li>
            </ul>
          </li>
          {vatTypes.map((vatType, i) => {
            return(
              <VATTypesRow ntakVATs={ntakVATs} ws={props.ws} setWindowText={props.setWindowText} setWindow={props.setWindow} key={i} vatType={vatType}/>
            )
          })}
        </ul>      
      </div>
    </div>
  )
}

const style = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  table: {
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
}

export default VATTypes;
import React, { useState, useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import * as Rights from './Rights';
import Login from './pages/Login';
import Registration from './pages/Registration';
import Sidebar from './pages/Sidebar';
import Appbar from './pages/Appbar';
import ProductList from './pages/ProductList';
import ItemGroups from './pages/ItemGroups';
import CurrencyList from './pages/CurrencyList';
import PayedPeriods from './pages/PayedPeriods';
import PartnerList from './pages/PartnerList';
import Sale from './pages/sale/Sale';

import { IP } from './ip.js';
import * as Global from './Global';
import ProductEditor from './pages/ProductEditor';
import Settings from './pages/Settings';
import CategoryList from './pages/CategoryList';
import PartnerEditor from './pages/PartnerEditor';
import UserList from './pages/UserList';
import UserEditor from './pages/UserEditor';
import MyProfile from './pages/MyProfile';
import TableList from './pages/TableList';
import DeleteTableModal from './pages/modals/DeleteTableModal';
import DataGrid from './pages/DataGrid';
import ProductsByReceipt from './pages/productsByReceipt/ProductsByReceipt';
import AmountUnits from './pages/AmountUnits';
import StornoModal from './pages/storno/StornoModal';
import PayStornoModal from './pages/storno/PayStornoModal';
import Stats from './pages/stats/Stats';
import VATTypes from './pages/VATTypes';
let websocketIP = window.location.host; // az IP alapértelmezetten legyen megegyező az url-lel
let wsUrl = `wss://${websocketIP}/wsntakclient/`;
if (window.location.port === '3000') { // fejlesztői szerveren viszont felül kell írni
  websocketIP = IP;
  wsUrl = `ws://${websocketIP}:8080/wsntakclient/`;
}
export var ws = new WebSocket(wsUrl);

let theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0e1f42',
      contrastText: '#fff',
    },
    common: {
      main: '#eee',
      contrastText: '#333',
    },
  },
});

function App() {
  const [login, setLogin] = useState(true);
  const [registration, setRegistration] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [windowText, setWindowText] = useState('');
  const [window, setWindow] = useState(false);
  const [modal, setModal] = useState(false);
  const [compName, setCompName] = useState('');
  const [siteName, setSiteName] = useState('');
  
  const saleWin = useRef();
  const statWin = useRef();
  const productWin = useRef();
  const settingsRef = useRef();

  ws.onclose = e => {
    console.log(`Socket is closed.`, e.reason);
    toast.error('Elvesztetted a kapcsolatot a szerverrel!', {
      autoClose: 5000,
    });
  }
  ws.onopen = () => {
    console.log("%cSikeres csatlakozás a WebSockethez.", "color: white; background: green; padding: 3px;");
    toast.success('Sikeres csatlakozás a szerverhez!', {
      autoClose: 5000,
    });
    let jwtToken = sessionStorage.getItem('jwtToken');
    if (jwtToken !== null) {
      console.log(jwtToken);
      ws.send('AUTOLOGIN|' + jwtToken);
    }
  };
  ws.onerror = err => {
    console.error("Socket encountered error: ", err.message, "Closing socket");
    toast.error('Elvesztetted a kapcsolatot a szerverrel!', {
      autoClose: 5000,
    });
  };  

  try {
    ws.onmessage = function(event) {
      //console.log(event.data);
      if (!login) {
        if(document.getElementsByClassName('grecaptcha-badge')[0] !== undefined){
          document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden';
        }
      }

      let json;
      try {
        json = JSON.parse(event.data);
      } catch(e) {
        console.log(event.data);
        console.error(e);
        const index = event.data.indexOf('{');
        if (index > -1) {
          try {
            json = JSON.parse(event.data.slice(index));
          } catch (e2) {
            console.log(event.data);
            console.error(e2);
            toast.error('A program kommunikáció során végzetes hibára futott!', {
              autoClose: 20000,
            });
            toast.error(e2, {
              autoClose: 20000,
            });
            return false;
          }
        }
      }
      console.log(json);
      
      if(json.success){
        toast.success(json.success);
      }
      if(json.error){
        toast.error(json.error);
      }

      switch (json.nev){
        case 'error':
            toast.error(json.description);
          break;
        
        case 'errors':
          for(let i=0;i<json.errors.length;i++){
            toast.error(json.errors[i].description);
          }
          break;

        case 'products':
          setWindow(<ProductList groups={json.groups} setWindow={setWindow} setWindowText={setWindowText} products={json.products} categories={json.categories} ws={ws}/>);
          setWindowText('Tételek');
          break;

        case 'getproduct':
          setWindow(<ProductEditor ref={productWin} setWindow={setWindow} setWindowText={setWindowText} ws={ws} product={json.product} categories={json.categories} groups={json.groups} vatrates={json.taxrates} units={json.units} pictureLink={json.product.pictureLink}/>);
          setWindowText("Tétel szerkesztése");
          break;

        case 'getcategories':
          productWin.current.getcategories(json);
          break;

        case 'gettables':
          setWindow(<TableList tables={json.tables} setWindow={setWindow} setWindowText={setWindowText} ws={ws} />)
          setWindowText('Asztalok');
          break;

        case 'precreproduct':
          setWindow(<ProductEditor ref={productWin} genRecommend={json.genRecommend} setWindow={setWindow} setWindowText={setWindowText} ws={ws} product={{}} groups={json.groups} vatrates={json.taxrates} units={json.units}/>);
          setWindowText("Új tétel hozzáadása");
          break;

        case 'groups':
          setWindow(<ItemGroups ws={ws} groups={json.groups} setWindow={setWindow} ntakGroups={json.ntakgroups}/>);
          setWindowText('Csoportok');
          break;

        case 'getcategoriesedit':
          setWindow(<CategoryList setModal={setModal} ws={ws} group={json.group} categories={json.categories} setWindow={setWindow} ntakCategories={json.ntakCategories}/>);
          setWindowText('Kategóriák');
          break;

        case 'partners':
          setWindow(<PartnerList ws={ws} partners={json.partners} setWindow={setWindow}/>);
          setWindowText('Partnerek');
          break;
        
        case 'editpartner':
          setWindow(<PartnerEditor partner={json.partner} ws={ws}/>)
          setWindowText('Partner szerkesztése');
          break;

        case 'getproductsbyreceipt':
          setWindow(<ProductsByReceipt ws={ws} products={json.products} szlaszam={json.szlaszam} partner={json.partner} szlaid={json.szamlaid}/>);
          setWindowText("Tétel lista");
          break;

        case 'currencies':
          setWindow(<CurrencyList currencies={json.currencies} ws={ws}/>);
          setWindowText("Devizák");
          break;

        case 'payedPeriods':
          for(let i=0;i<json.periods.length;i++){
            json.periods[i].payDate = DateFormatter(json.periods[i].payDate);
            json.periods[i].end = DateFormatter(json.periods[i].end);
            json.periods[i].start = DateFormatter(json.periods[i].start);
          }
          setWindow(<PayedPeriods setWindow={setWindow} periods={json.periods} payPackages={json.packages}/>);
          setWindowText('Befizetett időszakok');
          break;

        case 'getreceipt':
          setWindow(<DataGrid ws={ws} setWindow={setWindow} receipts={json.receipts} type={'receipts'}/>)
          setWindowText("Nyugták");
          break;

        case 'sikeres_bejelentkezes':
          loginHandle(json)
          break;

        case 'logout':
          setTimeout(() => {
            sessionStorage.removeItem('jwtToken');
            setLogin(true);
            setWindow(null);
          }, 400);
          break;

        case 'predeletetable':
          setModal(<DeleteTableModal ws={ws} id={json.id} setModal={setModal} deletable={json.deletable}/>)
          break;

        case 'userdata':
          setWindowText("Adataim");
          setWindow(<MyProfile ws={ws} data={json.data} setWindow={setWindow} setWindowText={setWindowText}/>)
          break;

        case 'getemployees':
          setWindow(<UserList users={json.employees} ws={ws} setWindow={setWindow} setWindowText={setWindowText}/>)
          setWindowText('Felhasználók');
          break;

        case 'preeditemployee':
          setWindow(<UserEditor editing={true} ws={ws} employee={json.employee} constRights={json.rights}/>)
          break;

        case 'preaddemployee':
          setWindow(<UserEditor editing={false} ws={ws} employee={{}} constRights={json.rights}/>)
          setWindowText("Új felhasználó hozzáadása");
          break;

        case 'dailyTraffic':
          if(!json.updating){
            setWindow(
              <Stats
                stats={json.data}
                stornoStats={json.dailyStornoTraffic} 
                ws={ws}
                statsByVat={json.dailyTrafficByVAT}
                stornoStatsByVat={json.dailyStornoTrafficByVAT}
                ref={statWin}
              />
            );
            setWindowText("");          
          }
          else{
            statWin.current.setDailyTraffic(json.data, json.date);
            statWin.current.setDailyStornoTraffic(json.dailyStornoTraffic, json.date);
          }
          break;

        case 'amountByGroups':
          statWin.current.setAmountByGroups(json.data);
          break;

        case 'trafficByGroups':
          statWin.current.setTrafficByGroups(json.data);
          break;

        case 'trafficByHour':
          statWin.current.setTrafficByHour(json.data);
          break;

        case 'saledata':
          setWindow(<Sale ref={saleWin} products={json.products} partnerId={json.partnerid} partner={json.partner} partnerdiscount={json.partnerdiscount}
                          categories={json.categories} orderProducts={json.kosarTetelek} brutto={json.brutto} orderId={json.szlakod} ws={ws} setWindow={setWindow} delivery={json.kiszallitas}/>)
          setWindowText("Eladás");
          break;

        case 'dayByDayTraffic':
          statWin.current.setDayByDayData(json.data);
          break;

        case 'monthlyTraffic':
          statWin.current.setMonthlyTraffic(json.data);
          break;

        case 'Top10Traffic':
          statWin.current.setTop10Traffic(json.data);
          break;
          
        case 'Top10ByTraffic':
          statWin.current.setTop10ByTraffic(json.data);
          break;

        case 'Top100Traffic':
          statWin.current.setTop100Traffic(json.data);
          break;

        case 'Top100ByTraffic':
          statWin.current.setTop100ByTraffic(json.data);
          break;
                    
        case 'settings':
          setWindow(<Settings 
            ref={settingsRef} ws={ws} partners={json.settings.partnerek} terminal={json.settings.terminal}
            sellings={json.settings.eladasimod} denominators={json.settings.osztoszam} 
            serviceCharge={json.settings.szervizdij} euro={json.euro} isDayOpen={json.isDayOpen}
          />);
          setWindowText("Beállítások");

          // after updating settings
          if(json.toast){
            toast.success(json.toast);
          }

          // if salesode changed
          if(json.reload){
            let timer = 5;
            toast.info('A beállítások életbe lépéséhez az oldal '+timer+' másodperc múlva újraindul');
            setTimeout(() => {
              //eslint-disable-next-line
              location.reload();
            }, timer * 1000);
          }
          break;

        case 'nodayopen':
          toast.error('Jelenleg nincs nyitott nap');
          settingsRef.current.daySetClosed()
          break;

        case 'dayalreadyopen':
          toast.error('Már van nyitott nap')
          settingsRef.current.daySetOpen();
          break;

        case 'daycantclose':
          toast.error('Nyitott nyugtával nem zárható le a nap!');
          settingsRef.current.daySetOpen();
          break;

        //sale kommunikáció
        case 'getsaletables':
          saleWin.current.setSaleTables(json);
          break;
        
        case 'tetelMod':
          saleWin.current.tetelMod(json);
          break;

        case 'cancelOrder':
          saleWin.current.cancelOrder();
          break;

        case 'grossAmount':
          if(json.brutto <= 0 || json.gross <= 0){
            toast.error('Nincs tétel kiválasztva');
            return;
          }
          saleWin.current.grossAmount(json);
          break;
        
        case 'grossAmountTable':
          saleWin.current.grossAmountTable(json);
          break;

        case 'orderClosed':
          saleWin.current.orderClose(json);
          break;

        case 'startInvoice':
          saleWin.current.startInvoice(json);
          break;

        case 'transfertables':
          saleWin.current.transfertables(json);
          break;

        case 'transferDone':
          saleWin.current.transferDone(json);
          break;

        case 'canceldone':
          saleWin.current.canceldone(json);
          break;
        
        case 'salepartners':
          saleWin.current.salepartners(json);
          break;

        case 'partnerValSiker':
          break;
        //sale kommunikáció end

        case 'vatTypes':
          // alert('IN-DEVELOPMENT');
          setWindowText('ÁFA-kulcsok beállítása');
          setWindow(<VATTypes setWindow={setWindow} setWindowText={setWindowText} vatTypes={json.localVATs} ntakVATs={json.ntakVATs} ws={ws} />);
          break;
        
        case 'amountUnits':
          setWindowText('Mennyiségi egységek beállítása');
          setWindow(<AmountUnits units={json.units} ws={ws} />)
        break;

        case 'sikeresbontasmodositas':
          break;

        case 'stornoItems':
          setModal(<StornoModal items={json.items} setModal={setModal} szlaszam={json.szlaszam} ws={ws} tip={json.borravalo}/>);
        break;

        case 'sikeresStorno':
          setModal(false);
          //toast.success("Sikeres stornó művelet");
          setModal(<PayStornoModal setModal={setModal} gross={json.brutto} order={json.stornoszamlaid} ws={ws} orderVATs={json.afaertekek} tip={json.borravalo}/>);
        break;

        case 'stornolist':
          setWindow(<DataGrid ws={ws} setWindow={setWindow} receipts={json.receipts} type={'storno'} setModal={setModal}/>)
          setWindowText("Stornó bizonylatok");
          break;

        case 'stornoproducts':
          setWindow(<ProductsByReceipt ws={ws} products={json.adat} szlaszam={json.szlaszam} partner={json.partner} szlaid={json.szamlaid} type={'storno'}/>);
          break;

        case 'stornocancelsiker':
          setModal(false);
          break;

        case 'closestorno':
          setModal(false);
          break;

        case 'sikeresCsoportUpdate':
          break;

        case 'sikeresnapnyitas':
          toast.success('A nap megnyílt');
          break;
        
        case 'sikeresnapzaras':
          toast.success('A nap sikeresen lezárult');
          break;

        case 'autologinfail':
        case 'empty':
          break;

        default:
          toast.error("Nem várt válasz érkezett");
          console.log(json)
          break;
      }
    }
  } catch (error) {
    console.error(error);
  }

  const sidebarWidth = 240;

  const DateFormatter = (date) => {
    date = date.split(" ")[0];
    date = date.split("-");
    date = date.reverse();
    date[0] = "20"+date[0];
    return new Date(date).toLocaleDateString('hu-HU');
  }

  const loginToRegistration = () => {
    setLogin(false);
    setRegistration(true);
  }

  const registrationToLogin = () => {
    setLogin(true);
    setRegistration(false);
  }

  const loginHandle = (json) => {
    setLogin(false);
    setCompName(json.compName);
    setSiteName(json.siteName);
    Global.USER = json.neve;
    sessionStorage.setItem('jwtToken', json.jwtToken);
    if(json.jog >= 40) Rights.ProductEditor = true;
    if(json.jog >= 100) {
      Rights.CurrencyEditor = true;
      Rights.AddUser = true;
      Rights.SeeStatistics = true;
      Rights.SeeSettings = true;
      Rights.Storno = true;
    } 
    
    if(json.beallitasok !== undefined) {
      for(let count in json.beallitasok){
        console.log(json.beallitasok[count], Global.SALEMODE);
        switch(json.beallitasok[count].id){
          case 'eladasimod':
            Global.SALEMODE = json.beallitasok[count].value;
            break;

          case 'terminal':
            Global.TERMINAL = json.beallitasok[count].value;
            break;
        
          default:
            break;
        }
      }
    }
    ws.send("SALE|"+Global.USER);
  }

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ToastContainer pauseOnFocusLoss={false} position={"top-right"} toastClassName={'toastify-toast'} bodyClassName={'toastify-body'} />
        <div className="App">
          {login &&
            <Login loginToRegistration={loginToRegistration} ws={ws}/>
          }

          {registration &&
            <Registration registrationToLogin={registrationToLogin} />
          }
          {!login && !registration &&
            <div>
              <Appbar sidebar={sidebar} windowText={windowText} sidebarWidth={sidebarWidth} setSidebar={setSidebar} theme={theme} />
              <Sidebar compName={compName} siteName={siteName} ws={ws} open={sidebar} sidebarWidth={sidebarWidth} setSidebar={setSidebar} setWindow={setWindow} setWindowText={setWindowText} />
              <div style={{ marginLeft: `${sidebar ? sidebarWidth : 0}px`, transition: `margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`, filter: `blur(${modal ? 4 : 0}px` }}>
                {window}
              </div>
            </div>
          }
          {!login && !registration && modal &&
            <div>
              {modal}
            </div>
          }
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;

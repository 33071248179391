import React, {useState} from 'react';
import { FormControl, MenuItem, Select, FormControlLabel, Checkbox, styled } from '@mui/material';

import * as Global from '../Global';

const CustomSelect = styled(Select)(() =>({
  '& legend':{
    display:'none'
  },
  '& fieldset':{
    height:50
  }
}));

const AmountUnits = (props) => {
  const [units] = useState(props.units);
  const [unit, setUnit] = useState('');
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    setUnit(e.target.value);
    let index = units.find( object => object.name === e.target.value );
    setChecked(index.breakable === 1 ? true : false);
  }

  const changeBreakable = () => {
    setChecked(!checked);
    let index = units.find( object => object.name === unit );
    props.ws.send('CHANGEBREAKABLE|' + Global.USER + '|' + index.id + '|' + !checked)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'row', margin: 8, justifyContent: 'center'}}>
        <FormControl>
          <CustomSelect
            value={unit}
            onChange={(e) => handleChange(e)}
            style={{margin: 8, height:50}}
          > 
            {units.map((unit) => (
              <MenuItem key={unit.id} value={unit.name}>{unit.name} ({unit.longName})</MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
        <FormControlLabel control={<Checkbox checked={checked} onChange={changeBreakable}/>} label="Bontható" />
      </div>
      {/*<Button style={{width: 200, margin: 16, alignSelf: 'center'}} variant="contained">Mentés</Button>*/}
    </div>
  )
}

export default AmountUnits;
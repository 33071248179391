import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Accordion, Box } from '@mui/material';
import { Typography } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ReactApexChart from 'react-apexcharts';
import { createApexBarOptions, createApexBarSeries } from './DefaultApexOptions';

const Top10ItemsByTraffic = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [isOpened, setOpened] = useState(false);
  const apexSeries = useMemo(() => createApexBarSeries(data.map(d => d.y)), [data]);
  const apexOptions = useMemo(() => createApexBarOptions(data.map(d => d.label), 'Top 10 cikk forgalom alapján'), [data]);

  useImperativeHandle(ref, () => {
    return{
      setData: setData  
    }
  });

  const getData = useCallback(() => {
    if(!isOpened){
      props.setModalProps({
        open:true,
        function:'GETTOP10BYTRAFFIC'
      });
    }
    else{
      setData([]);
    }
    setOpened(val => !val);
  }, [isOpened, props]);

  return (
    <Accordion onChange={getData}>
      <AccordionSummary expandIcon={ <ExpandMoreIcon/> }>
        <Typography>Top 10 cikk forgalom alapján</Typography>
      </AccordionSummary>
      <Box display={data.length ? "block" : "none"}>
        <ReactApexChart options={apexOptions} series={apexSeries} type="bar" height={data.length * 60 + 100} />      
      </Box>
    </Accordion>
  );
})

export default Top10ItemsByTraffic;
import React, { useState,  useEffect, useImperativeHandle, forwardRef } from 'react';
import { Typography, TextField, Button, ButtonGroup, IconButton, Box, styled, Grid } from '@mui/material';
import { toast } from 'react-toastify';

// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ArrowForwardIos } from '@mui/icons-material';
import * as Global from '../../Global';

import CashierPay from './CashierPay';
import TableInvoice from './TableInvoice';
import TableList from './Tables';
import TableTransfer from './TableTransfer';
import SalePartnerList from './SalePartnerList';
import AmountDialog from './AmountDialog';

import NotFound from '../../images/not-found-image.jpg';
import CategoryModal from './CategoryModal';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border:'none',
    },
    '&:hover fieldset': {
      border:'none',
    },
    '&.Mui-focused fieldset': {
      border:'none',
    },
  },
});


function Products(props) {
  return (
    <div style={styles.products}>
      {props.products && props.products.map((product, index) => {
        if (props.selectedGroup === -1 || (product.group === props.selectedGroup && props.selectedCategory === -1) || ( product.categories === props.selectedCategory)) {
          return(
              <Button key={index} style={styles.product} onClick={() => props.addProduct(product.barcode, product.dividable)}>
                <Box component="img" style={styles.product.image} alt="Termék kép" src={product.pictureLink?'https://ntakapi.hardbit.hu/'+product.pictureLink/*+'?'+Math.random()*/:NotFound} />

                <Typography style={styles.product.name}>{product.name}</Typography>
                <div style={styles.product.priceHolder}>
                  <Typography style={styles.product.price}>{product.price.toLocaleString('hu')} Ft</Typography>
                  <div style={{ ...styles.plusSign}}>
                    <AddIcon sx={{ color: 'white'}} />
                  </div>
                </div>
              </Button>)
        } 
        else {
          return null;
        }
      })}
    </div>
  );
}


const Sale = forwardRef((props, ref) => {
  const [serviceSum, setServiceSum] = useState(0);
  const [products, ] = useState(props.products);
  const [filteredProducts, setFilteredProducts] = useState(props.products);
  const [categories, ] = useState(props.categories)
  const [selectedGroup, setSelectedGroup] = useState(-1);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [orderInput, setOrderInput] = useState(props.orderId);
  const [orderProducts, setOrderProducts] = useState(props.orderProducts);
  const [paymentActive, setPaymentActive] = useState(false);
  const [orderPrice, setOrderPrice] = useState(props.brutto??0);
  const [activeOrder, setActiveOrder] = useState(0);
  const [chosenTable, setChosenTable] = useState(0);
  const [tableName, setTableName] = useState('');
  const [tableInvoice, setTableInvoice] = useState(false);
  const [tables, setTables] = useState([]);
  const [tablesVATS, setTableVATs] = useState([]);
  const [showTables, setShowTable] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const [tableTransfer, setTableTransfer] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [partnerName, setPartnerName] = useState('');
  const [showPartner, setShowPartner] = useState(false);
  const [partners, setPartners] = useState([]);
  const [partnerId, setPartnerId] = useState(props.partnerId ?? 0);
  const [orderVATs, setOrderVATs] = useState([]);
  const [amountDialog, setAmountDialog] = useState(false);
  const [amountBarcode, setAmountBarcode] = useState(0);
  const [delivery, setDelivery] = useState(props.delivery ?? false);
  const [exchange, setExchange] = useState(1);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [malfunctions, setMalfunctions] = useState([]);

  // mobil nézet
  const mediaMatch = window.matchMedia('(max-width: 900px)');
  const [isMobileView, setIsMobileView] = useState(mediaMatch.matches);
  const [isMobileShowOrder, setIsMobileShowOrder] = useState(false);
  useEffect(() => {
    const handler = e => setIsMobileView(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  useEffect(() => {
    if(Global.SALEMODE === '1') {
      if (props.partner && props.partnerdiscount) {
        setPartnerName(props.partner + ' ( -' + props.partnerdiscount + '% )');
      }
    }
  }, [props] );


  useImperativeHandle(ref, () => {
    return {
      setSaleTables: setSaleTables,
      tetelMod: tetelMod,
      grossAmount: grossAmount,
      grossAmountTable: grossAmountTable,
      orderClose: orderClose,
      startInvoice: startInvoice,
      transfertables: transfertables,
      transferDone: transferDone,
      canceldone: canceldone,
      salepartners: salepartners,
      cancelOrder: cancelOrder,
    }
  });

  const setSaleTables = (json) => {
    setTables(json.tables.tables);
    setShowTable(true);
    setDisableClick(true);
  }

  const tetelMod = (json) => {
    setOrderProducts(json.kosarTetelek);
    setOrderPrice(json.brutto);
    setActiveOrder(json.szlakod);
    setOrderInput(json.szlakod);
    if (json.partnerid > 1) {
      setPartnerId(json.partnerid);
      setPartnerName(json.partner + ' ( -' + json.partnerdiscount + '% )');
    }
    else {
      setPartnerId(0)
      setPartnerName('');
    }
    if(json.kiszallitas !== ''){
      if (json.kiszallitas === "1") {
        setDelivery(true);
      }
      else {
        setDelivery(false);
      }
    }
    
  }

  const grossAmount = (json) => {
    setServiceSum(json.serviceSum);
    setActiveOrder(json.szlakod);
    setOrderPrice(json.gross);
    setOrderVATs(json.afaertekek);
    setExchange(json.arfolyam);
    setMalfunctions(json.malfunctions);
    setPaymentActive(true);
    setDisableClick(true);
  }

  const grossAmountTable = (json) => {
    setTableVATs(json.vat);
    setActiveOrder(json.szlakod);
    setOrderPrice(json.gross);
    setExchange(json.arfolyam)
    setMalfunctions(json.malfunctions);
    setPaymentActive(true);
    setDisableClick(true);
    setTableInvoice(false);
  }

  const orderClose = (json) => {
    setPaymentActive(false);
    setPartnerId(0);
    setPartnerName('');
    setOrderProducts(json.kosarTetelek);
    setOrderPrice(json.brutto);
    setActiveOrder(json.szlakod);
    setDisableClick(false);
    setDelivery(false);
  }

  const startInvoice = (json) => {
    if(json.brutto === 0){
      toast.error('Nincsenek tételek az asztalon');
      return;
    }
    setTableInvoice(true);
    setDisableClick(true);
    setOrderProducts(json.kosarTetelek);
    if (json.kiszallitas === '0') setServiceCharge(json.szervizdij);
    else setServiceCharge(0);
  }

  const transfertables = (json) => {
    if(!orderProducts.length){
      toast.error("Nincsenek tételek az asztalon");
      return;
    }
    setTables(json.tables.tables);
    setTableTransfer(true);
    setDisableClick(true);
  }

  const transferDone = (json) => {
    setOrderProducts(json.kosarTetelek);
    setOrderPrice(json.brutto);
    setTableTransfer(false);
    setDisableClick(false);
  }

  const canceldone = (json) => {
    setOrderProducts(json.kosarTetelek);
    setOrderPrice(json.brutto);
    setPaymentActive(false);
    setDisableClick(false);
  }

  const salepartners = (json) => {
    setPartners(json.partners.partners);
    setShowPartner(true);
    setDisableClick(true);
  }

  const cancelOrder = () => {
    setPaymentActive(false);
    closeHandler();
  }

  const addProduct = (barcode, dividable) => {
    if(!disableClick){
      if(
        dividable === 1 
        && chosenTable !== 0 
        && !orderProducts.some(product => product.barcode === parseInt(barcode))
      ){
        setAmountBarcode(barcode);
        setAmountDialog(true);
        setDisableClick(true);
        return;
      }

      if (Global.SALEMODE === '1') {
        props.ws.send('ADDPRODUCTTOBASKET|' + Global.USER + '|' + barcode + '|' + partnerId + '|' + delivery);
      }
      else if (Global.SALEMODE === '2' && chosenTable !== 0) {
        props.ws.send('ADDPRODUCTTOTABLE|' + Global.USER + '|' + barcode + '|' + chosenTable + '|' + partnerId + '|' + delivery);
      }
      else if (Global.SALEMODE === '2' && chosenTable === 0) {
        toast.error('A tétel hozzáadásához válasszon asztalt!');
      }
    }
  }

  const changeCategory = (id, parent) => {
    console.log("id: " + id);
    console.log("parent: "+ parent);
    if (id === -1) {
      setSelectedGroup(-1);
      setSelectedCategory(-1);
    } else if (id >= 0 && parent === -1) {
      setSelectedGroup(id);
      setSelectedCategory(-1);
    } else {
      setSelectedCategory(id);
    }
  }

  const plusOne = (id) => {
    if(!disableClick){
      if (Global.SALEMODE === '1') { 
        props.ws.send('BASKETPLUS|' + Global.USER + '|' + id);
      }
      else if (Global.SALEMODE === '2') {
        props.ws.send('TABLEPLUS|' + Global.USER + '|' + id);
      }
    }
  }

  const minusOne = (id) => {
    if(!disableClick){
      if (Global.SALEMODE === '1') {
        props.ws.send('BASKETMINUS|' + Global.USER + '|' + id);
      }
      else if (Global.SALEMODE === '2') {
        props.ws.send('TABLEMINUS|' + Global.USER + '|' + id);
      }
    }
  }

  const deleteItem = (id) => {
    if(!disableClick){
      if (Global.SALEMODE === '1') {
        props.ws.send('DELETEFROMBASKET|' + Global.USER + '|' + id)
      }
      else if (Global.SALEMODE === '2') {
        props.ws.send('DELETEFROMTABLE|' + Global.USER + '|' + id);
      }
    }
  }

  const searchOrder = () => {
    if(!disableClick){
      props.ws.send('SEARCHORDER|' + Global.USER + '|' + orderInput);
    }
  }

  const getCurrentOrder = () => {
    if(!disableClick){
      props.ws.send('GETCURRENTORDER|' + Global.USER);
    }
  }

  // const getPrevOrder = () => {
  //   if(!disableClick){
  //     let num = parseInt(orderInput) - 1;
  //     if (isNaN(num)) num = 1;
  //     props.ws.send('SEARCHORDER|' + Global.USER + '|' + num);
  //   }
  // }
  // const getNextOrder = () => {
  //   if(!disableClick){
  //     let num = parseInt(orderInput) + 1
  //     if (isNaN(num)) num = 1;
  //     props.ws.send('SEARCHORDER|' + Global.USER + '|' + num);
  //   }
  // }

  const onPartnerClick = () => {
    if(orderProducts[0] !== undefined && orderProducts[0].status === 'N') {
      toast.error('Partnert csak üres megrendeléshez lehet hozzáadni');
    }
    else {
      props.ws.send('GETSALEPARTNERS|' + Global.USER)
    }
  }

  const onFilterChange = (e) => {
    let _products = [...products];
    if(e.target.value.length > 0)
      _products = _products.filter((product) => product.name.toLowerCase().includes(e.target.value.toLowerCase()));
    
    setFilteredProducts(_products);
  }

  const OrderOrTableSearch = () => {
    return (
      <div>
      { Global.SALEMODE === '1' ?
        <ButtonGroup variant="contained" style={styles.navigationBtnGroup}>
          {/* <Button style={{...styles.navigationBtn,...styles.greyBtn}} onClick={(e) => getPrevOrder()}><ChevronLeftIcon /></Button> */}
          <Button style={{...styles.navigationBtn, width: '100%',...styles.greyBtn }} onClick={(e) => getCurrentOrder()}>Aktuális</Button>
          {/* <Button style={{...styles.navigationBtn,...styles.greyBtn}} onClick={(e) => getNextOrder()}><ChevronRightIcon /></Button> */}
        </ButtonGroup>
        :
        <ButtonGroup variant="contained"  style={styles.navigationBtnGroup}>
          {isMobileView &&
            <IconButton style={{...styles.orderButton, }} onClick={() => setIsMobileShowOrder(val => !val)}>
              {isMobileShowOrder ?
                <ArrowForwardIos />
                :
                <ArrowBackIosNewIcon />
              }
            </IconButton>
          }
          <Button 
            style={{...styles.navigationBtn, width: '100%',...styles.greyBtn}} 
            onClick={(e) => props.ws.send('GETSALETABLES|' + Global.USER)}
          >
            {chosenTable !== 0 ? tableName : 'Asztal kiválasztása'}
          </Button>
        </ButtonGroup>
      }
      </div>
    )
  }

  const closeHandler = () => {
    if(!chosenTable && Global.SALEMODE === '2'){
      toast.error("Nincs asztal kiválasztva");
      return;
    }
    if (Global.SALEMODE === '1') {
      props.ws.send('GETGROSSAMOUNT|'+ Global.USER)
    }
    else if (Global.SALEMODE === '2') {
      props.ws.send('STARTINVOICE|' + Global.USER + '|' + chosenTable)
    }
  }

  const handleChangeDelivery = () => {
    if (Global.SALEMODE === '1') {
      if (orderProducts.length !== 0 && orderProducts[0].status !== 'Z') {
        toast.error('Nyitott megrendelésen nem változtatható')
        return;
      }
      else {
        setDelivery(delivery => !delivery);
        return;
      }
    }
    else if (Global.SALEMODE === '2') {
      if (chosenTable === 0) {
        toast.error('Kérem először válasszon asztalt');
        return;
      }
      else if (orderProducts.length !== 0) {
        toast.error('Nyitott megrendelésen nem változtatható')
        return;
      }
      else {
        setDelivery(delivery => !delivery);
      }
    } 
  }


  return (
    <Grid container style={{ display: 'flex' }}>
      <Grid item lg={9} md={9} sm={(isMobileShowOrder ? 8 : 12)} xs={(isMobileShowOrder ? 0 : 12)} 
        style={{ 
          ...styles.sale, 
          width: isMobileView && !isMobileShowOrder ? "100%" : "75%", 
          display: isMobileShowOrder ? 'none' : 'flex',
          filter: `blur(${disableClick ? 4 : 0}px)` 
        }}
      >
        <div style={{display:'flex', flexDirection:'row'}}>
          {!isMobileView && 
            <div style={styles.categories}>
              {selectedGroup >= 0 &&
                <Button variant="contained" color="warning" style={styles.category} onClick={() => changeCategory(-1, -1)}>
                  <ArrowBackIosNewIcon style={styles.category.icon} />
                </Button>
              }
              {categories.map((category, index) => {
                let colors;
                if(selectedCategory === category.id) colors = {backgroundColor:'#0e1f42', color:'white',border:'1px solid #0e1f42'};
                else colors = {backgroundColor:'white', color:'#8992a5',border:'1px solid #8992a5'};
                
                if(category.parent === selectedGroup)
                  return(
                    <Button variant="contained" key={index} style={{...styles.category,...colors}} onClick={() => changeCategory(category.id, category.parent)}>
                      {category.name}
                    </Button>
                  );
                else return null;
              })}
            </div>
          }

          {isMobileView &&
            <div style={{marginBottom: 8, padding: '0px 22px', flexGrow: 1, display:'flex'}}>
              <Button 
                style={{
                  ...styles.navigationBtn, 
                  ...styles.greyBtn,
                  width: '100%',
                }} 
                onClick={() => {setCategoryModal(true); setDisableClick(true);}}
              >
                Kategória kiválasztása
              </Button>
              <IconButton style={{...styles.orderButton, }} onClick={() => setIsMobileShowOrder(val => !val)}>
                {isMobileShowOrder ?
                  <ArrowForwardIos />
                :
                  <ArrowBackIosNewIcon />
                }
              </IconButton>
            </div>
          }
        </div>
       <Products products={filteredProducts} selectedGroup={selectedGroup} selectedCategory={selectedCategory} addProduct={addProduct} />
      </Grid>

      <Grid item lg={3} md={3} sm={4} xs={12} style={{
        ...styles.order,
        display: (isMobileView && !isMobileShowOrder) ? "none" : "flex",
        // width: isMobileView ? "100%" : "25%",
        width: "25%",
        minWidth:220,
        filter: `blur(${disableClick ? 4 : 0}px)`
      }}>
        <div style={styles.order.orderNavigator}>
          <Box style={styles.filterBox}>
            <TextField onChange={onFilterChange} placeholder="Keresés"/>
          </Box>
          <OrderOrTableSearch />
          {Global.SALEMODE === '1' &&
            <StyledTextField
              hiddenLabel
              placeholder="Keresés"
              style={styles.navigationSearch}
              size="small"
              fullWidth={true}
              value={orderInput}
              onChange={(e) => setOrderInput(e.target.value)}
              InputProps={{ endAdornment:
                <IconButton onClick={() => searchOrder()}>
                  <SearchIcon />
                </IconButton>
              }}
            />
          }
        </div>

        <div style={styles.order.products}>
          {orderProducts
          .map((product) => (
            <div key={product.id} style={styles.order.products.product}>
              <div style={styles.order.products.product.details}>
                <div style={styles.productDesc}>
                  <p style={{margin:0,fontWeight:600,width:'fit-content'}}>{product.name}</p>
                  <p style={{margin:0,width:'fit-content'}}>{product.price.toLocaleString('hu')}&nbsp;Ft/{product.quantityUnit}</p>
                </div>
                <div style={styles.productSum}>
                  <p style={{fontWeight:'bold', margin:0}}>{(product.price*product.quantity).toLocaleString('hu')}&nbsp;Ft</p>
                </div>
                {product.status === 'N' &&
                  <>
                    <div style={styles.productBtn}>
                      <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                        <IconButton
                          style={styles.greyBtn}
                          onClick={() => minusOne(product.id)}
                        >
                          <RemoveIcon sx={{color:'black'}}/>
                        </IconButton>
                        <div style={{display:'inline-flex',height:'100%',alignItems:'center',fontSize:22,fontWeight:'bold'}}>{product.quantity}</div>  
                        <IconButton
                          onClick={() => plusOne(product.id)}
                          style={styles.greyBtn}
                        >
                          <AddIcon sx={{color:'black'}}/>
                        </IconButton> 
                      </div>
                    </div>
                    <div style={styles.productRemove}>
                      <IconButton
                        onClick={() => deleteItem(product.id)}
                        style={{backgroundColor:'#fef2f2', borderRadius:8,display:'inline-flex',border:'1px solid rgba(255, 0, 0, 0.3)'}}
                      >
                        <DeleteIcon sx={{color:'#f42727'}}/>
                      </IconButton>
                    </div>
                  </>
                }
                
              </div>
            </div>
          ))}
        </div>

        <div style={styles.order.bottomButtons}>
          <Button 
            style={{
              ...styles.greyBtn,
              flex:'100%', 
              backgroundColor: delivery ? '#1cbfdb' : '#f5f7fa', 
              color: delivery ? '#fff' : '#000'
            }}
            onClick={() => handleChangeDelivery()}
          >
              <p style={{margin:0,overflow:'hidden',textOverflow:'ellipsis'}}>Kiszállításos megrendelés</p>
          </Button>
          <Button style={{...styles.greyBtn,flex:'100%'}}
            onClick={() => onPartnerClick()}>
              {partnerName === '' ? 'Partner hozzáadása' : partnerName}
          </Button>
          {Global.SALEMODE === '2' &&
            <Button style={{...styles.greyBtn,flex:'100%'}} 
              onClick={() => {if(!chosenTable && Global.SALEMODE === "2"){toast.error("Nincs asztal kiválasztva");return;}props.ws.send('GETTRANSFERTABLES|' + Global.USER)}}>
                Tételek áthelyezése
            </Button>
          }
          <Button disabled style={{...styles.greyBtn,backgroundColor:'#1cbfdb',color:'white',padding:'2px 20px',flex:'100%'}}>
            <p style={{width:'50%',textAlign:'left',textStyle:'none',margin:0,textDecorationStyle:'unset'}}>Összesen</p>
            <p style={{width:'50%',textAlign:'right',fontWeight:'bold',fontSize:26,textStyle:'none',margin:0}}>{orderPrice.toLocaleString('hu')}&nbsp;Ft</p>
          </Button>
          <Button style={{...styles.greyBtn, flex:'60%',padding:'10px 2px',backgroundColor:'#005dff',color:'white',fontWeight:'bold'}} 
            onClick={() => closeHandler()}>
              Lezárás
          </Button>
            
          {/* <Button 
            style={{
              backgroundColor:'white',
              borderRadius:4,
              color:'black',
              border:'2px solid #7186fa',
              padding:'10px 2px',
              borderColor:'#7186fa'
            }}
            onClick={() => {props.ws.send('LOGOUT|' + Global.USER)}}
          >
            <LogoutIcon sx={{color:'#7186fa'}}/>
          </Button> */}
        </div>
      </Grid>
      {paymentActive && 
        <CashierPay serviceSum={serviceSum} gross={orderPrice} order={activeOrder} setPaymentActive={setPaymentActive} ws={props.ws} table={chosenTable} setDisableClick={setDisableClick} orderVATs={orderVATs} tableVATs={tablesVATS} exchange={exchange} malfunctions={malfunctions}/>
      }
      {tableInvoice &&
        <TableInvoice products={orderProducts} setTableInvoice={setTableInvoice} ws={props.ws} setDisableClick={setDisableClick} serviceCharge={serviceCharge}/>
      }
      {showTables && 
        <TableList tables={tables} setTableList={setShowTable} ws={props.ws} setChosenTable={setChosenTable} user={Global.USER} setDisableClick={setDisableClick} setTableName={setTableName}/>
      }
      {tableTransfer &&
        <TableTransfer ws={props.ws} setTableTransfer={setTableTransfer} setDisableClick={setDisableClick} products={orderProducts} tables={tables} currentTable={chosenTable}/>
      }
      {showPartner &&
        <SalePartnerList ws={props.ws} setShowPartner={setShowPartner} partners={partners} setDisableClick={setDisableClick} setPartnerName={setPartnerName} setPartnerId={setPartnerId} chosenTable={chosenTable}/>
      }
      {amountDialog &&
        <AmountDialog ws={props.ws} setDisableClick={setDisableClick} amountBarcode={amountBarcode} chosenTable={chosenTable} partnerId={partnerId} delivery={delivery} setAmountDialog={setAmountDialog}/>
      }
      {categoryModal &&
        <CategoryModal changeCategory={changeCategory} categories={categories} setDisableClick={setDisableClick} setCategoryModal={setCategoryModal}/>
      }
    </Grid>

  )
});

const styles = {
  sale: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 48px)',
  },
  orderButton: {
    display:'inline-flex',
    width:'40px',
    color: 'black',
  },
  categories: {
    display:'flex',
    margin: '8px 22px',
    overflowX: 'auto',
    width:'auto',
    backgroundColor:'white',
    borderRadius:8,
    padding:8
  },
  category: {
    display:'inline-flex',
    padding: '6px 16px',
    margin: '5px',
    fontSize: '16px',
    color:'#8992a5',
    border:'1px solid #8992a5',
    backgroundColor:'white',
    boxShadow:'none',
    textTransform:'none',
    flex:'1 1 auto',
    minWidth:'fit-content',
    height:'min-content',
    icon: {
      fontSize: '22px',
      padding: '3px',
    }
  },
  products: {
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    padding: '10px',
    overflowY: 'auto',
    marginLeft: '12px',
    marginRight: '12px'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  product: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    borderRadius:8,
    justifyContent:'space-between',
    backgroundColor:'white',
    image: {
      maxWidth: '100%',
      height:80,
      borderRadius:'inherit'
    },
    name: {
      fontSize: '16px',
      fontWeight: '500',
      wordBreak: 'break-word',
      padding: '5px 5px 0px 5px',
      color: '#3d3d3d',
      textTransform: 'none',
      textAlign:'left',
      width:'100%',
      marginBottom:25
    },
    priceHolder:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-around',
      width:'100%'
    },
    price: {
      fontSize: '18px',
      padding: '5px',
      color: '#424242',
      textTransform: 'none',
      fontWeight:'bold',
    }
  },
  navigationBtnGroup:{
    display: 'flex',
    padding: 0,
    width: 'inherit',
    maxHeight: '30px',
    gap:4,
    backgroundColor:'white',
    boxShadow:'none',
    marginBottom:8,
  },
  navigationBtn:{
    display:'inline-flex',
    backgroundColor:'rgb(245,247,250)',
    borderRadius:4,
    color:'black',
    border:'1px solid #00000036'
  },
  navigationSearch:{
    backgroundColor:'rgb(245,247,250)',
    borderRadius:8
  },
  order: {
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
    backgroundColor:'white',
    boxShadow:'0px 0px 2px rgba(75, 75, 75, 0.3)',
    borderRadius:4,

    image: {
      maxHeight: '80px',
      width: 'fit-content',
      maxWidth: '100%',
      margin: 'auto',
      paddingTop: '7px',
    },

    orderNavigator: {
      width: 'calc(100% - 10px)',
      padding: '5px',
      display: 'flex',
      flexDirection: 'column',
    },

    products: {
      flex: 1,
      overflowX: 'auto',

      product: {
        padding: '5px',
        color: 'black',
        borderRadius: '4px',
        margin: '5px',

        name: {
          margin: '0',
          fontSize: '1.7rem',
        },

        details: {
          display: 'flex',
          flexWrap:'wrap',
        }
      }
    },
    bottomButtons: {
      width: 'calc(100% - 10px)',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap:'wrap',
      gap:6
    },
  },
  productDesc:{
    flex: '50%',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  productSum:{
    flex: '50%',
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
  productBtn:{
    flex: '50%',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop:10,
  },
  productRemove:{
    flex: '50%',
    display: 'flex',
    flexDirection: 'column',
    marginTop:10,
    alignItems:'flex-end'
  },
  greyBtn:{
    backgroundColor:'rgb(245,247,250)', 
    borderRadius:8,
    display:'inline-flex',
    border:'1px solid #00000036',
    color:'black',
    whiteSpace:'nowrap'
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }
};

export default Sale;
import {useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import UserListItem from "./UserListItem";
import * as Global from '../Global';

const style = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  table: {
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
}

const UserList = (props) => {
  const [users, setUsers] = useState(props.users ?? []);
  const addWorker = (e) => {
    props.ws.send('PREADDEMPLOYEE|'+Global.USER);
  }

  if(users !== props.users){
    setUsers(props.users);
  }

  return(
    <div style={style.main}>
      <div style={style.tableDiv}>
      <ul style={style.mainUL}>
          <li style={{minWidth:600}}>
            <ul style={{listStyleType:'none',minWidth:600,paddingLeft:16, margin:'0px 6px', display:'flex'}}>
            <li style={{...style.li,width:'40%',minWidth:180, justifyContent:'left'}}>Név</li>
              <li style={{...style.li,width:'40%',minWidth:180, justifyContent:'left'}}>Jog</li>
              <li style={{...style.li,width:'10%',minWidth:40, justifyContent:'center'}}>Státusz</li>
              <li style={{...style.li,width:'10%',minWidth:40, justifyContent:'center'}}>
                <div style={style.plusSign} onClick={addWorker}>
                  <AddIcon sx={{color: 'white'}} />
                </div>
              </li>
            </ul>
          </li>
          <li>
            {
              users.length > 0 ?
              users.map((user, i) => {
                return(
                  <UserListItem ws={props.ws} key={i} user={user}/>
                )
              })
              :
              <li style={{...style.li,width:'100%',minWidth:40, justifyContent:'center'}}>
                Nincsenek felhasználók
              </li>
            }
          </li>
        </ul>   
      </div>
    </div>
  )
}

export default UserList;
import React, { useState } from 'react';
import { Autocomplete, TextField, Grid, styled } from "@mui/material"
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import { useEffect } from 'react';
import { remove } from 'remove-accents';

const style = {
  main:{
    textAlign: "center",
    backgroundColor:"white",
    display:'flex',
    borderRadius:12,
    boxShadow:'0px 0px 16px #d5d5d5',
    margin:'0 8px',
  },
  input:{
    display: "inline-flex",
    margin: "8px",
    flex:"1 2 auto",
    backgroundColor:"rgb(245,247,250)",
  }
}

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#2196f3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth:0,
    },
    '&:hover fieldset':{
      borderWidth:1,
      borderColor:'#8992a5',
    },
    '&:hover':{
      backgroundColor:"rgb(238,238,238)"
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    },
  },
})

const ProductListFilter = (props) => {
  const [constProducts,]                        = useState(props.products);
  const [descFilterString, setDescFilterString] = useState('');
  const [serFilterString, setSerFilterString]   = useState('');
  const [groKeys,]                              = useState(props.groKeys);
  const [groFilterID, setGroFilterID]           = useState('');
  const [catKeys,]                              = useState(props.keys);
  const [catFilterID, setCatFilterID]           = useState('');
  const setList = props.setList;



  useEffect(() => {
    const generalFilter = (arr) => {
      arr = arr.filter(elem => {
        if(remove(elem.desc.toLowerCase()).includes(remove(descFilterString.toLowerCase()))){
          return elem;
        }
        return null;      
      })
      arr = arr.filter(elem => {
        if(remove(elem.serNum.toLowerCase()).includes(remove(serFilterString.toLowerCase()))){
          return elem;
        }
        return null; 
      })
      arr = arr.filter(elem => {
        if(elem.category === catFilterID || catFilterID === ''){
          return elem;
        }
        return null; 
      })
      arr = arr.filter(elem => {
        if(elem.group === groFilterID || groFilterID === ''){
          return elem;
        }
        return null; 
      })
      setList(arr);
    }
    generalFilter(constProducts)
  }, [descFilterString, serFilterString, catFilterID, groFilterID, constProducts, setList])

  const descFilter = (e) => {
    setDescFilterString(e.target.value);
  }

  const serFilter = (e) => {
    setSerFilterString(e.target.value);
  }

  const catFilter = (e, newValue) => {
    if(newValue == null)
      setCatFilterID('');
    else
      setCatFilterID(newValue.id);
  }

  const groFilter = (e, newValue) => {
    if(newValue == null)
      setGroFilterID('');
    else
      setGroFilterID(newValue.id);
  }  

  return(
    <div id="productListFilter" style={style.main}>
      <Grid container>
        <Grid item xs={1} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <TuneSharpIcon/>
        </Grid>
        <Grid item xs={11} style={{flexWrap:'wrap',display:'flex'}}>
          <StyledTextField
            style={style.input}
            value={serFilterString}
            onChange={serFilter}
            variant="outlined"
            label="Vonalkód szűrő"
            hiddenLabel
            size="small"
          />
          <StyledTextField 
            style={style.input}
            value={descFilterString}
            onChange={descFilter}
            variant="outlined"
            label="Megnevezés szűrő"
            hiddenLabel
            size="small"
          />         
          <Autocomplete
            onChange={(event, newValue) => groFilter(event, newValue)}
            style={style.input}
            size="small"
            autoHighlight
            id="combo-box-demo"
            options={groKeys}
            sx={{ width: 220 }}
            renderInput={(params) => <StyledTextField {...params} label="Csoport" />}
          />
          <Autocomplete
            onChange={(event, newValue) => catFilter(event, newValue)}
            style={style.input}
            size="small"
            autoHighlight
            id="combo-box-demo"
            options={catKeys}
            sx={{ width:220 }}
            renderInput={(params) => <StyledTextField {...params} label="Kategóriák" />}
          />
        </Grid>
      </Grid>    
    </div>
  )

}

export default ProductListFilter
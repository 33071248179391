import { React, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import * as Global from '../Global';

const styles = {
  ul:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    minWidth:600,
    boxShadow:'0px 0px 8px #d5d5d5',
  },
  li:{
    display:'inline-flex',
    width:'25%',
    minWidth:180,
    flex:'auto'
  },
  editDiv:{
    padding:4,
    border:'2px solid #7186fa',
    color:'rgb(65, 90, 227)',
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    width:'min-content'
  },
};

const PartnerListItem = (props) => {
  const [partner,] = useState(props.partner);

  return(
    <li>
      <ul style={styles.ul}>
        <li style={{...styles.li,width:'45%',minWidth:180}}>
          {partner.name}
        </li>        
        <li style={{...styles.li,width:'45%',minWidth:180,justifyContent:'center'}}>
          {partner.address}
        </li>   
        <li style={{...styles.li,width:'10%',minWidth:40,justifyContent:'center'}}>
          <div style={{...styles.editDiv,borderColor:'#7186fa'}} onClick={() => props.ws.send('GETPARTNER|'+Global.USER+"|"+partner.id)}>
            <EditIcon  style={{color:'#7186fa'}}/>
          </div>
        </li>     
      </ul>
    </li>
  );
};

export default PartnerListItem;
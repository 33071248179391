import React, { useState, useReducer } from 'react';
import { styled } from '@mui/material';
import { Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { toast } from 'react-toastify';
import * as Global from '../Global';

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root':{
    '& fieldset': {
      borderColor:'black'
    },
    '&::placeholder':{
      color:'#000000'
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
    '& input':{
      color: 'black'
    },
  },
  '& .MuiOutlinedInput-root.Mui-error':{
    '& fieldset':{
      borderColor:'red'
    },
  },
  '& .MuiFormHelperText-root.Mui-error':{
    color:'red'
  }
})

const styles = {
  main: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    textAlign: 'center',
    color: 'black'
  },
  gridContainer: {
    width: '100%',
    maxWidth: 200,
  },
  gridItem:{
    textAlign:'center'
  },
  titleText:{
    fontWeight:'bold',
    borderRight:'1px solid #23b9db',
    paddingTop:0
  },
  logo: {
    maxWidth: '300px',
    width: '100%'
  },
  headerText: {
    marginBottom: 15
  },
  inputDiv: {
    marginBottom: 15
  },
  loginButton: {
    marginBottom: 15,
    backgroundColor:'#284aff',
  },
  registrationLink: {
    width: '100%'
  }
}

const AddSite = (props) => {
  const [siteAddress, setSiteAddress]         = useState({ zipCode: '', city: '', street: '', streetType: '', streetNumber: '' });

  // eslint-disable-next-line
  const [, forceUpdate] = useReducer(x => !x);

  const registrationButtonClick = () => {
    if((siteAddress.zipCode === '' && 
        siteAddress.city === '' && 
        siteAddress.street === '' && 
        siteAddress.streetType === '' && 
        siteAddress.streetNumber === '')){
      toast.error('Szükséges mezők hiányoznak');
      return;
    }  
    console.log(siteAddress);
    props.ws.send('ADDSITE|'+Global.USER+'|'+siteAddress.zipCode
      +'|'+siteAddress.city+'|'+siteAddress.street+'|'
      +siteAddress.streetType+'|'+siteAddress.streetNumber);
  }

  return(
    <div style={ styles.main }>
      
      <Typography variant="h6">Telephely</Typography>

      <div style={ styles.inputDiv }>
        <Typography>Irányítószám</Typography>
        <StyledInput
          value={siteAddress.zipCode}
          onChange={e => setSiteAddress({...siteAddress,zipCode:e.target.value})}
          autoComplete="postal-code"
          variant="outlined"
          placeholder='1234'
          hiddenLabel
          size="small"
          error={!siteAddress.zipCode.match(/^(\d{4})$/)}
          helperText={!siteAddress.zipCode.match(/^(\d{4})$/) && "Hibás irányítószám"}
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Város</Typography>
        <StyledInput
          value={siteAddress.city}
          onChange={e => setSiteAddress({...siteAddress,city:e.target.value})}
          autoComplete="address-level2"
          variant="outlined"
          placeholder='Budapest'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Közterület neve</Typography>
        <StyledInput
          value={siteAddress.street}
          onChange={e => setSiteAddress({...siteAddress,street:e.target.value})}
          variant="outlined"
          placeholder='Király'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Közterület típusa</Typography>
        <StyledInput
          value={siteAddress.streetType}
          onChange={e => setSiteAddress({...siteAddress,streetType:e.target.value})}
          variant="outlined"
          placeholder='utca'
          hiddenLabel
          size="small"
        />
      </div>

      <div style={ styles.inputDiv }>
        <Typography>Házszám</Typography>
        <StyledInput
          value={siteAddress.streetNumber}
          onChange={e => setSiteAddress({...siteAddress,streetNumber:e.target.value})}
          variant="outlined"
          placeholder='5'
          hiddenLabel
          size="small"
        />
      </div>

      <LoadingButton
        onClick={registrationButtonClick}
        variant="contained"
        style={ styles.loginButton }
      >
        Mentés
      </LoadingButton>
    
    </div>
  )
}

export default AddSite;
import { useState } from 'react';
import PBRRow from './PBRRow';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Global from '../../Global';

const styles = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
    clear: 'both',
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  table: {
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
    width:40,
    height:40,
    justifyContent:'center',
    marginTop:10,
    marginLeft:10
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
  noContent:{
    paddingLeft:16,
    paddingBottom:12,
    paddingTop:12,
    marginTop:8,
    marginBottom:8,
    marginLeft:6,
    marginRight:6,
    backgroundColor:'white',
    borderRadius:12,
    listStyleType:'none',
    alignItems:'center',
    textAlign:'center',
    minWidth:600,
    boxShadow:'0px 0px 10px #d5d5d5',
  },
}

const ProductsByReceipt = (props) => {
  const [products, ] = useState(props.products);

  const RenderSums = proList => {
    let VATs = [];
    let sums = [];
    for(let i=0;i<proList.length;i++){
      let j = VATs.indexOf(proList[i].vat);
      if(j >= 0){
        sums[j] += proList[i].gross;
      }
      else{
        VATs.push(proList[i].vat);
        sums.push(proList[i].gross);
      }
    }    
    console.log(proList);
    console.log(VATs);
    console.log(sums);
    return(
      VATs.map((VAT, i) => (
        <ul key={i} style={{listStyleType:'none', paddingLeft:16, paddingRight:16, margin:'0px 6px', display:'flex', minWidth:600}}>
          <li style={{...styles.li,width:'70%',minWidth:360, justifyContent:'left'}}></li>
          <li style={{...styles.li,width:'15%',minWidth:50, justifyContent:'right'}}>{VAT}&nbsp;összeg:</li>
          <li style={{...styles.li,width:'15%',minWidth:50, justifyContent:'right'}}>{sums[i].toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
        </ul>
      ))
    );
  };

  const TotalSum = proList => {
    let sum = 0;
    proList.forEach(product => {
      sum += product.gross;
    });
    return sum;
  }

  const handleBack = () => {
    if(props.type === 'storno') {
      props.ws.send('STORNOLIST|'+Global.USER);
    }
    else {
      props.ws.send('GETRECEIPTS|'+Global.USER)
    }
  }

  return(
    <div style={styles.main}>
      <div style={styles.tableDiv}>
        <div style={{display:"flex", flexDirection:"row", justifyContent: "space-between"}}>
          <div style={styles.plusSign} onClick={() => handleBack()}>
            <ArrowBackIcon sx={{color: 'white'}} />
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{...styles.li, justifyContent: 'flex-end', paddingRight: 4, marginTop: 2, marginBottom: 2}}>Számla szám: {props.szlaszam}</p>
            <p style={{...styles.li, justifyContent: 'flex-end', paddingRight: 4, marginTop: 2, marginBottom: 2}}>Partner: {props.partner}</p>
          </div>
        </div>
        <ul style={styles.mainUL}>
          <li style={{minWidth:600}}>
            <ul style={{listStyleType:'none', paddingLeft:16, paddingRight:16, margin:'0px 6px', display:'flex', minWidth:600}}>
              <li style={{...styles.li,width:'25%',minWidth:140, justifyContent:'left'}}>Név</li>
              <li style={{...styles.li,width: '5%',minWidth:40, justifyContent:'center'}}>Mennyiség</li>
              <li style={{...styles.li,width:'20%',minWidth:80, justifyContent:'right'}}>Nettó</li>              
              <li style={{...styles.li,width:'20%',minWidth:80, justifyContent:'right'}}>ÁFA</li>              
              <li style={{...styles.li,width:'25%',minWidth:80, justifyContent:'right'}}>Bruttó</li>              
              <li style={{...styles.li,width:'15%',minWidth:60, justifyContent:'right'}}>ÁFA kulcs</li>              
            </ul>
          </li>
          {
            products.length > 0 ?
              products.map((product, i) => {
                return(
                  <PBRRow ws={props.ws} setWindow={props.setWindow} key={i} product={product}/>
                )
              })
              :
              <li style={styles.noContent}>
                Nincsenek tételek a nyugtán
              </li>
          }
        </ul>
        <div style={{display: 'flex'}}>
          <div>
            {props.type !== 'storno' &&
              <Button variant="contained" style={{backgroundColor: "#ef5350"}} onClick={() => props.ws.send('STORNOITEMS|'+Global.USER + '|' + props.szlaid)}>Stornó</Button>
            }
          </div>
          <div style={{width: '100%'}}>
            {RenderSums(products)}

            <ul style={{listStyleType:'none', paddingLeft:16, paddingRight:16, margin:'0px 6px', display:'flex', minWidth:600}}>
              <li style={{...styles.li,width:'60%',minWidth:160, justifyContent:'left'}}></li>
              <li style={{...styles.li,width:'20%',minWidth:50, justifyContent:'right',fontWeight:800}}>Teljes összeg:</li>
              <li style={{...styles.li,width:'15%',minWidth:50, justifyContent:'right',fontWeight:800}}>{TotalSum(products).toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' })}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsByReceipt;
import { React, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import PartnerListItem from "./PartnerListItem";
import PartnerEditor from './PartnerEditor';

const style = {
  main: {
    padding: '15px',
    width: 'calc(100% - 30px)',
  },
  tableDiv: {
    overflowX: 'auto',
  },
  table: {
    maxWidth: '800px',
    width: '100%',
    margin: 'auto',
    overflowX: 'auto',
  },
  title: {
    textAlign: 'center'
  },
  plusSign: {
    padding:6,
    borderRadius:8,
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'#284aff',
    boxShadow:'0px 0px 10px rgb(40, 74, 255)',
  },
  mainUL:{
    padding:0,
    alignItems:'center',
    minWidth:600
  },
  li:{
    display:'inline-flex',
    paddingTop:4,
    paddingBottom:4,
    textAlign:'center',
    width:'20%',
    minWidth:180,
    flex:'auto',
    color:'#8992a5',
    alignItems:'center'
  },
}

const PartnerList = (props) => {  
  const [partners,] = useState(props.partners ?? []);
  return(
    <div style={style.main}>
      <div style={style.tableDiv}>
      <ul style={style.mainUL}>
          <li style={{minWidth:600}}>
            <ul style={{listStyleType:'none',minWidth:600,paddingLeft:16, margin:'0px 6px', display:'flex'}}>
              <li style={{...style.li,width:'45%',minWidth:180, justifyContent:'left'}}>Név</li>
              <li style={{...style.li,width:'45%',minWidth:180, justifyContent:'center'}}>Cím</li>
              <li style={{...style.li,width:'10%',minWidth:40, justifyContent:'center'}}>
                <div style={style.plusSign} onClick={() => {props.setWindow(<PartnerEditor ws={props.ws} partner={{}}/>)}}>
                  <AddIcon sx={{color: 'white'}} />
                </div>
              </li>
            </ul>
          </li>
          <li>
            {
              partners.length > 0 ?
              partners.map((partner, i) => {
                return(
                  <PartnerListItem ws={props.ws} setWindow={props.setWindow} key={i} partner={partner}/>
                )
              })
              :
              <li style={{...style.li,width:'100%',minWidth:40, justifyContent:'center'}}>
                Nincsenek partnerek
              </li>
            }
          </li>
        </ul> 
      </div>
    </div>
  )
}

export default PartnerList;